import { Typography } from "@material-ui/core"
import React from "react"
import {makeStyles} from '@material-ui/styles'

function Condition(props) {
    const t = props.t
    const classes = useStyles()
    return (
        <Typography align="center" className={classes.condition} style={{
            backgroundColor: props.condition == "stock" ? "rgba(33, 150, 83, 0.1)" : "rgba(47, 128, 237, 0.1)",
            color: props.condition == "stock" ? "#219653" : "#2F80ED",
            width: props.condition === "stock" ? "90px" : "139px"
        }}>
            {props.condition == "stock" ? t('cars.list.condition.inStock') : t('cars.list.condition.sold')}
        </Typography>
    ) 
}

const useStyles = makeStyles(theme => ({
    condition: {
        height:'32px',
        fontWeight: 500,
        justifyContent:'center',
        display:'flex',
        alignItems:'center'
    },
}))

export default Condition