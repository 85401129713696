import { Box, Button, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import "./car_list_card.scss"
import arrowIcon from "../../images/arrow-right.png"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSort from "../buttons/tab_sort"
import { useHistory } from "react-router-dom";
import Condition from "./car_condition";
import { getDate } from "../../core/date";
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'
import TodoBox from "./todo_box";

function CarListTable(props) {
    const classes = useStyles()
    const history = useHistory()
    const t = props.t
    function handleOnClick(event, id) {
        history.push('/cars/' + id)
    }

    return (
        <div className="carCard">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>
                                <TableSort name="car_list.brand_type" title={t('cars.list.name')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.color" title={t('cars.list.color')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.reg_date" title={t('cars.list.year')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "4px" }} align="left">
                                <TableSort name={props.sold ? "car_list.sell.sold_on" : "car_list.buy.purchased_on"}
                                    title={props.sold ? t('cars.list.condition.sold') : t('cars.list.dateAdded')}
                                    sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "6px" }} align={props.allCars ? "center" : 'left'}>
                                <TableSort disabled={props.allCars} name={props.allCars ? "car_list.type" : "car_list.matricule"}
                                    title={props.allCars ? t('cars.list.condition') + " / " + t('cars.show.toDoList')
                                     : t('cars.show.carInformation.matricule')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            {props.stock &&
                                <TableCell className={classes.headCell} style={{ paddingLeft: "16px" }} align={props.allCars ? "center" : 'left'}>
                                    <TableSort disabled title={t('cars.show.toDoList')} />
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.cars.map((item, index) => (
                            <TableRow className={classes.row} >
                                <TableCell className={classes.bodyCell}>
                                    <Box>
                                        <Typography className={classes.title}>{item.brand_type}</Typography>
                                        <Typography style={{ color: "#B6BFCF" }}>{item.displacement}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{item.color}</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{getDate(item.reg_date).year}</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{
                                        props.sold ? (item.sell?.sold_on ? getDate(item.sell.sold_on).monthNamed : null)
                                            : item.buy?.purchased_on ? getDate(item.buy.purchased_on).monthNamed : null}</Typography>
                                </TableCell>
                                {!props.allCars ?
                                    <>
                                        <TableCell className={classes.bodyCell} align="left">
                                            <Typography className={classes.text}>{item.matricule}</Typography>
                                        </TableCell>
                                        {props.stock &&
                                            <TableCell className={classes.bodyCell} align="left">
                                                <TodoBox stock car={item} />
                                            </TableCell>
                                        }
                                    </>
                                    :
                                    <TableCell className={classes.bodyCell} align="left">
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Condition condition={item.type} t={props.t} />
                                            <TodoBox car={item} />
                                        </Box>
                                    </TableCell>
                                }
                                <TableCell className={classes.bodyCell} align="left" width="32px">
                                    <img src={arrowIcon} width="16px" height="16px"
                                        style={{ backgroundColor: 'black', padding: '8px', cursor: "pointer" }}
                                        onClick={event => handleOnClick(event, item._id)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700
    },
    text: {
        color: "#434B59"
    },
    arrowIcon: {
        color: 'white',
        width: '24px',
        height: '24px',
    },
    row: {
        backgroundColor: 'white',
        marginBottom: '1rem'
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '16px 20px'
    }
}))


export default withTranslation()(CarListTable)