import React from "react"

const NotFoundPage = props => {
    return(
        <div>
            404 page
        </div>
    )
}

export default NotFoundPage