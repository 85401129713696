import { Box, Collapse, Tooltip, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useMemo, useState } from "react"
import CarInformationItem from "../../../../components/car_list_card/car_information_item"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import CalendarTextField from "../../../../components/textfield/calender_picker";
import { getDate } from "../../../../core/date";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { PrintContext } from "../print/print_context";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import ErrorPopup from "../../../../components/error_popup/error_popup";
import { makeStyles } from '@material-ui/styles'

function CarInformation(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [print, setPrint] = useState(true)
    const context = useContext(Context)
    const { content, setContent } = useContext(PrintContext)
    const [query, setQuery] = useState({ event: "", field: "" })
    const [errorTip, setErrorTip] = useState(false);
    const t = props.t
    const roles = useMemo(() => JSON.parse(sessionStorage.getItem("roles"))
        , [])
    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    async function req(event, field, cancelToken) {
        if (field) { // roles && roles.includes(1) && 
            let data
            data = await dataProvider.update(context, "car/one/" + props.car._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
            if (field === "matricule") {
                setErrorTip(data.status !== 200)
            }
        }
    }

    useEffect(() => {
        setContent({ ...content, car_information: props.car })
    }, [])

    function changePrint() {
        if (print) setContent({ ...content, car_information: "" })
        else setContent({ ...content, car_information: props.car })
        setPrint(!print)
    }
    return (
        <CarInformationItem title={t('cars.show.carInformation')} open={open} setOpen={setOpen} print={print} changePrint={changePrint}>
            <Collapse in={!open}>
                <Box display="flex">
                    {[
                        { title: t('cars.show.carInformation.brand&model'), data: props.car.brand_type, flex: "1" },
                        { title: t('cars.list.year'), data: getDate(props.car.reg_date).year, flex: "1" },
                        { title: t('cars.list.color'), data: props.car.color, flex: "1" },
                        { title: t('cars.show.carInformation.matricule'), data: props.car.matricule, flex: "1" }
                    ].map(item =>
                        <Box flexGrow={item.flex}>
                            <Typography className={classes.columnTitle}>{item.title}</Typography>
                            <Typography className={classes.columnData}>{item.data}</Typography>
                        </Box>
                    )}
                </Box>
            </Collapse>
            <Collapse in={open} style={{ width: '100%' }}>

                <Box marginBottom="24px">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.brand&model')}</Typography>
                    <CustomTextField placeholder={t('cars.show.carInformation.brand&modelplh')}
                        defaultValue={props.car.brand_type} onChange={event => changeField(event, "brand_type")} />
                </Box>
                    <Box display="flex" flexDirection="column">


                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.chassis')}</Typography>
                                <CustomTextField defaultValue={props.car.chassis}
                                    onChange={event => changeField(event, "chassis")}
                                    placeholder={t('cars.show.carInformation.chassisplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t("cars.show.carInformation.regNumber")}</Typography>
                                <ErrorPopup title={t('cars.show.carInformation.matriculeError')} open={errorTip} >
                                    <CustomTextField defaultValue={props.car.matricule} onChange={event => changeField(event, "matricule")}
                                        placeholder={t("cars.show.carInformation.regNumberplh")} />
                                </ErrorPopup>
                            </Box>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.regDate')}</Typography>
                                <CalendarTextField date={props.car.reg_date} onDateChange={event => changeField(event, "reg_date")}
                                    placeholder={t('cars.show.carInformation.regDateplh')} />
                            </Box>
                        </RowData>
                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.kilomtrage')}</Typography>
                                <CustomTextField defaultValue={props.car.kilomtrage} onChange={event => changeField(event, "kilomtrage")}
                                    placeholder={t('cars.show.carInformation.kilomtrageplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.approval')}</Typography>
                                <CustomTextField defaultValue={props.car.approval} onChange={event => changeField(event, "approval")}
                                    placeholder={t('cars.show.carInformation.approvalplh')} />
                            </Box>
                            <Box width="33.33%"></Box>
                        </RowData>
                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.body')}</Typography>
                                <CustomTextField defaultValue={props.car.body} onChange={event => changeField(event, "body")}
                                    placeholder={t('cars.show.carInformation.bodyplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t('cars.list.color')}</Typography>
                                <CustomTextField defaultValue={props.car.color} onChange={event => changeField(event, "color")}
                                    placeholder={t('cars.show.carInformation.colorplh')} />
                            </Box>
                            <Box width="33.33%"></Box>
                        </RowData>
                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.cylinders')}</Typography>
                                <CustomTextField defaultValue={props.car.cylinder} onChange={event => changeField(event, "cylinder")}
                                    placeholder={t('cars.show.carInformation.cylindersplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.cylindreeCm3')}</Typography>
                                <CustomTextField defaultValue={props.car.cylindree_cm3} onChange={event => changeField(event, "cylindree_cm3")}
                                    placeholder={t('cars.show.carInformation.cylindreeCm3plh')} />
                            </Box>
                            <Box width="33.33%" marginTop="auto">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.power')}</Typography>
                                <CustomTextField defaultValue={props.car.power} onChange={event => changeField(event, "power")}
                                    placeholder={t('cars.show.carInformation.powerplh')} />
                            </Box>
                        </RowData>
                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.emmissionCode')}</Typography>
                                <CustomTextField defaultValue={props.car.emission_code} onChange={event => changeField(event, "emission_code")}
                                    placeholder={t('cars.show.carInformation.emmissionCodeplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.expertiseDate')}</Typography>
                                <CalendarTextField date={props.car.expertise_date} onDateChange={event => changeField(event, "expertise_date")}
                                    placeholder={t('cars.show.carInformation.expertiseDateplh')} />
                            </Box>
                            <Box width="33.33%" >
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.expertiseCanton')}</Typography>
                                <CustomTextField defaultValue={props.car.expertise_canton} onChange={event => changeField(event, "expertise_canton")}
                                    placeholder={t('cars.show.carInformation.expertiseCantonplh')} />
                            </Box>
                        </RowData>
                        <RowData>
                            <Box width="33.33%">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.status')}</Typography>
                                <CustomTextField defaultValue={props.car.status} onChange={event => changeField(event, "status")}
                                    placeholder={t('cars.show.carInformation.statusplh')} />
                            </Box>
                            <Box width="33.33%" margin="0px 40px">
                                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.kind')}</Typography>
                                <CustomTextField defaultValue={props.car.kind} onChange={event => changeField(event, "kind")}
                                    placeholder={t('cars.show.carInformation.kindplh')} />
                            </Box>
                            <Box width="33.33%"></Box>
                        </RowData>
                    </Box>
            </Collapse>
        </CarInformationItem >
    )
}

function RowData(props) {
    return (
        <Box display="flex" alignItems="center" marginBottom="24px">
            {props.children}
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
}))


export default withTranslation()(CarInformation)