import React, { useContext, useEffect, useState } from "react"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Box, Button, Collapse, Typography } from "@material-ui/core";
import AddButton from "../buttons/add";
import CustomAutocomplete from "../textfield/autocomplete";
import CalendarTextField from "../textfield/calender_picker";
import BaseModal from "../madal/base_modal";
import CustomerModal from "./customer_modal";
import { Context } from "../../core/context";
import dataProvider from "../../core/dataProvider";
import { getDate } from "../../core/date";
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function CarInformationShowDetial(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [editCustomerModal, setEditCustomerModal] = useState(false)
    const [addCustomerModal, setAddCustomerModal] = useState(false)
    const [customers, setCustomers] = useState([])
    const context = useContext(Context)
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))
    useEffect(() => {
        if (document.getElementById("customerAutocomplete").value != undefined)
            setOpen(true)
    }, [])
    useEffect(() => {
        if (props.open)
            getCustomers()
    }, [props.open])
    async function getCustomers() {
        let data = await dataProvider.getList(context, "customer/list")
        if (data && data.data && data.data.customer_list)
            setCustomers(data.data.customer_list)
        else setCustomers([])
    }
    function changeCustomer(event, value) {
        if (roles && roles.includes(1)) {
            if (event.target.value != undefined) {
                setOpen(true)
                props.setCustomer(value)
            } else {
                setOpen(false)
                props.setCustomer({})
            }
        }
    }
    return (
        <Box>
            <Box display='flex' alignItems="center" marginBottom="24px">
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{props.sell ? t('cars.show.sellDetail.soldOn') : t('cars.show.purchaseDetail.purchasedOn')}</Typography>
                    <CalendarTextField
                        id={props.sell ? "sold_on" : ""}
                        date={props.date}
                        placeholder={t('cars.show.purchaseDetail.purchasedOnplh')} onDateChange={props.onDateChange} />
                </Box>
                <Box width="33.33%" margin="0px 40px"></Box>
                <Box width="33.33%"></Box>
            </Box>
            <Box display='flex' alignItems="center" marginBottom="24px">
                <Box width="100%">
                    <Typography className={classes.columnTitle}>
                        {props.sell ? t('cars.show.sellDetail.selectCustomer') :
                            t('cars.show.purchaseDetail.selectProviderCustomer')}
                    </Typography>
                    <Box display='flex' alignItems='center'>
                        <Box flexGrow="1">
                            <CustomAutocomplete
                                disableClearable
                                placeholder={t("cars.show.purchaseDetail.customerplh")}
                                id="customerAutocomplete"
                                value={props.customer && props.customer._id ? props.customer : null}
                                options={customers}
                                onChange={changeCustomer}
                                setTableOpen={setOpen}
                                tableOpen={open} />
                        </Box>

                        <Typography style={{ margin: '0px 40px' }} className={classes.columnTitle}>or</Typography>

                        <AddButton
                            onClick={event => { if (roles && roles.includes(2)) setAddCustomerModal(true) }}
                            text={t('cars.show.purchaseDetail.createNew')} />
                        {(roles && roles.includes(2)) &&
                            <BaseModal open={addCustomerModal} setOpen={setAddCustomerModal}>
                                <CustomerModal setCustomer={props.setCustomer} getCustomers={getCustomers} setOpen={setAddCustomerModal} {...props.t} />
                            </BaseModal>
                        }
                    </Box>
                </Box>
            </Box>


            <Collapse in={open}>
                <BaseModal open={editCustomerModal} setOpen={setEditCustomerModal}>
                    <CustomerModal edit setCustomer={props.setCustomer} getCustomers={getCustomers} setOpen={setEditCustomerModal} customer={props.customer} {...props.t} />
                </BaseModal>
                {(props.customer && props.customer.name) &&
                    <Box border="1px solid #CAD0DB" padding='20px 20px 10px 20px' marginBottom='20px' display='flex'>
                        <TableContainer>
                            <Table style={{ margin: '0px' }}>
                                <TableBody>
                                    <TableRow style={{ marginBottom: '24px' }}>
                                        {[
                                            { title: t('cars.list.name'), data: props.customer.name, flex: "1" },
                                            { title: t('cars.show.purchaseDetail.streetNo'), data: props.customer.street_no, flex: "1" },
                                            { title: t('cars.show.purchaseDetail.postcode'), data: props.customer.postcode, flex: "1" },
                                            { title: t('cars.show.purchaseDetail.town'), data: props.customer.town, flex: "1" }
                                        ].map(item =>
                                            <TableCell className={classes.bodyCell}>
                                                <Box flexGrow={item.flex}>
                                                    <Typography className={classes.columnTitle}>{item.title}</Typography>
                                                    <Typography className={classes.columnData} >{item.data}</Typography>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        {(props.customer && props.customer.name) && [
                                            { title: t('cars.show.purchaseDetail.phoneNumber'), data: props.customer.phone_number, flex: "1" },
                                            { title: t('cars.show.purchaseDetail.email'), data: props.customer.email, flex: "1" },
                                            { title: t('cars.show.purchaseDetail.dateOfBirth'), data: getDate(props.customer.birthday).monthNamed, flex: "1" },
                                            { title: " ", data: t('cars.show.purchaseDetail.editCustomer'), flex: "1", edit: true }
                                        ].map(item =>
                                            <TableCell className={classes.bodyCell}>
                                                <Box flexGrow={item.flex}>
                                                    <Typography className={classes.columnTitle}>{item.title}</Typography>
                                                    <Typography
                                                        onClick={item.edit ? event => setEditCustomerModal(true) : null}
                                                        className={item.edit ? classes.editText : classes.columnData} >{item.data}</Typography>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </Collapse>

        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },

    editText: {
        color: '#2F80ED',
        fontWeight: 700,
        cursor: 'pointer',
        paddingTop: '20px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '0px'
    },
}))


export default withTranslation()(CarInformationShowDetial)