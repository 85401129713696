import { Box, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React from "react"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import DateFilter from "../../../components/textfield/date_filter";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function Filters(props) {
    const t = props.t

    function changeField(value, field) {
        let val = value
        props.setRegex({ ...props.regex, [field]: val })
    }


    function changeDate(value, field) {
        let val = {}
        let date = { from: '', to: '' }
        if (value == undefined || value == "")
            val = undefined
        else {
            date.from = moment(value).set({hour:0,minute:0,second:1,millisecond:0})
            date.to = moment(value).add(24, "hours").set({hour:0,minute:0,second:1,millisecond:0})
            val = date
        }
        props.setDate({ ...props.date, [field]: val })
    }


    return (
        <div className="carsListFiltersContainer">
            <Field
                label={t('cars.list.filters.brand')}
                options={props.cars ? props.cars.map(item => (item && item.brand_type && item.brand_type !== "") ? item.brand_type : "").filter(item => item!=="") : null}
                style={{ flexGrow: 4 }}
                onInputChange={(e, v) => changeField(v, "car_list.brand_type")}
            />
            <Field
                label={t('cars.list.color')}
                options={props.cars ? [...new Set(props.cars.map(item => (item && item.color && item.color !== "") ? item.color : "").filter(item => item!==""))] : null}
                style={{ flexGrow: 4 }}
                onInputChange={(e, v) => changeField(v, "car_list.color")}
            />
            <Field
                label={t('cars.show.carInformation.matricule')}
                options={props.cars ? [...new Set(props.cars.map(item => (item && item.matricule && item.matricule !== "") ? item.matricule : "").filter(item => item!==""))] : null}
                style={{ flexGrow: 4 }}
                onInputChange={(e, v) => changeField(v, "car_list.matricule")}
            />

            <Box width="16%" marginRight="1rem">
                <DateFilter placeholder={t('cars.list.year')} onChangeDate={changeDate} field="car_list.reg_date" />
            </Box>
            <Box width="16%" marginRight="1rem">
                <DateFilter placeholder={t('cars.list.dateAdded')} onChangeDate={changeDate} field="car_list.buy.purchased_on" />
            </Box>
            <Box width="16%">
                <DateFilter placeholder={t('cars.list.filters.dateSold')} onChangeDate={changeDate} field="car_list.sell.sold_on" />
            </Box>
        </div>
    )
}

function Field(props) {
    const classes = useStyles()
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            className={classes.autocompleteField}
            popupIcon={<ExpandMoreIcon color="disabled" />}
            getOptionLabel={(option) => option}
            renderInput={(params) => <MyTextField {...params} placeholder={props.label} variant="outlined" />}
            {...props}
        />
    )
}

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            fontWeight: '500',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)

const useStyles = makeStyles(theme => ({
    autocompleteField: {
        flexGrow: '1',
        marginRight: '1rem'
    },
}))

export default withTranslation()(Filters)