import { Box, Button, Divider, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect, useState } from "react"
import arrowIcon from "../../../images/arrow-right.png"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import { getDate } from "../../../core/date";
import { withTranslation } from 'react-i18next';
import TableSort from "../../../components/buttons/tab_sort"
import { makeStyles } from '@material-ui/styles'

function CustomerCarListTable(props) {
    const classes = useStyles()
    const history = useHistory()
    const t = props.t
    function handleOnClick(event, id) {
        history.push('/cars/' + id)
    }


    return (
        <div className="carCard">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell} style={{ paddingLeft: '50px' }}>
                                <TableSort disabled name="customer_list.name" title={t('cars.list.name')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} align="left">
                                <TableSort disabled name="customer_list.name" title={t('cars.list.color')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} align="left">
                                <TableSort disabled name="customer_list.name" title={t('cars.list.year')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "4px" }} align="left">
                                <TableSort disabled name="customer_list.name" title={t('customer.transactionDate')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.cars.map((item, index) => (
                            <TableRow className={classes.row} >
                                <TableCell className={classes.bodyCell} style={{ paddingLeft: '50px' }}>
                                    <Box>
                                        <Typography className={classes.title} gutterBottom>{item.brand_type}</Typography>
                                        <Typography style={{ color: "#B6BFCF" }}>{item.displacement}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{item.color}</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>2016</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>
                                        {props.sell ? (item.sell?.sold_on ? getDate(item.sell.sold_on).monthNamed : null)
                                            : item.buy?.purchased_on ? getDate(item.buy.purchased_on).monthNamed : null}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} width="32px" align="left" style={{ padding: '16px 24px 16px 0px' }}>
                                    <img src={arrowIcon} width="16px" height="16px"
                                        style={{ backgroundColor: 'black', padding: '8px', cursor: "pointer" }}
                                        onClick={event => handleOnClick(event, item._id)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700
    },
    text: {
        color: "#434B59"
    },
    arrowIcon: {
        width: '24px',
        height: '24px',
    },
    row: {
        backgroundColor: 'white',
        marginBottom: '1rem',
        paddingRight: '50px'
    },
    headCell: {
        border: 'none',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
    },
}))



export default withTranslation()(CustomerCarListTable)