import { Box } from "@material-ui/core"
import React from "react"
import ExtraWithCar from "../../cars_show/details/extra_with_cars"
import Fees from "../../cars_show/details/fees"
import Files from "../../cars_show/details/files/files"
import ToDoList from "../../cars_show/details/todo_list/to_do_list"
import AddCarModalFooter from "./footer"
import {makeStyles} from '@material-ui/styles'
import { useContext } from "react"
import { Context } from "../../../../core/context"
import dataProvider from "../../../../core/dataProvider"

function AddCarModalSuccess(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const roles = JSON.parse(sessionStorage.getItem("roles"))
    async function getCar() {
        context.setLoading(true)
        let data = await dataProvider.getOne(context, "car/one/" + props.car._id)
        if (data && data.data)
            props.setCar(data.data)
        else props.setCar({})
        context.setLoading(false)
    }
    return (
        <Box>
            <Box className={classes.container}>
                <ExtraWithCar id={props.car._id} />
                <ToDoList id={props.car._id} getCar={getCar}/>
                {(roles && roles.includes(0)) &&
                    <Fees id={props.car._id} car={props.car}/>
                }
                <Files id={props.car._id} />
            </Box>
            <Box padding="0px 32px 0px 50px" marginTop="32px">
                <AddCarModalFooter done setOpen={props.setOpen} page={props.page} setCar={props.setCar} setPage={props.setPage} getCars={props.getCars} {...props} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: '400px',
        padding: '32px 32px 0px 50px',
        borderBottom: '1px solid #CAD0DB',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }
}))

export default AddCarModalSuccess