import { Box, Divider, Typography } from '@material-ui/core'
import React, { forwardRef, useContext } from 'react'
import { getDate } from '../../core/date'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from '@material-ui/styles';

const FinancePrintContent = forwardRef((props, ref) => {
    const classes = useStyles()
    const t = props.t
    return (

        <div ref={ref}>
            <Box display="none" displayPrint="block" padding="20px">
                {props.prices &&
                    <Box>
                        <RowData title={t('financial.purchased')} data={props.prices.total_buy_price + " CHF"} />
                        <RowData title={t('financial.sold')} data={props.prices.total_sell_price + " CHF"} />
                        <RowData title={t('financial.total')} data={props.prices.total + " CHF"} />
                        <RowData title={t('financial.carsNumber')} data={props.cars.length} />
                    </Box>
                }
                <Divider style={{ margin: "10px 0px" }} />
                <TableContainer>
                    <Table>
                        {/* <TableHead>
                        </TableHead> */}
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.headCell}>
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('cars.list.name')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('cars.show.carInformation.matricule')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('cars.list.dateAdded')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('financial.boughtPrice')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('cars.list.filters.dateSold')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography noWrap style={{ fontWeight: '500' }} variant="body2">{t('financial.soldPrice')}</Typography>
                                </TableCell>
                            </TableRow>
                            {props.cars.map((item, index) => (
                                <>
                                    <TableRow className={classes.row} >
                                        <TableCell className={classes.bodyCell}>
                                            <Typography noWrap variant="caption" className={classes.text}># {index + 1}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            <Typography noWrap variant="caption" className={classes.title}>{item.brand_type}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell} align="left">
                                            <Typography noWrap variant="caption" className={classes.text}>{item.matricule}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell} align="left">
                                            <Typography noWrap variant="caption" className={classes.text}>{item.buy?.purchased_on ? getDate(item.buy.purchased_on).normal : null}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell} align="left">
                                            {item.sell &&
                                                <Typography noWrap variant="caption" className={classes.boldPrice}>{item.buy.price} CHF</Typography>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.bodyCell} align="left">
                                            <Typography noWrap variant="caption" className={classes.text}>{item.sell?.sold_on ? getDate(item.sell.sold_on).normal : null}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.bodyCell} align="left">
                                            {item.buy &&
                                                <Typography noWrap variant="caption" className={classes.boldPrice}>{item.sell.price} CHF</Typography>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    {/* {(index === 25 || index % 30 === 25) &&
                                        <div className="page-break" >aksldjfa</div>
                                    } */}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </div>
    )
})

function RowData(props) {
    return (
        <Box display="flex" alignItems="center" paddingRight="10px">
            <Typography noWrap varaint="subtitle2" style={{ fontWeight: 700, marginRight: '20px', pageBreakInside: 'avoid' }}>{props.title}</Typography>
            <Typography noWrap style={{ pageBreakInside: 'avoid' }}>{props.data}</Typography>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    print: {
        '@media print': {
            display: 'block',
            pageBreakBefore: 'auto',
            marginTop: "1rem"
        },
    },
    title: {
        fontWeight: 700,
        marginBottom: '5px'
    },
    text: {
        color: "#434B59"
    },
    row: {
        // backgroundColor: 'white',
    },
    headCell: {
        // border: 'none',
        // paddingBottom: '8px',
        paddingLeft: '16px !important',
        fontSize: '14px'
    },
    bodyCell: {
        // borderBottom: '4px solid #F5F7FA',
        padding: '16px 20px',
        fontSize: '12px'
    },
    boldPrice: {
        fontWeight: '700',
        display: 'flex'
    }
}))

export default FinancePrintContent