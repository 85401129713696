import { Box, Button, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import AddCarModalFooter from "./footer";
import CalendarTextField from "../../../../components/textfield/calender_picker";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { getDate } from "../../../../core/date";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import {makeStyles} from '@material-ui/styles'

function AddCarModalSecondPage(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    const [query, setQuery] = useState({ event: "", field: "" })

    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () =>  clearTimeout(timeOutId)
    }, [query])

    function req(event, field, cancelToken) {
        let data
        if (event && event.target)
            data = dataProvider.update(context, "car/one/" + props.car._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
    }

    return (
        <Box padding="28px 32px 0px 50px">
            <RowData>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.cylinders')}</Typography>
                    <CustomTextField defaultValue={props.car.cylinder} onChange={event => changeField(event, "cylinder")}
                        placeholder={t('cars.show.carInformation.cylindersplh')} />
                </Box>
                <Box width="33.33%" margin="0px 40px">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.cylindreeCm3')}</Typography>
                    <CustomTextField defaultValue={props.car.cylindree_cm3} onChange={event => changeField(event, "cylindree_cm3")}
                        placeholder={t('cars.show.carInformation.cylindreeCm3plh')} />
                </Box>
                <Box width="33.33%" marginTop="auto">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.power')}</Typography>
                    <CustomTextField defaultValue={props.car.power} onChange={event => changeField(event, "power")}
                        placeholder={t('cars.show.carInformation.powerplh')} />
                </Box>
            </RowData>
            <RowData>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.emmissionCode')}</Typography>
                    <CustomTextField defaultValue={props.car.emission_code} onChange={event => changeField(event, "emission_code")}
                        placeholder={t('cars.show.carInformation.emmissionCodeplh')} />
                </Box>
                <Box width="33.33%" margin="0px 40px">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.expertiseDate')}</Typography>
                    <CalendarTextField date={props.car.expertise_date} onDateChange={event => changeField(event, "expertise_date")}
                        placeholder={t('cars.show.carInformation.expertiseDateplh')} />
                </Box>
                <Box width="33.33%" >
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.expertiseCanton')}</Typography>
                    <CustomTextField defaultValue={props.car.expertise_canton} onChange={event => changeField(event, "expertise_canton")}
                        placeholder={t('cars.show.carInformation.expertiseCantonplh')} />
                </Box>
            </RowData>
            <RowData>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.status')}</Typography>
                    <CustomTextField defaultValue={props.car.status} onChange={event => changeField(event, "status")}
                        placeholder={t('cars.show.carInformation.statusplh')} />
                </Box>
                <Box width="33.33%" margin="0px 40px">
                    <Typography className={classes.columnTitle}>{t('cars.show.carInformation.kind')}</Typography>
                    <CustomTextField defaultValue={props.car.kind} onChange={event => changeField(event, "kind")}
                        placeholder={t('cars.show.carInformation.kindplh')} />
                </Box>
                <Box width="33.33%"></Box>
            </RowData>
            <AddCarModalFooter page={props.page} setPage={props.setPage} {...props} />
        </Box>
    )
}


function RowData(props) {
    return (
        <Box display="flex" alignItems="center" marginBottom="24px">
            {props.children}
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    bold: {
        fontWeight: 700,
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
}))

export default withTranslation()(AddCarModalSecondPage)