import { Box, Button, Collapse, Divider, IconButton, InputBase, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import ProfilePersonalInformation from "./personal_information";
import ProfileSetting from "./setting";
import Contact from "./contact";
import { withTranslation } from 'react-i18next';
import { Context } from "../../core/context";
import dataProvider from "../../core/dataProvider";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoutModal from "./logout_modal";
import BaseModal from "../../components/madal/base_modal";
import {makeStyles} from '@material-ui/styles'

function Profile(props) {
    const classes = useStyles();
    const context = useContext(Context)
    const [employee, setEmployee] = useState({})
    const [logoutModal, setLogoutModal] = useState(false)
    const t = props.t
    useEffect(() => {
        getEmployee()
    }, [])

    async function getEmployee() {
        context.setLoading(true)
        let data = await dataProvider.getOne(context, "employee/one/" + localStorage.getItem('id'))
        if (data && data.data)
            setEmployee(data.data)
        context.setLoading(false)
    }

    return (
        <Box flexGrow={1}>
            <Box padding="30px 40px 20px 40px"
                boxShadow="0px 4px 20px rgba(182, 191, 207, 0.4)">
                <Box marginBottom="22px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.title}>{t('profile.profile&setting')}</Typography>
                </Box>
            </Box>

            <Box padding="32px 24px 0px 40px">
                {!context.loading &&
                    <>
                        <ProfilePersonalInformation employee={employee} setEmployee={setEmployee} />
                        <ProfileSetting />
                        <Contact />
                        <Button
                            onClick={e => setLogoutModal(true)}
                            className={classes.logoutButton}
                            startIcon={<ExitToAppIcon style={{ marginLeft: "8px", fontSize: '30px' }} />}>
                            {t('profile.logout')}
                        </Button>
                        <BaseModal open={logoutModal} setOpen={setLogoutModal}>
                            <LogoutModal setOpen={setLogoutModal} {...props} />
                        </BaseModal>
                    </>
                }
            </Box>

        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700
    },
    logoutButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
    },

}));

export default withTranslation()(Profile)