import React, { useContext, useEffect, useState } from "react"
import CustomerShowItem from "../../../../components/customer_show_item/customer_show_item"
import { Context } from "../../../../core/context"
import dataProvider from "../../../../core/dataProvider"
import CustomerCarListTable from "../customer_car_table"
import { withTranslation } from 'react-i18next';

function CustomerSoldCars(props) {
    const context = useContext(Context)
    const t = props.t
    
    return(
        <CustomerShowItem title={t('customer.soldCars')}>
            <CustomerCarListTable sell sort={props.sort} setSort={props.setSort} cars={props.customer.sold_car ? props.customer.sold_car : []} {...props.t}/>
        </CustomerShowItem>
    )
}

export default withTranslation()(CustomerSoldCars)