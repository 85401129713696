import React, { useContext, useEffect, useState } from "react"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import CarListTable from "../../../components/car_list_card/car_list_card";
import { Box, Typography } from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import ProgressBar from "../../../components/backdrop/progress"
import "./cars_list.scss"
import { withStyles } from "@material-ui/styles";

function CarTabs(props) {
    const t = props.t

    const handleTab = (event, newValue) => {
        props.setTab(newValue);
    };

    function compare( a, b ) {
        if(props.sort.name !== "") {
            return 0
        }
        if ( a.brand_type < b.brand_type ){
          return -1;
        }
        if ( a.brand_type > b.brand_type ){
          return 1;
        }
        return 0;
      }
    return (
        <div>
            <CustomTabs
                value={props.tab}
                onChange={handleTab}
            >
                <CustomTab label={<div>{t('cars.list.allCars')} <TabNumber tab={props.tab} index={0} number={props.allCars.length} />  </div>} />
                <CustomTab label={<div>{t('cars.list.inStockCars')}<TabNumber tab={props.tab} index={1} number={props.allCars.filter(item => item.type == "stock").length} />  </div>} />
                <CustomTab label={<div>{t('cars.list.soldCars')}<TabNumber tab={props.tab} index={2} number={props.allCars.filter(item => item.type == "sold").length} />  </div>} />
            </CustomTabs>
            <TabPanel value={props.tab} index={0}>
                {props.loading ? <ProgressBar /> :
                    <div className="carsListCarsContainer">
                        <CarListTable allCars cars={props.allCars} sort={props.sort} setSort={props.setSort} />
                    </div>
                }
            </TabPanel>
            <TabPanel value={props.tab} index={1}>
                {props.loading ? <ProgressBar /> :
                    <div className="carsListCarsContainer">
                        <CarListTable stock cars={props.allCars.filter(item => item.type == "stock").sort(compare)} sort={props.sort} setSort={props.setSort} />
                    </div>
                }
            </TabPanel>
            <TabPanel value={props.tab} index={2}>
                {props.loading ? <ProgressBar /> :
                    <div className="carsListCarsContainer">
                        <CarListTable sold cars={props.allCars.filter(item => item.type == "sold").sort(compare)} sort={props.sort} setSort={props.setSort} />
                    </div>
                }
            </TabPanel>
        </div >
    )
}

function TabNumber(props) {
    return (
        <div className="carsListTabNumber"
            style={{ backgroundColor: props.tab == props.index ? "rgba(4, 135, 255, 0.1)" : "#E4E7ED" }}>
            <Typography>{props.number}</Typography>
        </div>
    )
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ position: 'relative', height: '60vh' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box padding="16px 24px 0px 40px">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const CustomTabs = withStyles({
    root: {
        borderBottom: '1px solid #CAD0DB',
        boxShadow: '0px 12px 20px -12px rgba(182, 191, 207, 0.4)',
    },
    indicator: {
        backgroundColor: '#0487FF',
        height: '4px'
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        marginLeft: "40px",
        fontSize: "20px",
        fontWeight: 700,
        '&:hover': {
            color: '#0487FF',
            opacity: 1,
        },
        '&$selected': {
            color: '#0487FF',
        },
        '&:focus': {
            color: '#0487FF',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default withTranslation()(CarTabs)