import { Box, Button, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import { withTranslation } from 'react-i18next';
import AddEmployeeModalFooter from "./footer"
import {makeStyles} from '@material-ui/styles'

function AddEmployeeModalInformation(props) {
    const [employee, setEmployee] = useState({})
    const classes = useStyles()
    const t = props.t
    function changeField(event, field) {
        setEmployee({ ...employee, [field]: event.target.value })
    }

    useEffect(() => {
        if (!sessionStorage.getItem("addEmployee")) {
            let employee = {}
            employee_fields.map(item => {
                employee[item] = ""
            })
            sessionStorage.setItem("addEmployee", JSON.stringify(employee))
        } else {
            setEmployee(JSON.parse(sessionStorage.getItem("addEmployee")))
        }
    }, [])

    useEffect(() => {
        sessionStorage.setItem("addEmployee", JSON.stringify(employee))
    }, [employee])

    return (
        <Box padding="28px 32px 0px 50px">
            <Box display="flex" alignItems="center" marginBottom="24px">
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t("cars.list.name")}</Typography>
                    <CustomTextField onChange={event => changeField(event, "name")} placeholder={t('employee.modal.nameplh')} value={employee.name} />
                </Box>
                <Box width="33.33%" margin="0px 40px">
                    <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.phoneNumber')}</Typography>
                    <CustomTextField value={employee.phone_number} placeholder={t('employee.modal.phoneplh')} onChange={event => changeField(event, "phone_number")} />
                </Box>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.email')}</Typography>
                    <CustomTextField value={employee.email} placeholder={t('employee.modal.emailplh')} onChange={event => changeField(event, "email")} />
                </Box>

            </Box>
            <Box display='flex' marginBottom="24px">
                <Box width="33.33%" >
                    <Typography className={classes.columnTitle}>{t('employee.jobTitle')}</Typography>
                    <CustomTextField
                        InputProps={{ classes: { input: classes.boldTextfield } }}
                        style={{ fontWeight: '700' }} value={employee.job_title} placeholder={t('employee.modal.jobTitleplh')} onChange={event => changeField(event, "job_title")} />
                </Box>
                <Box width="33.33%" margin="0px 40px">
                    <Typography className={classes.columnTitle}>{t('loginEmail')}</Typography>
                    <CustomTextField onChange={event => changeField(event, "username")} value={employee.username} placeholder={t('loginEmail')} />
                </Box>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('employee.show.password')}</Typography>
                    <CustomTextField onChange={event => changeField(event, "password")} value={employee.password} placeholder={t('employee.show.passwordplh')} />
                </Box>
            </Box>

            <AddEmployeeModalFooter page={props.page} setPage={props.setPage} setOpen={props.setOpen} {...props.t} />

        </Box>
    )
}


const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    bold: {
        fontWeight: 700,
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },


}))

export default withTranslation()(AddEmployeeModalInformation)

const employee_fields = [
    "name",
    "phone_number",
    "email",
    "job_title",
    "username",
    "password"
]