import React, { useMemo } from "react"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

function TodoBox(props) {
    const important = useMemo(() => props.car.todo_list.filter(item => item.priority === "Important" && item.state !== "done").length, [])
    const normal = useMemo(() => props.car.todo_list.filter(item => item.priority === "Normal" && item.state !== "done").length, [])
    const done = useMemo(() => props.car.todo_list.filter(item => item.state === "done").length, [])
    const { t } = useTranslation()
    return (
        <>
            {props.car.type === "stock" &&
                <>
                    {!props.stock ?
                        <>
                            {important !== 0 ?
                                <Box marginLeft="2px" bgcolor="rgba(235, 87, 87, 0.1)" display="flex" width="52px" fontWeight="600"
                                    color="#EB5757" alignItems="center" height="32px" justifyContent="center">
                                    {important}
                                    <ErrorOutlineIcon style={{ padding: "0px", marginLeft: '6px' }} />
                                </Box>
                                : normal !== 0 ?
                                    < Box marginLeft="2px" bgcolor="rgba(242, 153, 74, 0.1)" display="flex" width="52px" fontWeight="600"
                                        color="#F2994A" alignItems="center" height="32px" justifyContent="center">
                                        {normal}
                                        <ErrorOutlineIcon style={{ padding: "0px", marginLeft: '6px' }} />
                                    </Box>
                                    : done !== 0 ?
                                        < Box marginLeft="2px" bgcolor="rgba(33, 150, 83, 0.1)" display="flex" width="52px" fontWeight="600"
                                            color="#219653" alignItems="center" height="32px" justifyContent="center">
                                            {done}
                                            <CheckCircleOutlineIcon style={{ padding: "0px", marginLeft: '6px' }} />
                                        </Box>
                                        :
                                        <Box marginLeft="2px" bgcolor="#F5F7FA" display="flex" width="52px" fontWeight="600"
                                            color="#B6BFCF" alignItems="center" height="32px" justifyContent="center">
                                            0
                                        </Box>
                            }
                        </>
                        :
                        <>
                            {important != 0 ?
                                <Box marginLeft="2px" bgcolor="rgba(235, 87, 87, 0.1)" display="flex" width="140px" fontSize="12px" fontWeight="600" lineHeight="15px"
                                    color="#EB5757" alignItems="center" height="32px" >
                                    <ErrorOutlineIcon style={{ padding: "0px", margin: '0px 10px' }} />
                                    <span style={{fontSize:'16px', lineHeight:'20px'}}>{important}</span> &nbsp; {t('todo.remaining')}
                                </Box>
                                : normal !== 0 ?
                                    < Box marginLeft="2px" bgcolor="rgba(242, 153, 74, 0.1)" display="flex" width="140px" fontSize="12px" fontWeight="600" lineHeight="15px"
                                        color="#F2994A" alignItems="center" height="32px">
                                        <ErrorOutlineIcon style={{ padding: "0px", margin: '0px 10px' }} />
                                        <span style={{fontSize:'16px', lineHeight:'20px'}}>{normal}</span> &nbsp; {t('todo.remaining')}
                                    </Box>
                                    : done !== 0 ?
                                        < Box marginLeft="2px" bgcolor="rgba(33, 150, 83, 0.1)" display="flex" width="140px" fontSize="12px" fontWeight="600" lineHeight="15px"
                                            color="#219653" alignItems="center" height="32px">
                                            <CheckCircleOutlineIcon style={{ padding: "0px", marginLeft: '10px', marginRight: '16px' }} />
                                            {t('todo.allDone')}
                                        </Box>
                                        :
                                        <Box marginLeft="2px" bgcolor="#F5F7FA" display="flex" width="140px" fontSize="12px" fontWeight="600" lineHeight="15px"
                                            color="#B6BFCF" alignItems="center" height="32px" justifyContent="center">
                                            {t('todo.empty')}
                                        </Box>
                            }
                        </>
                    }
                </>
            }
        </>

    )
}


export default TodoBox