import React, { useState } from "react";

const PrintContext = React.createContext();

const PrintContextProvider = props => {

  const [content, setContent] = useState({})

  return (
    <PrintContext.Provider
      value={{
        content: content, setContent: setContent
      }}
    >
      {props.children}
    </PrintContext.Provider>
  );
}

export { PrintContextProvider, PrintContext };
