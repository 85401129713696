import { Box, Button, Collapse, TextField, Typography, withStyles } from "@material-ui/core"
import React, { useContext, useEffect, useRef, useState } from "react"
import CarInformationItem from "../../../../components/car_list_card/car_information_item"
import PriceField from "../../../../components/textfield/price_field";
import CarInformationShowDetial from "../../../../components/car_information_show/car_information_show";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { getDate } from "../../../../core/date";
import { PrintContext } from "../print/print_context";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import { makeStyles } from '@material-ui/styles'

function PurchaseDetail(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const context = useContext(Context)
    const [customer, setCustomer] = useState({})
    const [print, setPrint] = useState(false)
    const [purchaseOn, setPurchaseOn] = useState("")
    const { content, setContent } = useContext(PrintContext)
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))
    const [query, setQuery] = useState({ price: props.car.buy_price })
    function changeField(event) {
        if (event && event.target)
            setQuery({ price: event.target.value })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => buy(undefined, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    useEffect(() => {
        buy(undefined, "sold_on")
    }, [purchaseOn])

    function changeDate(e) {
        setPurchaseOn(e.target.value)
    }
    function changePrint() {
        if (print) setContent({ ...content, purchase_detail: "" })
        else setContent({
            ...content, purchase_detail: {
                customer, price:
                    document.getElementById("carShowPurchasePrice").value,
                purchased_on: props.car.buy?.purchased_on
            }
        })
        setPrint(!print)
    }
    async function getCustomer() {
        let data = await dataProvider.getOne(context, "customer/one/" + props.car.buy.customer)
        if (data && data.data)
            setCustomer(data.data)
        else setCustomer({})
    }
    async function buy(e, cancelToken) {
        if (customer && customer._id) { //roles && roles.includes(1) && 
            let params = {
                customer_id: customer._id,
                price: query.price,
                purchased_on: purchaseOn
            }
            // if (e && e.target) {
            //     let date = moment(e.target.value, "DD MMM yyyy").format()
            //     if (moment(e.target.value, "DD MMM yyyy").isValid())
            //         params.purchased_on = date
            //     else params.purchased_on = ""
            // }
            let data = await dataProvider.sendInformation(context, "car/buy/" + props.car._id, {
                data: params,
                ...(cancelToken === "sold_on" ? {} : { cancelToken })
            })
            if (data && data.data)
                props.setCar(data.data)
        }
    }

    useEffect(() => {
        if (customer._id && open)
            buy()
    }, [customer])

    useEffect(() => {
        if (props.car.buy)
            getCustomer()
    }, [])

    return (
        <CarInformationItem title={t('cars.show.purchaseDetail')} open={open} setOpen={setOpen} print={print} changePrint={changePrint}>
            <Collapse in={!open}>
                {customer._id &&
                    <Box display="flex">
                        {props.car.buy && [
                            { title: t('cars.show.purchaseDetail.provider'), data: customer.name, flex: "1" },
                            { title: t('cars.show.purchaseDetail.phone'), data: customer.phone_number, flex: "1" },
                            { title: t('cars.show.purchaseDetail.date'), data: getDate(props.car.buy.purchased_on).monthNamed, flex: "1" },
                            { title: t('cars.show.purchaseDetail.price'), data: props.car.buy.price, flex: "1", bold: true }
                        ].map(item =>
                            <Box flexGrow={item.flex}>
                                <Typography className={classes.columnTitle}>{item.title}</Typography>
                                <Typography className={classes.columnData} style={{ fontWeight: item.bold ? 'bold' : 'unset' }}>{item.data}</Typography>
                            </Box>
                        )}
                    </Box>
                }
            </Collapse>

            <Collapse in={open} style={{ width: '100%' }}>
                <CarInformationShowDetial open={open} customer={customer}
                    setCustomer={setCustomer} date={props.car.buy ? props.car.buy.purchased_on : null}
                    onDateChange={changeDate} />
                <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.purchasePrice')}</Typography>
                <Box display="flex" marginBottom="20px">
                    <Box width="33.33%">
                        <Box display="flex" bgcolor="white" border="1px solid #E4E7ED">
                            <PriceField font={700} defaultValue={query.price} id="carShowPurchasePrice"
                                placeholder={t('cars.show.purchaseDetail.purchasePriceplh')} onChange={e => changeField(e)} />
                            <Box padding="13px 10px" bgcolor="#F5F7FA" color="#B6BFCF" fontWeight="500">CHF</Box>
                        </Box>
                    </Box>
                    <Box width="33.33%" margin="0px 40px"></Box>
                    <Box width="33.33%"></Box>
                </Box>
            </Collapse>

        </CarInformationItem>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    createButton: {
        border: "1px solid #2A303B",
        height: '48px',
        paddingRight: '16px',
        borderRadius: '0px',
        fontWeight: 700
    },
}))



export default withTranslation()(PurchaseDetail)