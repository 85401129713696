import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles'

function ModalHeader(props) {
    const classes = useStyles()
    const handleClose = () => {
        if (props.onClose) props.onClose()
        else props.setOpen(false);
    };
    return (
        <Box display='flex' justifyContent="space-between" alignItems="center" margin="24px 32px 0px 50px">
            <Typography variant="subtitle1" className={classes.title} style={{ color: props.grey ? "#B6BFCF" : "#2A303B" }}>
                {props.title}
            </Typography>
            <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: '#B6BFCF' }} />
            </IconButton>
        </Box>
    )
}
const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700,
    }
}))

export default ModalHeader