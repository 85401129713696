import { Box, Collapse, Divider, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CarInformationItem from "../../../../components/car_list_card/car_information_item"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomTextField from "../../../../components/textfield/custom_textfield";
import PriceField from "../../../../components/textfield/price_field";
import AddButton from "../../../../components/buttons/add";
import Recyclerbin from "../../../../components/buttons/recyclrebin";
import CustomAutocomplete from "../../../../components/textfield/autocomplete";
import AddIcon from '@material-ui/icons/Add';
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { PrintContext } from "../print/print_context";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import { makeStyles } from '@material-ui/styles'

function Fees(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [fees, setFees] = useState([])
    const [prices, setPrices] = useState({})
    const [print, setPrint] = useState({ General: false, Appearence: false, Mechanical: false })
    const [query, setQuery] = useState({ event: "", field: "", id: "" })
    const printContext = useContext(PrintContext)
    const context = useContext(Context)
    const t = props.t

    const optionList = [
        { name: t('cars.show.fees.general') },
        { name: t('cars.show.fees.appearence') },
        { name: t('cars.show.fees.mechanical') }
    ]
    function changeField(event, field, id) {
        setQuery({ event: event, field: field, id: id })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, query.id, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    function changePrint(event) {
        if (printContext) {
            let data = JSON.parse(JSON.stringify(print))
            data[event.target.name] = event.target.checked
            setPrint(data)
            let printableFees = {}
            for (const [key, value] of Object.entries(data)) {
                if (value) {
                    printableFees[key] = fees.filter(item => item.category === key)
                } else {
                    delete printableFees[key]
                }
            }
            printContext.setContent({ ...printContext.content, fees: { ...printableFees, prices } })
        }
    }

    async function addNewFee() {
        let params = {
            title: '',
            price: '',
            category: 'General'
        }
        let data = await dataProvider.create(context, "car/fee/one/" + props.id, { data: params })
        getFees()
        setOpen(true)
    }
    async function req(event, field, id, cancelToken, index) {
        if (field) {
            let data = await dataProvider.update(context, "car/fee/one/" + id, {
                data: {
                    [field]: event.target.value
                }, cancelToken
            })
            await getFees()
        }
    }
    async function changeCategory(event, newValue, id) {
        let feeList = JSON.parse(JSON.stringify(fees))
        feeList.filter(item => item._id == id)[0].category = newValue.name
        setFees(feeList)
        let value
        switch (newValue.name) {
            case t('cars.show.fees.general'):
                value = "General"
                break;
            case t('cars.show.fees.appearence'):
                value = "Appearence"
                break;
            case t('cars.show.fees.mechanical'):
                value = "Mechanical"
                break;
            default:
                break;
        }
        let data = await dataProvider.update(context, "car/fee/one/" + id, {
            data: {
                "category": value
            }
        })
        getFees()
    }
    async function getFees() {
        let data = await dataProvider.getList(context, "car/fee/list/" + props.id)
        if (data && data.data) {
            if (data.data.fee_list) {
                data.data.fee_list.forEach(item => {
                    switch (item.category) {
                        case "General":
                            item.category = t('cars.show.fees.general')
                            break;
                        case "Appearence":
                            item.category = t('cars.show.fees.appearence')
                            break;
                        case "Mechanical":
                            item.category = t('cars.show.fees.mechanical')
                            break;
                        default:
                            break;
                    }
                })
                setFees(data.data.fee_list)
            }
            else setFees([])
            setPrices({
                sum_all: data.data.sum_all,
                sum_general: data.data.sum_general,
                sum_mechanical: data.data.sum_mechanical,
                sum_appearence: data.data.sum_appearence
            })
        }
        else setFees([])
        if (props.getSum) props.getSum()
    }
    async function deleteFee(event, id) {
        let data = await dataProvider.delete(context, "car/fee/one/" + props.id + "/" + id)
        if (!data.data || !data.data.fee_list)
            setOpen(false)
        getFees()
    }
    useEffect(() => {
        getFees()
    }, [props.car])
    return (
        <CarInformationItem title={t('cars.show.fees')} open={open} setOpen={setOpen}
            checkbox={true} fees={true} footer={fees.length == 0} print={print} changePrint={changePrint}>
            <Collapse in={!open || fees.length == 0}>
                <MinimizeFees prices={prices} {...props} />
                <AddButton text={t('cars.show.fees.addNewFee')} onClick={addNewFee} />
            </Collapse>

            <Collapse in={open && fees.length != 0} style={{ width: '100%' }}>
                <TableContainer>
                    <Table style={{ borderCollapse: 'separate' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headCell}></TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.fees.feeTitle')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.fees.type')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.fees.cost')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fees.map((item, index) => (
                                <TableRow className={classes.row}>
                                    <TableCell width="52px" className={classes.bodyCell}>
                                        <Typography className={classes.columnTitle}>#{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        <CustomTextField placeholder={t("cars.show.fees.feeTitle")} onChange={event => changeField(event, "title", item._id)}
                                         defaultValue={item.title} key={item.title}/>
                                    </TableCell>
                                    <TableCell width="236px" className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        <CustomAutocomplete disableClearable onChange={(event, newValue) => changeCategory(event, newValue, item._id)}
                                            options={optionList} value={{ name: item.category }} />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA', width: '200px' }}>
                                        <Box display="flex" bgcolor="white" border="1px solid #E4E7ED">
                                            <PriceField placeholder={t("cars.show.fees.cost")}
                                                onChange={event => changeField(event, "price", item._id, index)} defaultValue={item.price} key={item._id} />
                                            <Box padding="13px 10px" bgcolor="#F5F7FA" color="#B6BFCF" fontWeight="500">CHF</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell width="48px" className={classes.bodyCell} align="left">
                                        <Recyclerbin onClick={event => deleteFee(event, item._id)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className={classes.bodyCell}></TableCell>
                                <TableCell style={{ padding: "0px", borderBottom: 'none' }}>
                                    <AddButton text={t('cars.show.fees.addNewFee')} onClick={addNewFee} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box padding="0px 0px 0px 52px" marginTop="40px">
                    <MinimizeFees prices={prices} {...props} />
                </Box>
            </Collapse>

        </CarInformationItem>
    )
}

export function MinimizeFees(props) {
    const classes = useStyles()
    const t = props.t

    return (
        <Box display="flex" border="1px solid #CAD0DB" padding="20px 40px" marginBottom="24px">
            {props.prices && [
                { title: t('cars.show.fees.generalFees'), data: props.prices.sum_general ? props.prices.sum_general + " CHF" : "0" + " CHF", flex: "1" },
                { title: t('cars.show.fees.appearenceFees'), data: props.prices.sum_appearence ? props.prices.sum_appearence + " CHF" : "0" + " CHF", flex: "1" },
                { title: t('cars.show.fees.mechanicalFees'), data: props.prices.sum_mechanical ? props.prices.sum_mechanical + " CHF" : "0" + " CHF", flex: "1", last: true },
            ].map(item =>
                <Box display="flex" >
                    <Box>
                        <Typography className={classes.columnTitle}>{item.title}</Typography>
                        <Typography className={classes.columnData}>{item.data}</Typography>
                    </Box>
                    {!item.last &&
                        <Box display="flex" justifyContent="center" alignItems="center" padding="0px 40px">
                            <AddIcon style={{ color: '#778191' }} fontSize="small" />
                        </Box>
                    }
                </Box>

            )}
            <Divider orientation="vertical" flexItem style={{ color: '#CAD0DB', marginLeft: 'auto' }} />
            <Box marginLeft="40px" marginRight="20px">
                <Typography className={classes.columnTitle}>{t('cars.show.fees.totalFees')}</Typography>
                <Typography className={classes.columnData} style={{ fontWeight: '700' }}>{props.prices.sum_all ? props.prices.sum_all : "0"} CHF</Typography>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    row: {
        backgroundColor: 'none',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '0px',
    },
}))



export default withTranslation()(Fees)