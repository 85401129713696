import { Box, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { Context } from "../../core/context";
import CustomerShowItem from "../../components/customer_show_item/customer_show_item";
import i18n from '../../i18n';
import CustomAutocomplete from "../../components/textfield/autocomplete";
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function ProfileSetting(props) {
    const classes = useStyles()
    const [lang, setLang] = useState({ name: localStorage.getItem('lang') })
    const t = props.t
    function changeLanguage(event, value) {
        setLang(value)
        i18n.changeLanguage(value.name)
        localStorage.setItem('lang', value.name)
    }
    return (
        <CustomerShowItem title={t('profile.setting')}>
            <Box padding='0px 37px 24px 50px'>
                <Box width="33.33%">
                    <Typography className={classes.columnTitle}>{t('profile.selectLanguage')}</Typography>
                    <CustomAutocomplete
                        disableClearable
                        value={lang}
                        options={options}
                        onChange={changeLanguage}
                    />
                </Box>
                <Box width="33.33%" margin="0px 40px"></Box>
                <Box width="33.33%"></Box>
            </Box>
        </CustomerShowItem >
    )
}



const options = [
    { name: 'en' },
    { name: 'fr' },
    { name: 'de' },
]

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
}))


export default withTranslation()(ProfileSetting)