import { Box, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React from "react"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import { withTranslation } from 'react-i18next';
import DateFilter from "../../../components/textfield/date_filter";
import {makeStyles} from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function CustomerFilter(props) {
    const t = props.t
    function changeField(value, field) {
        let val = value
        if (field === "car_list.createdAt")
            val = moment(value, "LL").format()
        props.setFilter({ ...props.filter, [field]: val })
    }
    function changeDate(value, field) {
        let val = JSON.parse(JSON.stringify(props.date))
        let oldDate = val[Object.keys(val)[0]]
        let from = oldDate ? oldDate.from : undefined, to = oldDate ? oldDate.to : undefined
        let date = { from: from , to: to }
        if (value == undefined || value == "")
            delete date[field]
        else {
            date[field] = moment(value)
            // date.from = moment(value)
            // date.to = moment(value).add(24, "hours")
        }
        props.setDate({ [`customer_list.${props.tab === 1 ? "purchased_on" : "sold_on"}`]: date })
    }
    return (
        <div className="carsListFiltersContainer">
            <Field
                label={t('customer.brand&modelOfCar')}
                options={props.customers ? props.customers.map(item => (item && item.last_car && item.last_car !== "") ? item.last_car : "").filter(item => item !== "") : null}
                style={{ width: "33.33%" }}
                onInputChange={(e, v) => changeField(v, "customer_list.last_car")}
            />
            <Box width={"33.33%"} marginRight="1rem">
                <DateFilter placeholder={t('customer.transactionDateFrom')} onChangeDate={changeDate} field="from" />
            </Box>
            <Box width={"33.33%"}>
                <DateFilter placeholder={t('customer.transactionDateTo')} onChangeDate={changeDate} field="to" />
            </Box>
        </div>
    )
}

function Field(props) {
    const classes = useStyles()
    return (
        <Autocomplete
            freeSolo
            forcePopupIcon
            className={classes.autocompleteField}
            popupIcon={<ExpandMoreIcon color="disabled" />}
            getOptionLabel={(option) => option}
            renderInput={(params) => <MyTextField {...params} placeholder={props.label} variant="outlined" />}
            {...props}
        />
    )
}

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            fontWeight: '500',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)

const useStyles = makeStyles(theme => ({
    autocompleteField: {
        flexGrow: '1',
        marginRight: '1rem'
    },
}))

const top100Films = [
    'The Shawshank Redemption',
    'The Godfather',
    'The Godfather: Part II',
    'The Dark Knight',
    '12 Angry Men',
    "Schindler's List",
    'Pulp Fiction',
    'The Lord of the Rings: The Return of the King',
    'The Good, the Bad and the Ugly',
    'Fight Club',
    'The Lord of the Rings: The Fellowship of the Ring',
    'Star Wars: Episode V - The Empire Strikes Back',
    'Forrest Gump',
    'Inception',
    'The Lord of the Rings: The Two Towers',
    "One Flew Over the Cuckoo's Nest",
    'Goodfellas',
]

export default withTranslation()(CustomerFilter)