import { Box, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import PriceField from "../../../../components/textfield/price_field"
import AddCarModalFooter from "./footer"
import CarInformationShowDetial from "../../../../components/car_information_show/car_information_show"
import { Context } from "../../../../core/context"
import dataProvider from "../../../../core/dataProvider"
import moment from "moment"
import { withTranslation } from 'react-i18next';
import axios from "axios"
import { INPUT_TIMEOUT } from "../../../../core/APP_URL"
import { makeStyles } from '@material-ui/styles'

function AddCarModalPurchase(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const [customer, setCustomer] = useState({})
    const t = props.t
    const [query, setQuery] = useState({ price: props.car.buy_price })
    const [purchaseOn, setPurchaseOn] = useState("")
    function changeField(event) {
        if (event && event.target)
            setQuery({ price: event.target.value })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => buyCar(undefined, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    useEffect(() => {
        buyCar(undefined, "sold_on")
    }, [purchaseOn])
    function changeDate(e) {
        setPurchaseOn(e.target.value)
    }
    async function buyCar(e, cancelToken) {
        if (customer && customer._id) {
            let params = {
                customer_id: customer._id,
                price: query.price,
                purchased_on: purchaseOn
            }
            if (e && e.target) {
                let date = moment(e.target.value, "DD MMM yyyy")
                if (moment(date, "DD MMM yyyy").isValid())
                    params.purchased_on = date.format()
            }
            let data = await dataProvider.sendInformation(context, "car/buy/" + props.car._id, {
                data: params,
                ...(cancelToken === "sold_on" ? {} : { cancelToken })
            })
        }
    }

    useEffect(() => {
        if (customer._id)
            buyCar()
    }, [customer])
    return (
        <Box padding="28px 32px 0px 50px">
            <CarInformationShowDetial
                open={true} customer={customer} setCustomer={setCustomer} date={null}
                onDateChange={changeDate}
            />
            <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.purchasePrice')}</Typography>
            <Box display="flex">
                <Box width="33.33%">
                    <Box display="flex" bgcolor="white" border="1px solid #E4E7ED">
                        <PriceField defaultValue="" font={700} placeholder={t('cars.show.purchaseDetail.purchasePriceplh')} onChange={e => changeField(e)} />
                        <Box padding="13px 10px" bgcolor="#F5F7FA" color="#B6BFCF" fontWeight="500">CHF</Box>
                    </Box>
                </Box>
                <Box width="33.33%" margin="0px 40px"></Box>
                <Box width="33.33%"></Box>
            </Box>
            <AddCarModalFooter page={props.page} setPage={props.setPage} {...props} />
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },

}))

export default withTranslation()(AddCarModalPurchase)