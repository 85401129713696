import React, { useState } from "react"
import calendarIcon from "../../images/calendar.png"
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from "@material-ui/styles";
import DatePicker from '@material-ui/lab/DatePicker';
import { Box, IconButton, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";

function DateFilter(props1) {
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        let newDate
        if (date == null) {
            setSelectedDate(null);
            if (props1.onChangeDate) props1.onChangeDate(null, props1.field)
            return
        } 
        // else if (!date.isValid()) return
        newDate = moment(!date.isValid() ? date._i : date)
            // .set({hour:0,minute:0,second:4,millisecond:0})
            .format()
        setSelectedDate(newDate);
        let event = {}
        event.target = {}
        event.target.value = newDate
        if (props1.onChangeDate) props1.onChangeDate(newDate, props1.field)
    };

    return (
        <DatePicker
            value={selectedDate}
            inputFormat="DD.MM.yyyy"
            mask="__.__.____"
            onChange={handleDateChange}
            renderInput={({ inputRef, inputProps, InputProps, ...params }) =>
                <MyTextField
                    InputProps={
                        {
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }} paddingRight="10px">
                                        {(selectedDate !== null && selectedDate) &&
                                            <IconButton sx={{ padding: "0px", marginRight: '-10px' }} onClick={(e) => handleDateChange(null)}>
                                                <ClearIcon />
                                            </IconButton>}
                                        {InputProps?.endAdornment}
                                    </Box>
                                </InputAdornment>
                            ),
                            // readOnly: true
                        }}
                    {...params}
                    helperText={null}
                    {...inputProps}
                    placeholder={props1.placeholder}
                    ref={inputRef}
                />}
        />
    )
}

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            fontWeight: '500',
            backgroundColor: 'white',
            paddingRight: '0px',
            borderRadius: '0px',
            "&.Mui-error + fieldset": {
                border: "1px solid green"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },
})(TextField)

export default DateFilter