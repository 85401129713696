import { Box, Button, Divider, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect, useState } from "react"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import TableSort from "../../components/buttons/tab_sort";
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'
import { getDate } from "../../core/date";

function FinancialTable(props) {
    const classes = useStyles()
    const history = useHistory()
    const t = props.t
    function handleOnClick(event, id) {
        history.push('/cars/' + id)
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>
                                <TableSort name="car_list.brand_type" title={t('cars.list.name')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.buy.purchased_on" title={t('cars.list.dateAdded')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.sell.sold_on" title={t('cars.list.filters.dateSold')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.buy.price" title={t('financial.boughtPrice')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="car_list.sell.price" title={t('financial.soldPrice')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.cars.map((item, index) => (
                            <TableRow className={classes.row} >
                                <TableCell className={classes.bodyCell}>
                                    <Box>
                                        <Typography className={classes.title}>{item.brand_type}</Typography>
                                        <Box display="flex" alignItems="center">
                                            <Typography style={{ color: "#B6BFCF" }}>{item.matricule}</Typography>
                                            {item.color &&
                                                <Typography style={{ color: "#B6BFCF", fontWeight:'500' }}>&nbsp; | &nbsp;{item.color}</Typography>
                                            }
                                            {item.reg_date &&
                                                <Typography style={{ color: "#B6BFCF", fontWeight:'500' }}>&nbsp; | &nbsp;{getDate(item.reg_date).year}</Typography>
                                            }
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{item.buy?.purchased_on ? getDate(item.buy.purchased_on).monthNamed : null}</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    <Typography className={classes.text}>{item.sell?.sold_on ? getDate(item.sell.sold_on).monthNamed : null}</Typography>
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    {item.sell &&
                                        <Typography className={classes.boldPrice}>{item.buy.price}<Typography style={{ fontWeight: '500', marginLeft: '10px' }}>CHF</Typography></Typography>
                                    }
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left">
                                    {item.buy &&
                                        <Typography className={classes.boldPrice}>{item.sell.price} <Typography style={{ fontWeight: '500', marginLeft: '10px' }}>CHF</Typography></Typography>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700,
        marginBottom: '5px'
    },
    medium: {
        fontWeight: 500,
        color: '#B6BFCF'
    },
    text: {
        color: "#434B59"
    },
    arrowIcon: {
        color: 'white',
        width: '24px',
        height: '24px',
    },
    row: {
        backgroundColor: 'white',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
        paddingLeft: '16px !important'
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '16px 20px'
    },
    boldPrice: {
        fontWeight: '700',
        display: 'flex'
    }
}))

export default withTranslation()(FinancialTable)