import React, { useContext, useEffect, useState } from "react"
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import summaryIcon from "../../../../images/summary.png"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { PrintContext } from "../print/print_context";
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function Summary(props) {
    const classes = useStyles()
    const [ignore, setIgnore] = useState({ ignore_vta: props.car.ignore_vta, ignore_fee: props.car.ignore_fee })
    const [print, setPrint] = useState(true)
    const context = useContext(Context)
    const { content, setContent } = useContext(PrintContext)
    const t = props.t
    useEffect(() => {
        props.getSum()
    }, [])

    useEffect(()=>{
        if (print) {
            setContent({ ...content, summary: { ...props.sum, ignore } })
        } else setContent({ ...content, summary: "" })
    }, [props.sum])

    useEffect(() => {
        setContent({ ...content, summary: { ...props.sum, ignore } })
    }, [ignore])


    function changePrint(e) {
        setPrint(e.target.checked)
        if (e.target.checked) {
            setContent({ ...content, summary: { ...props.sum, ignore } })
        } else setContent({ ...content, summary: "" })
    }

    async function ignoreField(event, field) {
        let params = {
            [field]: event.target.checked
        }
        setIgnore({ ...ignore, [field]: event.target.checked })
        let data = await dataProvider.update(context, "car/one/" + props.id, { data: params })
        props.getSum()
    }
    return (
        <Box border="1px solid #CAD0DB" boxShadow="0px 10px 20px -5px rgba(182, 191, 207, 0.3)"
            marginBottom='24px' padding="18px 16px">
            <Box display="flex" alignItems="center">
                <img src={summaryIcon} width="25px" height="25px" />
                <Typography className={classes.cardTitle} variant="subtitle2">{t('cars.show.summary')}</Typography>
                <FormControlLabel
                    onChange={changePrint}
                    checked={print}
                    style={{ marginLeft: 'auto' }}
                    control={<Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon className={classes.mediumText} />} style={{ color: '#2F80ED' }} />}
                    label={t('cars.show.toPrint')}
                    labelPlacement="start"
                />
            </Box>
            <Box display="flex" marginTop="26px" justifyContent="space-between">
                <Box flexGrow="1" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"12px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.purchasedPrice')}</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '500' }}>{props.sum.purchased + " CHF"}</Typography>
                </Box>
                <Box width="15%" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"12px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.totalFees')}</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '500' }}>{(props.sum.fees ? props.sum.fees : "0") + " CHF"}</Typography>
                    <FormControlLabel
                        checked={ignore.ignore_fee}
                        onChange={e => ignoreField(e, "ignore_fee")}
                        style={{ margin: '0px', marginLeft: '-8px' }}
                        control={<Checkbox color="primary" size="small" icon={<CheckBoxOutlineBlankIcon className={classes.mediumText} />} style={{ color: '#2F80ED' }} />}
                        label={<Typography variant="caption" style={{ fontWeight: '700', color: '#778191' }}>{t('cars.show.summary.ignoreThis')}</Typography>}
                        labelPlacement="end"
                    />
                </Box>
                <Box width="15%" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"12px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.totalCost')}</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '700' }}>{(props.sum.cost ? props.sum.cost : "0") + " CHF"}</Typography>
                </Box>
                <Box width="15%" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"12px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.soldPrice')}</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '500' }}>{props.sum.sold ? props.sum.sold + " CHF" : `(${t('cars.show.summary.notSold')})`}</Typography>
                </Box>
                <Box width="15%" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"12px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.margin')}</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '700' }}>{props.sum.margin ? props.sum.margin + " CHF" : "-"}</Typography>
                </Box>
                <Box width="15%" height="117px" bgcolor="#F1F3F6" boxSizing="border-box"
                    border="1px solid #E4E7ED" padding="16px 20px" marginRight={"0px"}>
                    <Typography className={classes.columnTitle}>{t('cars.show.summary.tva')} (7.7%)</Typography>
                    <Typography className={classes.columnData} style={{ fontWeight: '700' }}>{props.sum.tva ? props.sum.tva + " CHF" : "-"}</Typography>
                    <FormControlLabel
                        checked={ignore.ignore_vta}
                        onChange={e => ignoreField(e, "ignore_vta")}
                        style={{ margin: '0px', marginLeft: '-8px' }}
                        control={<Checkbox color="primary" size="small" icon={<CheckBoxOutlineBlankIcon className={classes.mediumText} />} style={{ color: '#2F80ED' }} />}
                        label={<Typography variant="caption" style={{ fontWeight: '700', color: '#778191' }}>{t('cars.show.summary.ignoreThis')}</Typography>}
                        labelPlacement="end"
                    />
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    cardTitle: {
        color: "#2A303B",
        fontWeight: 700,
        marginLeft: '10px'
    },
    mediumText: {
        color: '#778191',
        fontWeight: 500
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '10px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500,
    }
}))

export default withTranslation()(Summary)