import React, { useContext } from 'react'
import { Context } from '../../core/context';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ErrorSnackbar(props) {
    const context = useContext(Context)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        context.setError({ open: false, message: context.error.message });
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={context.error.open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={context.error.state === "success" ? "success" : "error"}>
                    {context.error.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ErrorSnackbar