import React, { useContext, useEffect, useState } from "react"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { Box, Typography } from "@material-ui/core";
import CustomerListTable from "./customer_table_list";
import TabNumber from "../../../components/textfield/tab_number"
import { withTranslation } from 'react-i18next';
import ProgressBar from "../../../components/backdrop/progress"
import { withStyles } from "@material-ui/styles";

function CustomerTabs(props) {
    const t = props.t

    const handleTab = (event, newValue) => {
        let val = JSON.parse(JSON.stringify(props.date))
        props.setTab(newValue);
        if (newValue === 0) {
            if (val["customer_list.purchased_on"])
                props.setDate({ ["customer_list.sold_on"]: val["customer_list.purchased_on"] })
        } else {
            if (val["customer_list.sold_on"])
                props.setDate({ ["customer_list.purchased_on"]: val["customer_list.sold_on"] })
        }
    };
    return (
        <Box>
            <CustomTabs
                value={props.tab}
                onChange={handleTab}
            >
                <CustomTab label={<Box>{t('customer.clients')}<TabNumber tab={props.tab} index={0} number={props.customers.filter(item => item.type == "client").length} />  </Box>} />
                <CustomTab label={<Box>{t('customer.providers')}<TabNumber tab={props.tab} index={1} number={props.customers.filter(item => item.type == "provider").length} />  </Box>} />
            </CustomTabs>
            <TabPanel value={props.tab} index={0}>
                {props.loading ? <ProgressBar /> :
                    <Box>
                        <CustomerListTable customers={props.customers.filter(item => item.type == "client")}
                            sort={props.sort} setSort={props.setSort} {...props.t} />
                    </Box>
                }
            </TabPanel>
            <TabPanel value={props.tab} index={1}>
                {props.loading ? <ProgressBar /> :
                    <Box>
                        <CustomerListTable customers={props.customers.filter(item => item.type == "provider")}
                            sort={props.sort} setSort={props.setSort} {...props.t} />
                    </Box>
                }
            </TabPanel>
        </Box>
    )
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        style={{ position: 'relative', height: '60vh' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box padding="16px 24px 0px 40px">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const CustomTabs = withStyles({
    root: {
        borderBottom: '1px solid #CAD0DB',
        boxShadow: '0px 12px 20px -12px rgba(182, 191, 207, 0.4)',
    },
    indicator: {
        backgroundColor: '#0487FF',
        height: '4px'
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        marginLeft: "40px",
        fontSize: "20px",
        lineHeight: '24px',
        padding: '0px 32px',
        fontWeight: 700,
        '&:hover': {
            color: '#0487FF',
            opacity: 1,
        },
        '&$selected': {
            color: '#0487FF',
        },
        '&:focus': {
            color: '#0487FF',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default withTranslation()(CustomerTabs)