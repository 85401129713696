import dataProvider from "../../../../../core/dataProvider";

export async function uploadNewFile(context, carId, file, setFiles, setFile,setOpen, setProgress, cancelToken) {
    setOpen(true)
    const formData = new FormData();
    formData.append("file", file)
    const progress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
            setProgress(progressEvent.loaded*100/progressEvent.total)
         }
    }
    let data = await dataProvider.sendInformation(context, "car/file/upload/" + carId, { data: formData, progress:progress, cancelToken })
    data = await sendLink(context, carId, data.data.link, file.name)
    await getFiles(context, carId, setFiles)
    setFile({})
    setProgress(0)
}
export async function sendLink(context, carId, link, title) {
    let data = await dataProvider.create(context, "car/file/one/" + carId, {
        data: {
            title: title,
            link: link
        }
    })
}
export async function getFiles(context, carId, setFiles) {
    let data = await dataProvider.getList(context, "car/file/list/" + carId)
    if (data && data.data && data.data.file_list)
        setFiles(data.data.file_list)
    else setFiles([])
}
export async function deleteFile(context, carId, id, setFiles, setOpen) {
    let data = await dataProvider.delete(context, "car/file/one/" + carId + "/" + id)
    if (!data.data || !data.data.file_list)
        setOpen(false)
    getFiles(context, carId, setFiles)
}