import { Box, Divider, Typography } from "@material-ui/core"
import React, { useContext, useState } from "react"
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import CustomerShowItem from "../../../../components/customer_show_item/customer_show_item";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AntSwitch from "../../../../components/buttons/ant_switch";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function EmployeeAccessLevels(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    async function changeRole(event, code) {
        let list = props.employee.roles
        let roles = list.map(item => item.code)
        if (list.length === 0 && event.target.checked) roles.push(code)
        else if (list.filter(item => item.code === code).length === 0) roles.push(code)
        else if (event.target.checked) roles.push(code)
        else roles = roles.filter(item => item !== code)
        let data = await dataProvider.update(context, "employee/one/" + props.employee._id, {
            data: {
                'roles': roles
            }
        })
        if (data && data.data)
            props.setEmployee(data.data)
    };
    return (
        <CustomerShowItem title={t('employee.show.accessLevels')}>
            <Box padding='0px 50px' display="flex" justifyContent="space-between" marginBottom="34px">
                <Box width="45%">
                    {[
                        { title: t('employee.show.roleFee'), field: "", code: 0 },
                        { title: t('employee.show.roleCar'), field: "", code: 1 },
                        { title: t('financial'), field: "", code: 3, last: true },
                    ].map(item =>
                        <Box display="flex" alignItems="center" justifyContent="space-between"
                            marginBottom={item.last ? '0px' : "36px"}>
                            <Typography noWrap className={classes.columnData}>{item.title}</Typography>
                            <FormControlLabel
                                control={
                                    <AntSwitch checked={props.employee.roles.filter(e => e.code === item.code).length !== 0}
                                        onChange={event => changeRole(event, item.code)} />}
                                label={props.employee.roles.filter(e => e.code === item.code).length !== 0 ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')}
                            />
                        </Box>
                    )
                    }

                </Box>
                <Divider orientation="vertical" flexItem />
                <Box width="45%">
                    {[
                        { title: t("employee.show.roleCustomer"), field: "", code: 2 },
                        { title: t('employee.show.roleEmployee'), field: "", code: 4, last: true },
                    ].map(item =>
                        <Box display="flex" alignItems="center" justifyContent="space-between"
                            marginBottom={item.last ? '0px' : "36px"}>
                            <Typography noWrap className={classes.columnData}>{item.title}</Typography>
                            <FormControlLabel
                                disabled={item.code === 0}
                                control={
                                    <AntSwitch checked={props.employee.roles.filter(e => e.code === item.code).length !== 0}
                                        onChange={event => changeRole(event, item.code)} />}
                                label={props.employee.roles.filter(e => e.code === item.code).length !== 0 ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')}
                            />
                        </Box>
                    )
                    }

                </Box>
            </Box>
        </CustomerShowItem >
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },

}))



export default withTranslation()(EmployeeAccessLevels)
