import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles,withStyles } from '@material-ui/styles'

function ProgressBar(props) {
    const useStylesFacebook = makeStyles((theme) => ({
        root: {
            position: 'relative',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        },
        bottom: {
            color: props.white ? 'white' : "#434B59"
        },
        top: {
            color: props.white ? '#778191' : '#CAD0DB',
            animationDuration: '1500ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));
    const classes = useStylesFacebook();

    return (
        <div className={classes.root} {...props}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={120}
                thickness={2}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={120}
                thickness={2}
                disableShrink
                {...props}
            />
        </div>
    );
}

export default ProgressBar