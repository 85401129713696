import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React from "react"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function CustomAutocomplete(props) {
    const classes = useStyles()

    return (
        <Autocomplete
            value={props.value ? props.value : null}
            classes={{ root: classes.autoComplete }}
            options={props.options ? props.options : top100Films}
            popupIcon={<ExpandMoreIcon color="disabled" />}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <MyTextField {...params} placeholder={props.placeholder} variant="outlined" size="small" />}
            {...props}
        />
    )
}

const useStyles = makeStyles(theme => ({
    autoComplete: {
        height: '48px',
    },
}))

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                border: '1px solid #2A303B'
            },
            fontWeight: '700',
            height: '48px',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)

const top100Films = [
    { name: 'The Shawshank Redemption', year: 1994 },
    { name: 'The Godfather', year: 1972 },
    { name: 'The Godfather: Part II', year: 1974 },
    { name: 'The Dark Knight', year: 2008 },
    { name: '12 Angry Men', year: 1957 },
    { name: "Schindler's List", year: 1993 },
    { name: 'Pulp Fiction', year: 1994 },
    { name: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { name: 'The Good, the Bad and the Ugly', year: 1966 },
    { name: 'Fight Club', year: 1999 },
    { name: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { name: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { name: 'Forrest Gump', year: 1994 },
    { name: 'Inception', year: 2010 },
    { name: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { name: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { name: 'Goodfellas', year: 1990 },
]

export default CustomAutocomplete