import { Avatar, Box, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useRef, useState } from "react"
import CustomTextField from "../../components/textfield/custom_textfield";
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import CustomerShowItem from "../../components/customer_show_item/customer_show_item";
import AddButton from "../../components/buttons/add";
import { withTranslation } from 'react-i18next';
import profileImage from "../../images/profile.png"
import BaseModal from "../../components/madal/base_modal";
import ChangePasswordModal from "../../components/madal/change_password";
import axios from "axios";
import { INPUT_TIMEOUT } from "../../core/APP_URL";
import ErrorPopup from "../../components/error_popup/error_popup";
import {makeStyles} from '@material-ui/styles'

function ProfilePersonalInformation(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    const [file, setFile] = useState({})
    const [passwordModal, setPasswordModal] = useState(false)
    const [query, setQuery] = useState({ event: "", field: "" })
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])


    async function req(event, field, cancelToken) {
        if (field) {
            let data = await dataProvider.update(context, "employee/one/" + props.employee._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
            if (field === "email") {
                setErrorEmail(data.status !== 200)
            } else if (field === "username") {
                setErrorUsername(data.status !== 200)
            }
        }
    }
    return (
        <CustomerShowItem title={t('employee.show.personalInformation')}>
            <Box padding='0px 37px 24px 50px'>

                <Box display="flex" alignItems="center" marginBottom="40px">
                    <Avatar className={classes.avatar} src={"http://apigarage.crossproduct.ch" + props.employee.profilePicUrl} />
                    <Box>
                        <Typography className={classes.columnTitle} style={{ marginBottom: '20px' }}>{t('profile.profilePhoto')}</Typography>
                        <UploadButton
                            employee={props.employee}
                            setEmployee={props.setEmployee}
                            file={file}
                            setFile={setFile}
                            {...props}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t("cars.list.name")}</Typography>
                        <CustomTextField placeholder={t('employee.modal.nameplh')}
                            onChange={event => changeField(event, "name")} value={props.employee.name} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.phoneNumber')}</Typography>
                        <CustomTextField placeholder={t('employee.modal.phoneplh')} onChange={event => changeField(event, "phone_number")}
                            defaultValue={props.employee.phone_number} />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.email')}</Typography>
                        <ErrorPopup title={t('employee.show.emailerr')} open={errorEmail} >
                            <CustomTextField placeholder={t('employee.modal.emailplh')} onChange={event => changeField(event, "email")}
                                defaultValue={props.employee.email} />
                        </ErrorPopup>
                    </Box>

                </Box>
                <Box display='flex' marginBottom="24px">
                    <Box width="33.33%" >
                        <Typography className={classes.columnTitle}>{t('employee.jobTitle')}</Typography>
                        <CustomTextField
                            defaultValue={props.employee.job_title}
                            InputProps={{ classes: { input: classes.boldTextfield } }}
                            style={{ fontWeight: '700' }} placeholder={t('employee.modal.jobTitleplh')} onChange={event => changeField(event, "job_title")} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('loginEmail')}</Typography>
                        <ErrorPopup title={t('employee.show.usernameerr')} open={errorUsername} >
                            <CustomTextField defaultValue={props.employee.username}
                                placeholder={t('loginEmail')} onChange={event => changeField(event, "username")} />
                        </ErrorPopup>
                    </Box>
                    <Box width="33.33%" display="flex" flexDirection="column" justifyContent='flex-end'>
                        <AddButton text={t('employee.changePasswordModal.text')} noIcon onClick={e => setPasswordModal(true)} />
                    </Box>
                </Box>
                <BaseModal open={passwordModal} setOpen={setPasswordModal}>
                    <ChangePasswordModal setOpen={setPasswordModal} id={props.employee._id} />
                </BaseModal>
            </Box>
        </CustomerShowItem >
    )
}

function UploadButton(props) {
    const hiddenFileInput = useRef(null);
    const context = useContext(Context)
    const t = props.t
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    async function changeFile(event) {
        props.setFile(event.target.files[0])
        const formData = new FormData();
        formData.append("file", event.target.files[0])
        let data = await dataProvider.sendInformation(context, "employee/file/upload", { data: formData })
        if (data && data.data && data.data.link) {
            data = await dataProvider.update(context, "employee/one/" + props.employee._id, { data: { profilePicUrl: data.data.link } })
            if (data && data.data)
                props.setEmployee({ ...props.employee, ...data.data })
        }
        hiddenFileInput.current.value = ""
        props.setFile({})
    }
    return (
        <Box>
            <input type="file" ref={hiddenFileInput}
                onChange={changeFile}
                style={{ display: 'none' }} />
            <AddButton text={t('profile.changePhoto')} noIcon onClick={handleClick} />
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    avatar: {
        width: "100px",
        height: '100px',
        marginRight: '25px',
        background: profileImage,
        fontSize: '30px'
    }
}))


export default withTranslation()(ProfilePersonalInformation)