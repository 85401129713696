import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import BackButton from "../../../../components/buttons/back"
import DoneButton from "../../../../components/buttons/done"
import NextButton from "../../../../components/buttons/next"
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function AddEmployeeModalFooter(props) {
    const classes = useStyles()
    const t = props.t
    const nextPage = () => {
        props.setPage(props.page + 1);
        if (props.nextOnClick) props.nextOnClick()
    };
    const previousPage = () => {
        props.setPage(props.page - 1);
    };

    function handleClose() {
        props.setOpen(false)
    }
    function done() {
        if (props.nextOnClick) props.nextOnClick()
    }
    return (
        <Box display='flex' alignItems="center" paddingTop={`${props.done ? '0px' : '52px'}`}>
            <Typography className={classes.bold} style={{ color: '#B6BFCF', marginRight: 'auto' }}>{t('carModal.step')} {props.page} / 2</Typography>
            {props.page == 1 ?
                <Box>
                    <Button onClick={handleClose} className={classes.cancelButton}>{t('button.cancel')}</Button>
                    <NextButton onClick={nextPage} />
                </Box>
                :
                <Box>
                    <BackButton onClick={previousPage} />
                    <DoneButton onClick={done} title={t('employee.modal.addToEmployees')} />
                </Box>
            }
        </Box >
    )
}

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        height: '48px'
    },
}))

export default withTranslation()(AddEmployeeModalFooter)