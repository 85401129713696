import { Box, Button, Divider, Typography } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import CustomTextField from "../../components/textfield/custom_textfield"
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import { useHistory } from "react-router-dom";
import logo from "../../images/logo.PNG"
import loginBackground from "../../images/login-background.png"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'
function Login(props) {
    const history = useHistory()
    const t = props.t
    const classes = useStyles()
    const context = useContext(Context)

    async function check() {
        let admin = JSON.parse(sessionStorage.getItem("admin"))
        if (admin && admin.id) {
            let data = await dataProvider.sendInformation(context, "auth/login/admin", {
                data: {
                    username: admin.username,
                    password: admin.password,
                    garage_id: admin.garage_id
                }
            })
            if (data && data.data && data.data.tokens) {
                let token = data.data.tokens.accessToken
                let id = data.data.user._id
                sessionStorage.setItem("token", token)
                localStorage.setItem("id", id)
                localStorage.setItem("lang", "en")
                history.push("/")
            }
        }
        else if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
            history.push("/")
        }
    }

    useEffect(() => {
        check()
    }, [])

    async function handleClick() {
        context.setLoading(true)
        let data = await dataProvider.sendInformation(context, "auth/login", {
            data: {
                username: document.getElementById("loginUsername").value,
                password: document.getElementById("loginPassword").value
            }
        })
        try {
            let token = data.data.tokens.accessToken
            let id = data.data.user._id
            localStorage.setItem("token", token)
            localStorage.setItem("id", id)
            if (!localStorage.getItem("lang"))
                localStorage.setItem("lang", "en")
            context.setLoading(false)
            history.push("/")
        } catch {
            context.setLoading(false)
        }
    }

    function enterToLogin(event) {
        if (event.key === "Enter") {
            handleClick()
        }
    }
    return (
        <Box bgcolor="#F5F7FA" display="flex">
            <img src={loginBackground} className={classes.background} />
            <Box padding="24px 0px" width="410px" display="flex" justifyContent="center" padding="0px 110px" alignItems="center" flexDirection="column" flexGrow="1" >
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom="100px">
                    <img className={classes.garageImage} src={logo} />
                    <Typography variant="subtitle1" style={{ fontWeight: 700 }}>Garage</Typography>
                </Box>
                <Box bgcolor="#F5F7FA" border="1px solid #CAD0DB" marginBottom="60px" width="380px"
                    boxShadow="0px 10px 20px -5px rgba(182, 191, 207, 0.3)" padding="40px">
                    <Box marginBottom="24px">
                        <Typography className={classes.fieldTitle}>{t('loginEmail')}</Typography>
                        <CustomTextField onKeyUp={enterToLogin} id="loginUsername" variant="outlined" placeholder={t('loginEmail')} />
                    </Box>
                    <Box marginBottom="40px">
                        <Typography className={classes.fieldTitle}>{t('employee.show.password')}</Typography>
                        <CustomTextField onKeyUp={enterToLogin} id="loginPassword" variant="outlined" type="password" placeholder={t('employee.show.passwordplh')} />
                    </Box>

                    <Box textAlign="center">
                        <Button
                            onClick={handleClick}
                            endIcon={<ArrowForwardIcon className={classes.icon} />}
                            className={classes.logButton}>
                            {t('login')}
                        </Button>
                    </Box>
                </Box>
                <Box textAlign="center">
                    <Typography variant="caption" style={{ fontWeight: 500, textAlign: 'center' }} >{t('login.explanation')}</Typography>
                    <Box display="flex" justifyContent="center" marginTop="10px">
                        <Typography variant="caption" noWrap color="primary" style={{ fontWeight: 500 }}>support@ogarage.com</Typography>
                        <Divider orientation="vertical" flexItem style={{ margin: "0px 10px" }} />
                        <Typography variant="caption" noWrap color="primary" style={{ fontWeight: 500 }}>+41 78 709 79 54</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700,
    },
    logButton: {
        background: '#2A303B',
        width: '100%',
        borderRadius: '0px',
        fontSize: '20px',
        lineHeight: '24px',
        padding: "16px 0px",
        fontWeight: 700,
        color: 'white',
        "&:hover": { background: '#2A303B', }
    },
    fieldTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    garageImage: {
        width: '60px',
        height: '60px',
        marginRight: "13px"
    },
    icon: {
        color: 'white',
        fontSize: '18px'
    },
    background: {
        flexGrow: 4,
        height: 'calc(100vh - 1px)'
    }
}))

export default withTranslation()(Login)