import React, { useContext, useEffect, useState } from "react"
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Box, ListItemAvatar, Typography } from "@material-ui/core";
import logo from "../../images/logo.PNG"
import { useHistory } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import profileImage from "../../images/profile.png"
import {makeStyles} from '@material-ui/styles'

function AppDrawer(props) {

    const classes = useStyles();
    const [employee, setEmployee] = useState({})
    const [selected, setSelected] = useState()
    const history = useHistory()
    const context = useContext(Context)
    const t = props.t
    useEffect(() => {
        getEmployee()
    }, [])

    async function getEmployee() {
        let data = await dataProvider.getOne(context, "employee/one/" + localStorage.getItem('id'))
        if (data && data.data)
            setEmployee(data.data)
    }
    useEffect(() => {
        const value = history.location.pathname
        let checked = false
        drawerTitles.map((item, index) => {
            if (value.toString().startsWith(item.link)) {
                setSelected(index)
                checked = true
            }
        })
        if (!checked && value.toString().startsWith("/profile"))
            setSelected(drawerTitles.length)
    }, [history.location.pathname])

    function handleClick(link, index) {
        setSelected(index)
        history.push(link)
    }

    const roles = JSON.parse(sessionStorage.getItem("roles"))
    const drawerTitles = [
        { title: t("drawer.menu.cars"), link: "/cars" },
        ...(roles && roles.includes(2) ? [{ title: t('drawer.menu.customers'), link: "/customers" }] : []),
        ...(roles && roles.includes(3) ? [{ title: t('drawer.menu.financial'), link: "/financial" }] : []),
        ...(roles && (roles.includes(4) || roles.includes(5)) ? [{ title: t('drawer.menu.employees'), link: "/employees" }] : [])
    ]

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.drawerHeader}>
                <img className={classes.garageImage} src={logo} />
                <Typography variant="h5" classes={{ root: classes.boldText }}>Garage</Typography>
            </div>
            <List component="div" disablePadding>
                {drawerTitles.map((item, index) => (
                    <ListItem
                        onClick={event => handleClick(item.link, index)}
                        selected={selected == index}
                        button
                        key={item.title}
                        classes={{ selected: classes.selected, root: classes.mainSelected }}>
                        <ListItemText disableTypography primary={item.title} />
                    </ListItem>
                ))}
            </List>
            <Box flexGrow="1"></Box>
            <ListItem
                onClick={event => handleClick("/profile", drawerTitles.length)}
                selected={selected == drawerTitles.length}
                button
                style={{ marginTop: 'auto' }}
                classes={{ selected: classes.selected, root: classes.profileSelected }}
            >
                <ListItemAvatar>
                    <Avatar src={employee ? "http://apigarage.crossproduct.ch" + employee.profilePicUrl : null} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText primary={employee.name} disableTypography />
            </ListItem>
        </Drawer>
    )
}

export const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    mainSelected: {
        padding: '15px 55px',
        fontWeight: 500,
        fontSize: '32px',
        '&$selected': {
            backgroundColor: '#0487FF',
            fontWeight: 700,
            color: 'white',
            '&:hover': {
                backgroundColor: '#0487FF',
            }
        },
    },
    profileSelected: {
        padding: '10px 5px 10px 40px',
        marginBottom: '30px',
        fontWeight: 500,
        fontSize: '20px',
        boxSizing: 'border-box',
        whiteSpace: 'wrap',
        overflowWrap: 'break-word',
        '&$selected': {
            backgroundColor: '#0487FF',
            fontWeight: 700,
            color: 'white',
            '&:hover': {
                backgroundColor: '#0487FF',
            }
        },
    },
    selected: {},
    listItemText: {
        fontWeight: 700,
        fontSize: '32px'
    },
    drawerFooter: {
        display: 'flex',
        marginLeft: '30px',
        alignItems: 'center',
        marginBottom: '40px'
    },
    avatar: {
        marginRight: '10px',
        width: '60px',
        height: '60px',
        background: profileImage,
    },
    drawerHeader: {
        display: 'flex',
        padding: '30px',
        alignItems: 'center',
    },
    garageImage: {
        backgroundColor: '#c7c7c7',
        width: '30px',
        height: '30px',
        marginRight: "10px"
    },
    boldText: {
        fontWeight: 700,
        fontSize: '20px'
    }
}));



export default withTranslation()(AppDrawer)