import { Box, Button, IconButton, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { makeStyles } from '@material-ui/styles'
import AddButton from "../../../../../components/buttons/add"
import ModalHeader from "../../../../../components/madal/modal_header"
import { Context } from "../../../../../core/context";
import dataProvider from "../../../../../core/dataProvider";
import PriceField from "../../../../../components/textfield/price_field";
import { useTranslation } from "react-i18next";

function AddToFeesModal(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const handleClose = () => {
        props.setOpen(false);
    };
    const {t} = useTranslation()

    async function addToFees() {
        let value
        switch (props.fee.category) {
            case t('cars.show.fees.general'):
                value = "General"
                break;
            case t('cars.show.fees.appearence'):
                value = "Appearence"
                break;
            case t('cars.show.fees.mechanical'):
                value = "Mechanical"
                break;
            default:
                break;
        }
        let params = {
            title: props.fee.title,
            price: document.getElementById("addToFeesPrice").value,
            category: value
        }
        let data = await dataProvider.create(context, "car/fee/one/" + props.id, { data: params })
        await props.getCar()
        handleClose()
    }

    return (
        <Box className={classes.root}>
            <ModalHeader title={t('cars.show.toDoList.addToFees')} setOpen={props.setOpen} />
            <Box padding="0px 37px 24px 50px">
                <Box marginTop="20px" marginBottom="40px">
                    <Typography gutterBottom variant="h6">{t('cars.show.toDoList.addToFeesDescription')}</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom="40px">
                    <Box flexGrow="1">
                        <Typography className={classes.columnTitle}>{t('cars.show.fees.feeTitle')}</Typography>
                        <Typography className={classes.columnData}>{props.fee.title}</Typography>
                    </Box>
                    <Box flexGrow="1">
                        <Typography className={classes.columnTitle}>{t('cars.show.toDoList.feeType')}</Typography>
                        <Typography className={classes.columnData}>{props.fee.category}</Typography>
                    </Box>
                </Box>
                <Typography className={classes.columnTitle}>{t('cars.show.fees.cost')}</Typography>
                <Box width="50%">
                    <Box display="flex" bgcolor="white" border="1px solid #E4E7ED">
                        <PriceField font={700} defaultValue={0} id="addToFeesPrice"
                            placeholder={t('cars.show.fees.cost')} />
                        <Box padding="13px 10px" bgcolor="#F5F7FA" color="#B6BFCF" fontWeight="500">CHF</Box>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='row-reverse' marginTop="68px">
                    <AddButton
                        onClick={addToFees}
                        text={t('cars.show.toDoList.addToFees')}
                        style={{fontSize: '20px',padding: '12px 20px',fontWeight: 700,
                        marginLeft: '16px',height:'unset' }}/>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}>
                        {t('button.cancel')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '760px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        boxSizing: 'border-box'
    },
    bold: {
        fontWeight: 700,
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
}))

export default AddToFeesModal