import React, { useState } from "react";

const Context = React.createContext();

const ContextProvider = props => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ open: false})

  return (
    <Context.Provider
      value={{
        loading: loading, setLoading: setLoading,
        error: error, setError: setError
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export { ContextProvider, Context };
