import { Box, Button, Collapse, Divider, IconButton, InputBase } from "@material-ui/core"
import React, { useState } from "react"
import SearchIcon from '@material-ui/icons/Search';
import filterIcon from "../../images/filter.png"
import filterSelectedIcon from "../../images/filter-selected.png"
import CustomerFilter from "../../pages/customers/customer_list/customer_filters";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function SearchBar(props) {
    const classes = useStyles()
    const [filterIn, setFilterIn] = useState(false);
    const t = props.t
    const handleFilterIn = () => {
        setFilterIn(!filterIn);
    };
    return (
        <Box>
            <Box className={classes.root}>
                <IconButton>
                    <SearchIcon />
                </IconButton>
                <InputBase className={classes.searchBar} placeholder={t('searchbar.searchInList')} onChange={props.onChange}/>
                <Divider orientation="vertical" flexItem/>
                {!props.filter &&
                    <Button
                        startIcon={<img src={filterIn ? filterSelectedIcon : filterIcon} />}
                        onClick={handleFilterIn}
                        className={classes.filterButton}
                        style={{ color: filterIn ? "#0487FF" : "ButtonText" }}>{t('searchbar.filters')}</Button>
                }
            </Box>
            {!props.filter &&
                <Collapse in={filterIn} style={{ width: '100%', padding: '0px' }}>
                    <CustomerFilter date={props.date} setDate={props.setDate}
                     setFilter={props.setFilter} filter={props.filters} tab={props.tab} customers={props.customers} {...props.t}/>
                </Collapse>
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: 'white',
        border: 'solid 1px #E4E7ED',
        width: '100%',
        padding: '4px',
        boxSizing: 'border-box',
        marginBottom: '1rem',
        "&:focus-within": {
            border: '1px solid #2A303B',
            boxShadow: '0px 4px 16px rgba(182, 191, 207, 0.5)'
        }
    },
    searchBar: {
        flexGrow: "1",
        fontSize: '18px',
    },
    filterButton: {
        backgroundColor: 'white',
        fontSize: '20px',
        padding: "0px 15px",
        marginLeft: '5px',
    },
}))


export default withTranslation()(SearchBar)