import { TextField } from "@material-ui/core"
import React from "react"
import { withStyles } from "@material-ui/styles"
function CustomTextField(props) {
    return (
        <MyTextField variant="outlined" {...props}/>
    )
}

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                border: '1px solid #2A303B'
            },
            "& .Mui-error": {
                border: "1px solid #EB5757"
            },
            fontWeight: '500',
            height: '48px',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)

export default CustomTextField