import { Box, Button, Collapse, Divider, InputAdornment, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CarInformationShowDetial from "../../../../components/car_information_show/car_information_show"
import CarInformationItem from "../../../../components/car_list_card/car_information_item"
import CustomTextField from "../../../../components/textfield/custom_textfield"
import PriceField from "../../../../components/textfield/price_field"
import calendarIcon from "../../../../images/calendar.png"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import dataProvider from "../../../../core/dataProvider"
import { Context } from "../../../../core/context"
import CalendarTextField from "../../../../components/textfield/calender_picker"
import { getDate } from "../../../../core/date"
import { PrintContext } from "../print/print_context"
import moment from "moment"
import { withTranslation } from 'react-i18next';
import AddButton from "../../../../components/buttons/add"
import UndoIcon from '@material-ui/icons/Undo';
import axios from "axios"
import { INPUT_TIMEOUT } from "../../../../core/APP_URL"
import {makeStyles} from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function SellDetail(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [exported, setExported] = useState(props.car.sell && props.car.sell.customer ? (props.car.sell.exported ? "Yes" : "No") : "No")
    const [gurantee, setGurantee] = useState(props.car.sell && props.car.sell.customer ? (props.car.sell.guarantee ? "Yes" : "No") : "No")
    const [customer, setCustomer] = useState({})
    const [dates, setDates] = useState({
        sold_on: props.car.sell && props.car.sell.customer ? props.car.sell.sold_on : "",
        expertise_date: props.car.sell && props.car.expertise_date ? props.car.expertise_date : ""
    })
    const context = useContext(Context)
    const [print, setPrint] = useState(false)
    const { content, setContent } = useContext(PrintContext)
    const [query, setQuery] = useState({ event: "", field: "" })
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))

    function changeField(event, field) {
        if (event && event.target)
            setQuery({ event: event.target.value, field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => sell(undefined, undefined, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    function changePrint() {
        if (print) setContent({ ...content, sell_detail: "" })
        else setContent({ ...content, sell_detail: { customer, sell: props.car.sell, price: document.getElementById("carShowSellPrice").value } })
        setPrint(!print)
    }
    function changeExported(event) {
        // if (roles && roles.includes(1)) {
        setExported(event.target.value)
        changeField(event, "exported")
        // }
    }

    function changeGurantee(event) {
        // if (roles && roles.includes(1)) {
        setGurantee(event.target.value)
        changeField(event, "exported")
        // }
    }

    async function getCustomer() {
        let data = await dataProvider.getOne(context, "customer/one/" + props.car.sell.customer)
        setCustomer(data.data)
    }
    async function undoSell() {
        if (props.car.sell && props.car.sell.customer) {
            let data = await dataProvider.sendInformation(context, "car/sell/undo/" + props.car._id, { data: {} })
            if (data.status === 200) {
                props.setCar(data.data)
                setExported("No")
                setGurantee("No")
                setCustomer({})
                setDates({
                    sold_on: "",
                    expertise_date: ""
                })
                document.getElementById("carShowSellPrice").value = ""
                document.getElementById("sellExpertiseDate").value = ""
                document.getElementById("sold_on").value = ""
            }
        }
        setOpen(false)
    }

    async function sell(e, field, cancelToken) {
        if (customer._id) {//&& roles && roles.includes(1)
            let params = {
                customer_id: customer._id,
                price: parseInt(document.getElementById("carShowSellPrice").value),
                exported: exported === "Yes" ? true : false,
                guarantee: gurantee === "Yes" ? true : false,
                sold_on: dates.sold_on,
                expertise_date: dates.expertise_date
            }
            let data = await dataProvider.sendInformation(context, "car/sell/" + props.car._id, {
                data: params,
                cancelToken
            })
            if (data && data.data)
                props.setCar(data.data)
        }
    }

    useEffect(() => {
        if (customer._id && open)
            sell()
    }, [customer])
    useEffect(() => {
        if (customer._id && open)
            sell()
    }, [dates])

    useEffect(() => {
        if (props.car.sell && props.car.sell.customer)
            getCustomer()
    }, [])

    return (
        <CarInformationItem title={t('cars.show.sellDetail')} open={open} setOpen={setOpen} footer={!props.car.sell || !props.car.sell.customer} print={print} changePrint={changePrint}>
            <Collapse in={(!props.car.sell || !props.car.sell.customer) && !open}>
                <Box>
                    <Typography gutterBottom paragraph className={classes.columnData}>{t('cars.show.sellDetail.emptyExplanation')}</Typography>
                    <Button color="primary" className={classes.sellButton} onClick={e => setOpen(true)} >{t('cars.show.sellDetail.sellThisCar')}</Button>
                </Box>
            </Collapse>
            <Collapse in={!open && (props.car.sell && props.car.sell.customer)}>
                {(customer._id && props.car.sell) &&
                    <Box display="flex">
                        {[
                            { title: t('cars.show.sellDetail.customer'), data: customer.name, flex: "1" },
                            { title: t('cars.show.purchaseDetail.phone'), data: customer.phone_number, flex: "1" },
                            { title: t('cars.show.purchaseDetail.date'), data: getDate(props.car.sell.sold_on).monthNamed, flex: "1" },
                            { title: t('cars.show.purchaseDetail.price'), data: props.car.sell_price, flex: "1", bold: true }
                        ].map(item =>
                            <Box flexGrow={item.flex}>
                                <Typography className={classes.columnTitle}>{item.title}</Typography>
                                <Typography className={classes.columnData} style={{ fontWeight: item.bold ? 'bold' : 'unset' }}>{item.data}</Typography>
                            </Box>
                        )}
                    </Box>
                }
            </Collapse>

            <Collapse in={open} style={{ width: '100%' }}>
                <CarInformationShowDetial open={open} customer={customer} setCustomer={setCustomer} sell={true}
                    date={props.car.sell && props.car.sell.customer ? props.car.sell.sold_on : null} onDateChange={e => setDates({ ...dates, sold_on: e.target.value })} />

                <Box display='flex' marginTop="14px" marginBottom="24px">
                    <Box width="33.33%" marginRight="40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.sellDetail.expertise')}</Typography>
                        <CalendarTextField id="sellExpertiseDate" placeholder={t('cars.show.purchaseDetail.purchasedOnplh')}
                            onDateChange={e => setDates({ ...dates, expertise_date: e.target.value })}
                            date={props.car.sell && props.car.expertise_date && props.car.sell.customer ? props.car.expertise_date : null} />
                    </Box>

                    <Box width="66.66%" display="flex" marginRight="40px">

                        <Box display='flex' flexDirection="column" justifyContent="space-between" marginRight="68px">
                            <Typography className={classes.columnTitle}>{t('cars.show.sellDetail.exported')}</Typography>
                            <RadioGroup value={exported} onChange={changeExported} className={classes.radioGroup}>
                                <FormControlLabel value="No" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: exported == "No" ? "700" : "500" }}>{t('cars.show.sellDetail.no')}</Typography>} />
                                <FormControlLabel value="Yes" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: exported == "Yes" ? "700" : "500" }}>{t('cars.show.sellDetail.yes')}</Typography>} />
                            </RadioGroup>
                        </Box>
                        <Box display='flex' flexDirection="column" justifyContent="space-between" >
                            <Typography className={classes.columnTitle}>{t('cars.show.sellDetail.guarantee')}</Typography>
                            <RadioGroup value={gurantee} onChange={changeGurantee} className={classes.radioGroup}>
                                <FormControlLabel value="No" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: gurantee == "No" ? "700" : "500" }}>{t('cars.show.sellDetail.no')}</Typography>} />
                                <FormControlLabel value="Yes" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: gurantee == "Yes" ? "700" : "500" }}>{t('cars.show.sellDetail.yes')}</Typography>} />
                            </RadioGroup>
                        </Box>
                    </Box>

                </Box>

                <Typography className={classes.columnTitle}>{t('cars.show.sellDetail.soldPrice')}</Typography>
                <Box display="flex" marginBottom="20px">
                    <Box width="33.33%">
                        <Box display="flex" bgcolor="white" border="1px solid #E4E7ED">
                            <PriceField font={700} id="carShowSellPrice" defaultValue={props.car.sell_price} onChange={e => changeField(e, 'sell_price')} placeholder={"Sold Price..."} />
                            <Box padding="13px 10px" bgcolor="#F5F7FA" color="#B6BFCF" fontWeight="500">CHF</Box>
                        </Box>
                    </Box>
                    <Box width="33.33%" margin="0px 40px"></Box>
                    <Box width="33.33%"></Box>
                </Box>
                <Divider style={{ color: "#CAD0DB" }} />

                <Box marginTop="20px" marginBottom="16px">
                    <AddButton text={t('cars.show.sellDetail.undoSell')} startIcon={<UndoIcon />} onClick={undoSell} />
                    {/* <Typography className={classes.columnTitle}>{t('cars.show.sellDetail.undoSell')}</Typography>
                    <Box display="flex">
                        <Typography style={{ marginRight: '5px' }} className={classes.columnData}>{t('cars.show.sellDetail.undoSellExplanation')}  </Typography>
                        <Typography className={classes.linkText} onClick={undoSell}>{t('cars.show.sellDetail.undoSellingCar')}</Typography>
                    </Box> */}
                </Box>
            </Collapse>

        </CarInformationItem>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    linkText: {
        color: '#2F80ED',
        fontWeight: 700,
        cursor: 'pointer'
    },
    sellButton: {
        border: "1px solid #2F80ED",
        height: '48px',
        padding: '14px 20px',
        borderRadius: '0px',
        fontSize: '16px',
        fontWeight: 700
    }
}))


export default withTranslation()(SellDetail)