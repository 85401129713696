import { Box, Breadcrumbs, Button, Typography } from "@material-ui/core"
import React, { useContext, useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import arrowLeftIcon from "../../../images/arrow-left.png"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import removeIcon from "../../../images/remove-orange.png"
import BaseModal from "../../../components/madal/base_modal"
import dataProvider from "../../../core/dataProvider"
import { Context } from "../../../core/context"
import EmployeePersonalInformation from "./deitals/personal_information";
import EmployeeAccessLevels from "./deitals/access_levels";
import RemoveEmployeeModal from "./remove_employee_modal";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function EmployeeShow(props) {
    const history = useHistory()
    const t = props.t
    function handleOnClick() {
        history.goBack()
    }
    const [employee, setEmployee] = useState({})
    const classes = useStyles();
    const context = useContext(Context)
    const { id } = useParams()
    async function getData() {
        context.setLoading(true)
        let data = await dataProvider.getOne(context, "employee/one/" + id)
        if (data && data.data)
            setEmployee(data.data)
        context.setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [id])
    return (
        <Box flexGrow={1}>
            {employee._id &&
                <Box>
                    <Box paddingLeft="40px" paddingRight="24px" bgcolor="#F5F7FA" paddingBottom="31px" boxShadow="0px 4px 20px rgba(182, 191, 207, 0.4)">
                        <img src={arrowLeftIcon} width="27px" height="27px" className={classes.backButton} onClick={handleOnClick} />
                        <Breadcrumbs
                            separator={<NavigateNextIcon className={classes.bredcrumbText} />}>
                            <Typography variant="body1" className={classes.bredcrumbText} >{t('drawer.menu.employees')}</Typography>
                        </Breadcrumbs>
                        <Box marginTop="12px">
                            <Typography className={classes.name} variant="subtitle1">{employee.name}</Typography>
                        </Box>
                    </Box>

                    <Box padding="32px 24px 0px 40px" boxSizing="border-box">
                        <EmployeePersonalInformation employee={employee} />
                        <EmployeeAccessLevels employee={employee} setEmployee={setEmployee} />
                        <RemovingEmployeeCard {...props} id={id} />
                    </Box>

                </Box>
            }
        </Box>
    )
}

function RemovingEmployeeCard(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const t = props.t

    function deleteEmployee() {
        setOpen(true)
    }
    return (
        <Box className="removeCarCard">
            <BaseModal open={open} setOpen={setOpen}>
                <RemoveEmployeeModal setOpen={setOpen} id={props.id} />
            </BaseModal>
            <Typography className={classes.removeTitle} variant="subtitle2">{t('employee.removeThisEmployee')}</Typography>
            <Typography style={{ fontWeight: '500' }} gutterBottom>{t('employee.clickOnDelete')}</Typography>
            <Box display="flex" marginBottom="24px" alignItems="center">
                <Typography className={classes.removeTitle} style={{ marginBottom: '0px', marginRight: '5px' }}>{t('cars.show.removeCar.alert')} ! </Typography>
                <Typography style={{ fontWeight: 'bold', marginRight: '5px' }}>{t("cars.show.removeCar.notReversible")}</Typography>
                <Typography style={{ fontWeight: '500' }}> {t('employee.willRemoved')}</Typography>
            </Box>
            <Button
                onClick={deleteEmployee}
                startIcon={<img src={removeIcon} width="16px" height="21px" style={{ marginLeft: "8px" }} />}
                className={classes.removeButton}>{t('employee.deleteThisEmployee')}</Button>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    bredcrumbText: {
        color: '#778191',
        fontWeight: 500
    },
    name: {
        marginRight: '20px',
        fontWeight: 700,
        color: '#434B59'
    },
    cardTitle: {
        color: "#2A303B",
        fontWeight: 700
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
        marginBottom: '12px'
    },
    removeButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
    backButton: {
        marginTop: '35px',
        marginBottom: '22px',
        cursor: 'pointer'
    }
}))


export default withTranslation()(EmployeeShow)