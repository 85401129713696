import { Box, Divider, Typography } from "@material-ui/core"
import React, { useState } from "react"
import ModalHeader from "../../../../components/madal/modal_header";
import AddCarModalFirstPage from "./first";
import AddCarModalPurchase from "./purchase";
import AddCarModalSecondPage from "./second";
import AddCarModalSuccess from "./success_add_car";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChooseEnterInformationType from "./choose_enter_information_type";
import ScanFile from "./scan_file";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function AddCarModal(props) {
    const [page, setPage] = useState(-1)
    const classes = useStyles()
    const t = props.t

    return (
        <Box className={classes.root}>
            <ModalHeader title={t('carModal.header')} grey onClose={props.onClose} setOpen={props.setOpen} />

            <Box paddingLeft="50px" marginBottom="10px" marginTop="16px">
                {page != 4 ?
                    <Box >
                        <Typography variant="subtitle1" className={classes.title}>
                            {page == -1 ? t('carModal.title1') :
                                page == 0 ? t('carModal.scanTitle') :
                                    page == 1 || page == 2 ? `${t('carModal.informationTitle')} ${page}` :
                                        page == 3 ? t('cars.show.purchaseDetail')
                                            : null}
                        </Typography>
                    </Box>
                    :
                    <Box display="flex" alignItems="center">
                        <CheckCircleIcon className={classes.checkIcon} />
                        <Typography className={classes.title} variant="subtitle1" style={{ color: '#27AE60' }}>{t('carModal.successCarAdded')}</Typography>
                    </Box>
                }
                <Box marginTop="10px" >
                    <Typography variant="h6" style={{ color: '#778191' }}>
                        {page == 4 ?
                            t('carModal.successCarAddedExplanation') :
                            page == -1 ?
                                t('carModal.explanation1') :
                                page == 0 ?
                                    t('carModal.scanExplanation') :
                                    t('carModal.informationExplanation')}
                    </Typography>
                </Box>
            </Box>
            <Box padding={`0px ${page != 4 ? '32px' : '0px'} 0px ${page != 4 ? '50px' : '0px'}`}>
                <Divider />
            </Box>
            <Box>
                {page == -1 ? <ChooseEnterInformationType setPage={setPage} page={page} setOpen={props.setOpen} setCar={props.setCar} {...props} /> :
                    page == 0 ? <ScanFile setPage={setPage} page={page} setOpen={props.setOpen} id={props.car._id} {...props} setCar={props.setCar} car={props.car}/> :
                        page == 1 ? <AddCarModalFirstPage setPage={setPage} page={page} setOpen={props.setOpen} car={props.car} {...props} /> :
                            page == 2 ? <AddCarModalSecondPage setPage={setPage} page={page} setOpen={props.setOpen} setCar={props.setCar} car={props.car} {...props} /> :
                                page == 3 ? <AddCarModalPurchase setPage={setPage} page={page} setOpen={props.setOpen} car={props.car} {...props} /> :
                                    page == 4 ? <AddCarModalSuccess setPage={setPage} page={page} setOpen={props.setOpen} car={props.car} setCar={props.setCar} getCars={props.getCars} {...props} />
                                        : null}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '1056px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        paddingBottom: '24px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    },
    title: {
        fontWeight: 700,
    },
    checkIcon: {
        marginRight: '12px',
        color: '#27AE60',
        fontSize: '32px'
    }
}))

export default withTranslation()(AddCarModal)