import { Box, Typography } from "@material-ui/core"
import React, { useContext, useRef, useState } from "react"
import AddCarModalFooter from "./footer"
import uploadingIcon from "../../../../images/uploading.png"
import uploadedIcon from "../../../../images/uploaded.png"
import smallScanDisabled from "../../../../images/small_scan_disabled.png"
import smallScanEnabled from "../../../../images/small_scan_enabled.png"
import AddButton from "../../../../components/buttons/add"
import dataProvider from "../../../../core/dataProvider"
import { Context } from "../../../../core/context"
import { withTranslation } from 'react-i18next';
import moment from "moment"
import {makeStyles} from '@material-ui/styles'

function ScanFile(props) {
    const classes = useStyles()
    const [selectedFile, setSelectedFile] = useState({})
    const [uploadProgress, setUploadProgress] = useState(0)
    const [scanProgress, setScanProgress] = useState(0)
    const [loading, setLoading] = useState(true)
    const context = useContext(Context)
    const t = props.t

    async function updateCar() {
        let data = await dataProvider.update(context, "car/one/" + props.car._id, { data: { ...props.car } })
    }

    return (
        <Box padding="44px 32px 0px 50px">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box marginBottom="24px">
                    <Box textAlign="center" marginBottom="40px">
                        <UploadButton
                            setLoading={setLoading}
                            scanProgress={scanProgress}
                            setCar={props.setCar}
                            car={props.car}
                            setUploadProgress={setUploadProgress}
                            uploadProgress={uploadProgress}
                            setScanProgress={setScanProgress}
                            file={selectedFile}
                            setFile={setSelectedFile} id={props.id} {...props} />
                    </Box>
                    <Box alignItems="center" marginBottom="20px">
                        <img src={uploadProgress === 100 ? uploadedIcon : uploadingIcon} width="19px" height="24px" className={classes.image} />
                        <Typography variant="subtitle1" style={{ display: "inline-flex", alignItems: 'center', color: uploadProgress === 100 ? "#27AE60" : 'unset' }}
                            className={classes.medium} noWrap>{t('carModal.uploadingTheFile')} : &nbsp;
                    <Typography className={classes.bold} variant="subtitle1">{parseInt(uploadProgress)}%</Typography>  </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" color={scanProgress === 0 ? "#B6BFCF" : "#2A303B"} fontWeight="500">
                        <img src={scanProgress === 0 ? smallScanDisabled : smallScanEnabled} width="21px" height="25px" className={classes.image} />
                        <Typography variant="subtitle1" style={{ display: "inline-flex", alignItems: 'center' }}
                            className={classes.medium} noWrap>{t('carModal.scaningTheFile')} : &nbsp;
                    <Typography className={classes.bold} variant="subtitle1" style={{ marginLeft: '30px' }}>{scanProgress}%</Typography>  </Typography>
                    </Box>
                </Box>
            </Box>
            <AddCarModalFooter page={props.page} nextOnClick={updateCar} setPage={props.setPage} loading={loading} {...props} />
        </Box>
    )
}

function UploadButton(props) {
    const hiddenFileInput = useRef(null);
    const context = useContext(Context)
    const t = props.t
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    async function changeFile(event) {
        props.setUploadProgress(0)
        props.setScanProgress(0)
        props.setFile(event.target.files[0])
        const formData = new FormData();
        formData.append("file", event.target.files[0])
        const uploadProgress = (progressEvent) => {
            if (progressEvent.lengthComputable) {
                props.setUploadProgress(progressEvent.loaded * 100 / progressEvent.total)
            }
        }
        let data = await dataProvider.sendInformation(context, "car/file/upload/" + props.id, { data: formData, progress: uploadProgress })
        if (data && data.data && data.data.link) {
            let width = 0
            let loading = true
            var interval = setInterval(function () {
                width += 10;
                if (width < 100 || (width == 100 && !loading))
                    props.setScanProgress(width)
                if (width >= 100 && !loading) {
                    props.setScanProgress(100)
                    clearInterval(interval);
                }
            }, 1000);
            let res = await dataProvider.create(context, "car/file/ocr", { data: { path: data.data.link } })
            let result = await dataProvider.create(context, "car/file/one/" + props.id, {
                data: {
                    title: event.target.files[0].name,
                    link: data.data.link
                }
            })
            if (res && res.data) {
                let date = res.data.reg_date
                let regex = /(\d\d\.\d\d\.)(\d{2,4})/
                let match = date.match(regex)
                let lastDate = match[1]
                if (match[2].length == 2) {
                    let year = parseInt(match[2])
                    if (year > 21)
                        lastDate = lastDate + (1900 + year)
                    else lastDate = lastDate + (2000 + year)
                } else {
                    lastDate = lastDate + match[2]
                }
                if (moment(lastDate, "DD.MM.yyyy").isValid()) res.data.reg_date = moment(lastDate, "DD.MM.yyyy").format()
                else res.data.reg_date = ""
                props.setCar({ ...props.car, ...res.data })
                props.setLoading(false)
                loading = false
            }
        }
        hiddenFileInput.current.value = ""
        props.setFile({})
    }
    return (
        <Box style={{ display: props.uploadProgress > 0 ? 'none' : 'block' }}>
            <input accept="image/jpeg,image/png,application/pdf" type="file" ref={hiddenFileInput}
                onChange={changeFile}
                style={{ display: 'none' }} />
            <AddButton text={t('cars.show.files.uploadNewFile')} onClick={handleClick} />
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700,
    },
    medium: {
        fontWeight: 500,
    },

    image: {
        marginRight: '13px'
    }
}))

export default withTranslation()(ScanFile)