import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import BackButton from "../../../../components/buttons/back"
import DoneButton from "../../../../components/buttons/done"
import NextButton from "../../../../components/buttons/next"
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function AddCarModalFooter(props) {
    const classes = useStyles()
    const t = props.t
    const nextPage = () => {
        if (props.page !== -1 && !props.disableDefault)
            props.setPage(props.page + 1);
        if (props.nextOnClick) props.nextOnClick()
    };
    const previousPage = () => {
        props.setPage(props.page - 1);
    };

    function handleClose() {
        props.setCar({})
        props.setOpen(false)
        props.getCars()
    }
    return (
        <Box display='flex' alignItems="center" paddingTop={`${props.done ? '0px' : '52px'}`}>
            <Typography className={classes.bold} style={{ color: '#B6BFCF', marginRight: 'auto' }}>{t('carModal.step')} {props.page <= 0 ? 1 : props.page + 1} / 5</Typography>
            {props.done ?
                <DoneButton onClick={handleClose} title={t('button.done')} />
                :
                <Box>
                    {props.page == -1 ?
                        <Button onClick={handleClose} className={classes.cancelButton}>{t('button.cancel')}</Button>
                        :
                        <BackButton onClick={previousPage} {...props.t} />
                    }
                    <NextButton onClick={nextPage} disabled={props.loading} {...props.t} />
                </Box>
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        height: '48px'
    },
}))

export default withTranslation()(AddCarModalFooter)