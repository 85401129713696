import { Box, Breadcrumbs, Button, Checkbox, Collapse, FormControlLabel, Typography } from "@material-ui/core"
import React, { useContext, useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import arrowLeftIcon from "../../../images/arrow-left.png"
import "./car_show.scss"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Condition from "../../../components/car_list_card/car_condition"
import removeIcon from "../../../images/remove-orange.png"
import CarInformation from "./details/car_information"
import ToDoList from "./details/todo_list/to_do_list"
import PurchaseDetail from "./details/purchase_detail"
import SellDetail from "./details/sell_detail"
import Fees from "./details/fees"
import ExtraWithCar from "./details/extra_with_cars"
import AddButton from "../../../components/buttons/add"
import Summary from "./details/summary"
import BaseModal from "../../../components/madal/base_modal"
import RemoveCarModal from "./remove_car_modal"
import dataProvider from "../../../core/dataProvider"
import { Context } from "../../../core/context"
import Files from "./details/files/files"
import PrintContent from "./print/print_content"
import { useReactToPrint } from 'react-to-print';
import { PrintContextProvider } from "./print/print_context"
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function CarShow(props) {
    const history = useHistory()
    function handleOnClick() {
        history.goBack()
    }
    const [car, setCar] = useState({})
    const [sum, setSum] = useState({})
    const classes = useStyles();
    const context = useContext(Context)
    const { id } = useParams()
    const printRef = useRef()
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))
    async function getData() {
        context.setLoading(true)
        let data = await dataProvider.getOne(context, "car/one/" + id)
        if (data && data.data)
            setCar(data.data)
        else setCar({})
        context.setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [id])
    async function getSum() {
        let data = await dataProvider.getOne(context, "car/summary/" + id)
        if (data && data.data)
            setSum(data.data)
    }
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <div className="carShowContainer">
            {car._id &&
                <div>
                    <div className="carInfromationHeader">
                        <img src={arrowLeftIcon} width="27px" height="27px" className="carShowBackButton" onClick={handleOnClick} />
                        <Breadcrumbs
                            separator={<NavigateNextIcon className={classes.bredcrumbText} />}>
                            <Typography variant="body1" className={classes.bredcrumbText} >{t('drawer.menu.cars')}</Typography>
                            <Typography variant="body1" className={classes.bredcrumbText} >{car.type == "stock" ? t("cars.show.inStock") : t('cars.list.condition.sold')}</Typography>
                        </Breadcrumbs>
                        <div className="carShowNameContainer">
                            <Typography className={classes.name} variant="subtitle1">{car.brand_type}</Typography>
                            <Condition condition={car.type} t={props.t} />
                            <Box marginLeft="auto">
                                <AddButton noIcon onClick={handlePrint} text={t("cars.show.printInfo")} />
                            </Box>
                        </div>
                    </div>
                    <div className="carShowBody">
                        <PrintContextProvider>
                            <PrintContent ref={printRef} t={props.t} pageStyle={pageStyle} />
                            {(roles && roles.includes(2)) &&
                                <Summary getSum={getSum} sum={sum} id={id} car={car} setCar={setCar} />
                            }
                            <CarInformation car={car} />
                            {(roles && roles.includes(2)) &&
                                <PurchaseDetail car={car} setCar={setCar} />
                            }
                            {(roles && roles.includes(0)) &&
                                <Fees getSum={getSum} id={id} car={car}/>
                            }
                            <ToDoList id={id} car={car} getCar={getData} />
                            {(roles && roles.includes(2)) &&
                                <SellDetail car={car} setCar={setCar} />
                            }
                            {(roles && roles.includes(1)) &&
                                <Files id={id} />
                            }
                            <ExtraWithCar id={id} />
                        </PrintContextProvider>
                        {(roles && roles.includes(1)) &&
                            <RemovingCarCard id={id} {...props} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

function RemovingCarCard(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const t = props.t
    function deleteCar() {
        setOpen(true)
    }
    return (
        <div className="removeCarCard">
            <BaseModal open={open} setOpen={setOpen}>
                <RemoveCarModal setOpen={setOpen} id={props.id} {...props} />
            </BaseModal>
            <Typography className={classes.removeTitle} variant="subtitle2">{t('cars.show.removeCar')}</Typography>
            <Typography style={{ fontWeight: '500' }} gutterBottom>{t('cars.show.removeCar.clickOnDelete')}</Typography>
            <Box display="flex" marginBottom="18px" alignItems="center">
                <Typography className={classes.removeTitle} style={{ marginBottom: '0px', marginRight: '5px' }}>{t('cars.show.removeCar.alert')} ! </Typography>
                <Typography style={{ fontWeight: 'bold', marginRight: '5px' }}>{t("cars.show.removeCar.notReversible")}</Typography>
                <Typography style={{ fontWeight: '500' }}> {t('cars.show.removeCar.willRemoved')}</Typography>
            </Box>
            <Box display="flex" marginBottom="24px" alignItems="center" >
                <Typography style={{ fontWeight: '500', marginRight: '5px' }}>{t('cars.show.removeCar.remark')}</Typography>
                <Typography className={classes.sellSection} color="primary">{t('cars.show.removeCar.sellSection')}</Typography>
                <Typography style={{ fontWeight: '500' }}> {t('cars.show.removeCar.above')} </Typography>
            </Box>

            <Button
                onClick={deleteCar}
                startIcon={<img src={removeIcon} width="16px" height="21px" style={{ marginLeft: "8px" }} />}
                className={classes.removeButton}>{t('cars.show.removeCar.deleteThisCar')}</Button>
        </div>
    )
}

const pageStyle = `
  @page {
    size: 80mm 50mm;
    margin: 5mm 5mm 5mm 5mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }`
const useStyles = makeStyles(theme => ({
    bredcrumbText: {
        color: '#778191',
        fontWeight: 500
    },
    name: {
        marginRight: '20px',
        fontWeight: 700,
        color: '#434B59'
    },
    cardTitle: {
        color: "#2A303B",
        fontWeight: 700
    },
    expandText: {
        fontWeight: 700,
        color: "#2F80ED",
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
        marginBottom: '12px'
    },
    sellSection: {
        cursor: 'pointer',
        fontWeight: 500,
        marginRight: '5px',
        '&:hover': {
            color: 'black'
        }
    },
    removeButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
}))


export default withTranslation()(CarShow)