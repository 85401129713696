import { Box, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import CustomerShowItem from "../../../../components/customer_show_item/customer_show_item";
import { withTranslation } from 'react-i18next';
import BaseModal from "../../../../components/madal/base_modal";
import AddButton from "../../../../components/buttons/add";
import ChangePasswordModal from "../../../../components/madal/change_password"
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import ErrorPopup from "../../../../components/error_popup/error_popup";
import {makeStyles} from '@material-ui/styles'

function EmployeePersonalInformation(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const [passwordModal, setPasswordModal] = useState(false)
    const [query, setQuery] = useState({ event: "", field: "" })
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const t = props.t
    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () =>  clearTimeout(timeOutId)
    }, [query])
    async function req(event, field, cancelToken) {
        if (field) {
            let data = await dataProvider.update(context, "employee/one/" + props.employee._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
            if (field === "email") {
                setErrorEmail(data.status !== 200)
            } else if (field === "username") {
                setErrorUsername(data.status !== 200)
            }
        }
    }
    return (
        <CustomerShowItem title={t('employee.show.personalInformation')}>
            <Box padding='0px 50px'>
                <BaseModal open={passwordModal} setOpen={setPasswordModal}>
                    <ChangePasswordModal setOpen={setPasswordModal} id={props.employee._id} />
                </BaseModal>
                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t("cars.list.name")}</Typography>
                        <CustomTextField onChange={event => changeField(event, "name")} defaultValue={props.employee.name} placeholder={t('employee.modal.nameplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.phoneNumber')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "phone_number")} defaultValue={props.employee.phone_number} placeholder={t('employee.modal.phoneplh')} />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.email')}</Typography>
                        <ErrorPopup title={t('employee.show.emailerr')} open={errorEmail} >
                            {/* <TextField variant="outlined" error={errorEmail} onChange={event => changeField(event, "email")} defaultValue={props.employee.email} placeholder={t('employee.modal.emailplh')} /> */}
                            <CustomTextField error={errorEmail} onChange={event => changeField(event, "email")} defaultValue={props.employee.email} placeholder={t('employee.modal.emailplh')} />
                        </ErrorPopup>
                    </Box>

                </Box>
                <Box display='flex' marginBottom="24px">
                    <Box width="33.33%" >
                        <Typography className={classes.columnTitle}>{t('employee.jobTitle')}</Typography>
                        <CustomTextField
                            onChange={event => changeField(event, "job_title")}
                            InputProps={{ classes: { input: classes.boldTextfield } }}
                            defaultValue={props.employee.job_title} style={{ fontWeight: '700' }} placeholder={t('employee.modal.jobTitleplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('loginEmail')}</Typography>
                        <ErrorPopup title={t('employee.show.usernameerr')} open={errorUsername} >
                            <CustomTextField error={errorUsername} onChange={event => changeField(event, "username")} 
                            defaultValue={props.employee.username} placeholder={t('loginEmail')} />
                        </ErrorPopup>
                    </Box>
                    <Box width="33.33%" display="flex" flexDirection="column" justifyContent='flex-end'>
                        <AddButton text={t('employee.changePasswordModal.text')} noIcon onClick={e => setPasswordModal(true)} />
                    </Box>
                </Box>
            </Box>
        </CustomerShowItem >
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    boldTextfield: {
        fontWeight: '700'
    }
}))


export default withTranslation()(EmployeePersonalInformation)