import React from "react"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import expandIcon from "../../images/expand.png"
import expandLessIcon from "../../images/expand-less.png"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import "./car_list_card.scss"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function CarInformationItem(props) {
    const classes = useStyles()
    const t = props.t

    function handleClick() {
        props.setOpen(!props.open)
    }
    return (
        <div className="carInformationCard" style={{
            border: props.open ? '2px solid #2A303B' : '1px solid #CAD0DB',
            boxShadow: props.open ? '0px 10px 20px -5px rgba(182, 191, 207, 0.6)' : '0px 10px 20px -5px rgba(182, 191, 207, 0.3);'
        }}>
            <Box padding="16px 0px 0px 14px" display="flex" alignItems="center">
                {!props.open ?
                    <NavigateNextIcon fontSize="large" style={{ color: '#2A303B' }} />
                    :
                    <KeyboardArrowDownIcon fontSize="large" style={{ color: '#2A303B' }} />
                }
                <Typography className={classes.cardTitle} variant="subtitle2">{props.title}</Typography>
            </Box>
            <div className="carCardBody">
                {props.children}
            </div>
            {!props.footer &&
                <div className="carCardFooter">
                    <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleClick}>
                        <img src={props.open ? expandLessIcon : expandIcon} width="10px" height="12px" style={{ marginRight: "11px" }} />
                        <Typography className={classes.expandText} style={{ color: props.open ? "#778191" : "#2F80ED" }}>
                            {props.open ? t("cars.show.minimizeThis") : t("cars.show.seeMoreEdit")}</Typography>
                    </Box>
                    {!props.checkbox &&
                        <FormControlLabel
                            checked={props.print ? props.print : undefined}
                            onChange={props.changePrint ? props.changePrint : undefined}
                            style={{ marginLeft: 'auto' }}
                            control={<Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon className={classes.bredcrumbText} />} style={{ color: '#2F80ED' }} />}
                            label={<Typography variant="body2" className={classes.expandText} style={{ color: "#778191" }}>{t('cars.show.toPrint')}</Typography>}
                            labelPlacement="start"
                        />
                    }
                    {props.fees &&
                        <Box display='flex' alignItems="center" marginLeft="auto">
                            <Typography variant="body2" className={classes.expandText} style={{ color: "#778191", marginRight: '32px' }}>{t('cars.show.toPrint')} :</Typography>
                            <FormControlLabel
                                checked={props.print ? props.print.General : undefined}
                                onChange={props.changePrint}
                                name="General"
                                className={classes.formLabel}
                                control={<Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon className={classes.bredcrumbText} />} style={{ color: '#2F80ED' }} />}
                                label={<Typography variant="body2" className={classes.medium}>{t('cars.show.fees.generalFees')}</Typography>}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                checked={props.print ? props.print.Appearence : undefined}
                                onChange={props.changePrint}
                                name="Appearence"
                                className={classes.formLabel}
                                control={<Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon className={classes.bredcrumbText} />} style={{ color: '#2F80ED' }} />}
                                label={<Typography variant="body2" className={classes.medium}>{t('cars.show.fees.appearenceFees')}</Typography>}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                checked={props.print ? props.print.Mechanical : undefined}
                                onChange={props.changePrint}
                                name="Mechanical"
                                className={classes.formLabel}
                                control={<Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon className={classes.bredcrumbText} />} style={{ color: '#2F80ED' }} />}
                                label={<Typography variant="body2" className={classes.medium} style={{ marginRight: '0px' }}>
                                    {t('cars.show.fees.mechanicalFees')}</Typography>}
                                labelPlacement="end"
                            />
                        </Box>
                    }
                </div>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    bredcrumbText: {
        color: '#778191',
        fontWeight: 500
    },
    cardTitle: {
        color: "#2A303B",
        fontWeight: 700
    },
    expandText: {
        fontWeight: 700,
    },
    medium: {
        fontWeight: 500,
        color: "#778191",
        marginRight: '24px'
    },
    formLabel: {
        marginRight: '0px'
    }
}))

export default withTranslation()(CarInformationItem)