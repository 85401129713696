import { Box, Button, Collapse, Divider, IconButton, InputBase, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import addIcon from "../../../images/add.png"
import BaseModal from "../../../components/madal/base_modal";
import SearchBar from "../../../components/textfield/searchbar";
import dataProvider from "../../../core/dataProvider";
import { Context } from "../../../core/context";
import EmployeeListTable from "./employee_table_list";
import AddEmployeeModal from "./add_car_modal/add_employee_modal";
import { withTranslation } from 'react-i18next';
import ProgressBar from "../../../components/backdrop/progress"
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../core/APP_URL";
import {makeStyles} from '@material-ui/styles'
import AddButton from "../../../components/buttons/add";

function EmployeesList(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [employees, setEmployees] = useState([])
    const context = useContext(Context)
    const t = props.t
    const [sort, setSort] = useState({ name: "", order: -1 })
    const [regex, setRegex] = useState({ 'employee_list.name': '' })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => getEmployees(token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [regex])

    useEffect(() => {
        if (Object.keys(sort).includes("title"))
            getEmployees()
    }, [sort])

    async function getEmployees(cancelToken) {
        setLoading(true)
        let data = await dataProvider.getList(context, "employee/list", { sort, regex, cancelToken })
        if (data && data.data && data.data.employee_list)
            setEmployees(data.data.employee_list)
        else setEmployees([])
        setLoading(false)
    }

    const openModal = () => {
        setOpen(true)
    };

    return (
        <Box flexGrow={1}>
            <BaseModal open={open} setOpen={setOpen}>
                <AddEmployeeModal getEmployees={getEmployees} setOpen={setOpen} {...props.t} />
            </BaseModal>
            <Box padding="30px 40px 20px 40px"
                boxShadow="0px 4px 20px rgba(182, 191, 207, 0.4)">
                <Box marginBottom="22px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.title}>{t('drawer.menu.employees')}</Typography>
                    <AddButton onClick={openModal} text={t("employee.addNewEmployee")} style={{ fontSize: "20px" }} />
                </Box>
                <SearchBar filter="true" onChange={e => setRegex({ 'employee_list.name': e.target.value })} />
            </Box>

            <Box padding="16px 24px 0px 40px" position="relative" height="60vh">
                {loading ? <ProgressBar /> :
                    <EmployeeListTable employees={employees} sort={sort} setSort={setSort} {...props.t} />
                }
            </Box>

        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700
    },
}));







export default withTranslation()(EmployeesList)