import moment from "moment";
import i18n from "i18next";

export function getDate(input) {
    let date = {
        normal: moment(input).format("DD.MM.YYYY"),
        monthNamed: input !== "" || input !== null ? moment(input).locale(i18n.language).format('LL') : "",
        time: moment(input).format('HH:mm:ss'),
        relative: moment(input).fromNow(),
        year: input !== "" && input ? moment(input).format("YYYY") : "",
    }
    return date
}

export function getMonths() {
    moment.locale(i18n.language);    
    return moment.months();
}