import { Box, Button, Divider, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AntSwitch from "../../../../components/buttons/ant_switch";
import { withTranslation } from 'react-i18next';
import { Context } from "../../../../core/context";
import dataProvider from "../../../../core/dataProvider";
import AddEmployeeModalFooter from "./footer"
import { makeStyles } from '@material-ui/styles'

function AddEmployeeModalAccessLevel(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const [roles, setRoles] = useState([{
        code: 0
        , check: true
    }, {
        code: 1
        , check: false
    }, {
        code: 2
        , check: false
    }, {
        code: 3
        , check: false
    }, {
        code: 4
        , check: false
    },])
    const t = props.t

    function changeRole(event, code) {
        let list = JSON.parse(JSON.stringify(roles))
        list.filter(item => item.code === code)[0].check = event.target.checked
        setRoles(list)
    };

    async function createEmployee() {
        let params = JSON.parse(sessionStorage.getItem('addEmployee'))
        params.roles = roles.filter(item => item.check).map(e => e.code)
        let data = await dataProvider.create(context, "employee/one", { data: params })
        if (data && data.status == 200) {
            sessionStorage.removeItem("addEmployee")
            props.setOpen(false)
            props.getEmployees()
        } else {
            props.setPage(1)
        }
    }


    return (
        <Box padding="28px 32px 0px 50px">
            <Box padding='0px 50px' display="flex" justifyContent="space-between" marginBottom="34px">
                <Box width="45%">
                    {[
                        { title: t('employee.show.roleFee'), field: "", code: 0 },
                        { title: t('employee.show.roleCar'), field: "", code: 1 },
                        { title: t('financial'), field: "", code: 3, last: true }
                    ].map(item =>
                        <Box display="flex" alignItems="center" justifyContent="space-between"
                            marginBottom={item.last ? '0px' : "36px"}>
                            <Typography noWrap className={classes.columnData}>{item.title}</Typography>
                            <FormControlLabel
                                control={
                                    <AntSwitch checked={roles.filter(e => e.code === item.code)[0].check}
                                        onChange={event => changeRole(event, item.code)} />}
                                label={roles.filter(e => e.code === item.code)[0].check ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')}
                            />
                        </Box>
                    )
                    }

                </Box>
                <Divider orientation="vertical" flexItem />
                <Box width="45%">
                    {[
                        { title: t("employee.show.roleCustomer"), field: "", code: 2 },
                        { title: t('employee.show.roleEmployee'), field: "", code: 4, last: true },
                    ].map(item =>
                        <Box display="flex" alignItems="center" justifyContent="space-between"
                            marginBottom={item.last ? '0px' : "36px"}>
                            <Typography noWrap className={classes.columnData}>{item.title}</Typography>
                            <FormControlLabel
                                disabled={item.code === 0}
                                control={
                                    <AntSwitch checked={roles.filter(e => e.code === item.code)[0].check}
                                        onChange={event => changeRole(event, item.code)} />}
                                label={roles.filter(e => e.code === item.code)[0].check ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')}
                            />
                        </Box>
                    )
                    }

                </Box>
            </Box>
            <AddEmployeeModalFooter page={props.page} setPage={props.setPage} setOpen={props.setOpen} nextOnClick={createEmployee} {...props.t} />

        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
}))

export default withTranslation()(AddEmployeeModalAccessLevel)
