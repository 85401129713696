import { Box, Button, Collapse, Divider, IconButton, InputBase, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import {makeStyles} from '@material-ui/styles'
import addIcon from "../../../images/add.png"
import BaseModal from "../../../components/madal/base_modal";
import CustomerModal from "../../../components/car_information_show/customer_modal";
import CustomerTabs from "./customer_tabs";
import SearchBar from "../../../components/textfield/searchbar";
import dataProvider from "../../../core/dataProvider";
import { Context } from "../../../core/context";
import { withTranslation } from 'react-i18next';
import { INPUT_TIMEOUT } from "../../../core/APP_URL";
import axios from "axios";
import AddButton from "../../../components/buttons/add";

function CustomersList(props) {
    const classes = useStyles();
    const context = useContext(Context)
    const [open, setOpen] = useState(false);
    const [customers, setCustomers] = useState([])
    const [sort, setSort] = useState({ name: "", order: -1 })
    const [regex, setRegex] = useState({ 'customer_list.brand_type': '' })
    const [filter, setFilter] = useState({})
    const [tab, setTab] = useState(0);
    const [date, setDate] = useState({})
    const [loading, setLoading] = useState(true)
    const t = props.t

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => sortCustomers(token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [regex])

    useEffect(() => {
        if (Object.keys(sort).includes("title") ||
            Object.keys(filter).length > 0 ||
            Object.keys(date).length > 0)
            sortCustomers()
    }, [sort, filter, date])
    async function sortCustomers(cancelToken) {
        setLoading(true)
        let data = await dataProvider.getList(context, "customer/list", { sort, regex, filter, date, cancelToken })
        if (data && data.data && data.data.customer_list)
            setCustomers(data.data.customer_list)
        else setCustomers([])
        setLoading(false)
    }

    async function getCustomers() {
        setLoading(true)
        let data = await dataProvider.getList(context, "customer/list")
        if (data && data.data && data.data.customer_list)
            setCustomers(data.data.customer_list)
        else setCustomers([])
        setLoading(false)
    }

    const openModal = () => {
        setOpen(true)
    };

    return (
        <Box flexGrow={1}>
            <BaseModal open={open} setOpen={setOpen}>
                <CustomerModal getCustomers={getCustomers} setOpen={setOpen} {...props.t} />
            </BaseModal>
            <Box padding="0px 40px" margin="30px 0px 20px 0px">
                <Box marginBottom="20px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.title}>{t('drawer.menu.customers')}</Typography>
                    <AddButton onClick={openModal} text={t("customer.addNewCustomer")} style={{ fontSize: "20px" }} />
                </Box>
                <SearchBar tab={tab} onChange={e => setRegex({ 'customer_list.name': e.target.value })} {...props.t}
                    setFilter={setFilter} filters={filter} date={date} setDate={setDate} customers={customers} />

            </Box>
            <CustomerTabs tab={tab} setTab={setTab} sort={sort} setSort={setSort}
                setDate={setDate} date={date} customers={customers} loading={loading} {...props.t} />

        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700
    },
}));







export default withTranslation()(CustomersList)