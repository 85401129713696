import { Box, Button, Collapse, Divider, IconButton, InputBase, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import "./cars_list.scss"
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search';
import filterIcon from "../../../images/filter.png"
import addIcon from "../../../images/add.png"
import filterSelectedIcon from "../../../images/filter-selected.png"
import Filters from "./filters";
import CarTabs from "./tabs";
import AddCarModal from "./add_car_modal/add_car_modal";
import BaseModal from "../../../components/madal/base_modal";
import { Context } from "../../../core/context";
import dataProvider from "../../../core/dataProvider";
import { withTranslation } from 'react-i18next';
import { INPUT_TIMEOUT } from "../../../core/APP_URL";
import axios from "axios";
import AddButton from "../../../components/buttons/add";
import WarningModal from "./add_car_modal/warning_modal"

function CarsList(props) {
    const classes = useStyles();
    const [filterIn, setFilterIn] = useState(false);
    const [open, setOpen] = useState(false);
    const [carWarningModal, setWarningModal] = useState(false);
    const [tab, setTab] = useState(0);
    const context = useContext(Context)
    const [allCars, setAllCars] = useState([])
    const [sort, setSort] = useState({ name: "", order: -1 })
    const [regex, setRegex] = useState({ 'car_list.brand_type': '' })
    const [date, setDate] = useState({})
    const [filter, setFilter] = useState({})
    const [loading, setLoading] = useState(true)
    const [modalCar, setModalCar] = useState({})
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            sortCars(token)
        }, INPUT_TIMEOUT);
        return () => cancel("") || clearTimeout(timeOutId)
    }, [regex])
    useEffect(() => {
        if (Object.keys(sort).includes("title") ||
            Object.keys(filter).length > 0 ||
            Object.keys(date).length > 0)
            sortCars()
    }, [sort, filter, date])

    async function getCars() {
        setLoading(true)
        let data = await dataProvider.getList(context, "car/list")
        if (data && data.data && data.data.car_list)
            setAllCars(data.data.car_list)
        else setAllCars([])
        setLoading(false)
    }

    async function sortCars(cancelToken) {
        setLoading(true)
        let data = await dataProvider.getList(context, "car/list", { sort, regex, filter, date, cancelToken })
        if (data && data.data && data.data.car_list)
            setAllCars(data.data.car_list)
        else setAllCars([])
        setLoading(false)
    }
    const handleFilterIn = () => {
        setFilterIn(!filterIn);
    };

    const openModal = () => {
        if (roles && roles.includes(1))
            setOpen(true)
    };

    async function deleteModalCar() {
        setWarningModal(false)
        let data
        if (modalCar._id)
            data = await dataProvider.delete(context, "car/one/" + modalCar._id)
        setOpen(false)
        getCars()
    }
    function openWarningModal() {
        setWarningModal(true)
    }

    return (
        <div className="carsListContainer">
            {(roles && roles.includes(1)) &&
                <>
                    <BaseModal open={carWarningModal} setOpen={setWarningModal} >
                        <WarningModal deleteCar={deleteModalCar} setOpen={setWarningModal}
                            car={modalCar}
                            setCar={setModalCar}
                            {...props} />
                    </BaseModal>
                    <BaseModal open={open} setOpen={setOpen} onClose={openWarningModal}>
                        <AddCarModal onClose={openWarningModal} setOpen={setOpen} getCars={getCars}
                            car={modalCar}
                            setCar={setModalCar}
                            {...props}
                        />
                    </BaseModal>
                </>
            }
            <div className="carsListHeader">
                <Box marginBottom="20px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.title}>{t("drawer.menu.cars")}</Typography>
                    {(roles && roles.includes(1)) &&
                        <AddButton onClick={openModal} text={t("cars.list.addNewCar")} style={{ fontSize: "20px" }} />
                    }
                </Box>
                <div className="carsListSearchContainer">
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                    <InputBase className={classes.searchBar} placeholder={t('searchbar.searchInList')}
                        onChange={e => setRegex({ ...regex, 'car_list.brand_type': e.target.value })} />
                    <Divider orientation="vertical" />
                    <Button
                        startIcon={<img src={filterIn ? filterSelectedIcon : filterIcon} />}
                        onClick={handleFilterIn}
                        className={classes.filterButton}
                        style={{ color: filterIn ? "#0487FF" : "ButtonText" }}>
                        {t('searchbar.filters')}
                    </Button>
                </div>
                <Collapse in={filterIn} style={{ width: '100%', padding: '0px' }}>
                    <Filters regex={regex} setRegex={setRegex} cars={allCars} filter={filter} setFilter={setFilter} date={date} setDate={setDate} />
                </Collapse>
            </div>
            <CarTabs tab={tab} setTab={setTab} sort={sort} setSort={setSort} allCars={allCars} loading={loading} />

        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700
    },
    searchBar: {
        flexGrow: "1",
        fontSize: '18px',
    },
    filterButton: {
        backgroundColor: 'white',
        fontSize: '20px',
        padding: "0px 15px",
        marginLeft: '5px',
    },

}));

export default withTranslation()(CarsList)