import React, { useContext } from 'react'
import Backdrop from '@material-ui/core/Backdrop';
import { Context } from '../../core/context';
import ProgressBar from "./progress"

function LoadingBackdrop(props) {
    const context = useContext(Context)
    return (
        <div>
            <Backdrop open={context.loading} style={{zIndex:10000}}>
                <ProgressBar white />
            </Backdrop>
        </div>
    )
}

export default LoadingBackdrop