import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Collapse, TextField, Typography } from "@material-ui/core"
import CarInformationItem from "../../../../../components/car_list_card/car_information_item"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CustomTextField from "../../../../../components/textfield/custom_textfield";
import PriceField from "../../../../../components/textfield/price_field";
import Recyclerbin from "../../../../../components/buttons/recyclrebin";
import AddButton from "../../../../../components/buttons/add";
import { Context } from "../../../../../core/context";
import { addTodo, changeCategory, changeFieldTodo, deleteTodo, getTodos, updateTodoState } from "./todo_api";
import { PrintContext } from "../../print/print_context";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../../core/APP_URL";
import { makeStyles } from '@material-ui/styles'
import CustomAutocomplete from "../../../../../components/textfield/autocomplete";
import { useMemo } from "react";
import dataProvider from "../../../../../core/dataProvider";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BaseModal from "../../../../../components/madal/base_modal";
import AddToFeesModal from "./add_to_fees_modal";

function ToDoList(props) {
    const classes = useStyles()
    const printContext = useContext(PrintContext)
    const context = useContext(Context)
    const t = props.t
    const [feeModal, setFeeModal] = useState(false)
    const [open, setOpen] = useState(false)
    const [fee, setFee] = useState({})
    const [todos, setTodos] = useState([])
    const [print, setPrint] = useState(false)
    const [query, setQuery] = useState({ event: "", field: "", id: "" })
    const categoryOptions = useMemo(() =>
        [
            { name: t('cars.show.fees.general') },
            { name: t('cars.show.fees.appearence') },
            { name: t('cars.show.fees.mechanical') }
        ]
        , [])
    const priorityOptions = useMemo(() =>
        [
            { name: t('cars.show.todo.Important') },
            { name: t('cars.show.todo.Normal') }
        ]
        , [])

    function changeField(event, field, id) {
        setQuery({ event: event, field: field, id: id })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => changeFieldTodo(context, query.event, query.field, props.id, query.id, setTodos, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    useEffect(() => {
        getTodos(context, setTodos, props.id)
    }, [])
    function changePrint() {
        if (printContext) {
            if (print) printContext.setContent({ ...printContext.content, to_do: "" })
            else printContext.setContent({ ...printContext.content, to_do: todos })
            setPrint(!print)
        }
    }

    function openModal(item) {
        setFee(item)
        setFeeModal(true)
    }


    return (
        <CarInformationItem title={t('cars.show.toDoList')} open={open} setOpen={setOpen} footer={todos.length == 0} print={print} changePrint={changePrint}>
            <Collapse in={todos.length == 0}>
                <Box>
                    <Typography gutterBottom paragraph className={classes.columnData}>{t('cars.show.toDoList.emptyExplanation')}</Typography>
                    <AddButton text={t('cars.show.toDoList.addNewTask')} onClick={e => addTodo(context, props.id, setTodos, setOpen)} />
                </Box>
            </Collapse>
            <Collapse in={!open && todos.length != 0}>
                <Box display="flex">
                    {todos.map((item, index) =>
                        index < 4 &&
                        <Box flexGrow={1}>
                            <Typography className={classes.columnTitle} style={{ marginBottom: '10px' }}># {index + 1}</Typography>

                            <Box display="flex" color="#39BB9D" marginBottom="10px">
                                <Typography className={classes.columnData}>{item.title}</Typography>
                                {item.state == "done" &&
                                    <DoneAllIcon style={{ marginLeft: "16px" }} />
                                }
                            </Box>
                            <Typography className={classes.columnTitle} style={{ fontWeight: '500', marginBottom: '10px' }}>{item.category}</Typography>
                            {item.priority === "Important" &&
                                <Box display="flex" alignItems="center" color="#EB5757">
                                    <ErrorOutlineIcon style={{ padding: "0px", marginRight: '6px' }} />
                                    <Typography style={{ fontWeight: '500' }}>{t("cars.show.todo.Important")}</Typography>
                                </Box>
                            }
                        </Box>
                    )}
                </Box>
            </Collapse>
            <Collapse in={open && todos.length != 0} style={{ width: '100%' }}>
                <BaseModal open={feeModal} setOpen={setFeeModal}>
                    <AddToFeesModal setOpen={setFeeModal} id={props.id} fee={fee} getCar={props.getCar}/>
                </BaseModal>
                <TableContainer>
                    <Table style={{ borderCollapse: 'separate' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headCell}></TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.toDoList.taskTitle')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.toDoList.feeType')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.toDoList.priority')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {todos.map((item, index) => (
                                <TableRow className={classes.row}>
                                    <TableCell width="52px" className={classes.bodyCell}>
                                        <Typography className={classes.columnTitle}>#{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        <CustomTextField key={item._id} id="carTodoTitle" onChange={event => changeField(event, "title", item._id)}
                                            defaultValue={item.title} placeholder={t('cars.show.toDoList.taskTitle')} />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA', minWidth:'150px' }}>
                                        <CustomAutocomplete disableClearable
                                            onChange={(event, newValue) => changeCategory(context, todos, setTodos, newValue, item._id, props.id, "category")}
                                            options={categoryOptions} value={{ name: item.category }} />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }} width="300px">
                                        {item.state == "done" ?
                                            <Box display="flex" alignItems="center">
                                                <Button
                                                    startIcon={<DoneAllIcon style={{ marginLeft: "8px" }} />}
                                                    className={classes.allDoneButton}>{t('cars.show.toDoList.done')}</Button>
                                                <AddButton text={t("cars.show.toDoList.addToFees")} style={{ flexGrow: 1 }} onClick={e => openModal(item)}/>
                                            </Box>
                                            :
                                            <Box display="flex" alignItems="center">
                                                <CustomAutocomplete style={{ flexGrow: 1, marginRight: '20px' }} disableClearable
                                                    onChange={(event, newValue) => changeCategory(context, todos, setTodos, newValue, item._id, props.id, "priority")}
                                                    options={priorityOptions} value={{ name: item.priority }} />
                                                <Button
                                                    onClick={event => updateTodoState(context, props.id, item._id, setTodos)}
                                                    startIcon={<DoneIcon style={{ marginLeft: "8px" }} />}
                                                    className={classes.doneButton}>{t('cars.show.toDoList.done')}</Button>
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell width="48px" className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        <Recyclerbin onClick={event => deleteTodo(context, props.id, item._id, setTodos, setOpen)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className={classes.bodyCell}></TableCell>
                                <TableCell style={{ padding: "0px", borderBottom: 'none' }}>
                                    <AddButton text={t('cars.show.toDoList.addNewTask')} onClick={e => addTodo(context, props.id, setTodos, setOpen)} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </CarInformationItem>
    )
}

const useStyles = makeStyles(theme => ({
    allDoneButton: {
        backgroundColor: 'none',
        border: "1px solid #39BB9D",
        borderRadius: '0px',
        fontSize: '16px',
        color: '#39BB9D',
        height: '48px',
        paddingRight: '16px',
        fontWeight: 700,
        marginRight: '20px'
    },
    doneButton: {
        backgroundColor: 'none',
        border: "1px solid #2A303B",
        borderRadius: '0px',
        fontSize: '16px',
        color: '#2A303B',
        height: '48px',
        paddingRight: '16px',
        fontWeight: 700
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    row: {
        backgroundColor: 'none',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '0px',
    },
}))

export default withTranslation()(ToDoList)