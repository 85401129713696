import { Box, Divider, Typography } from "@material-ui/core"
import React, { useState } from "react"
import ModalHeader from "../../../../components/madal/modal_header";
import AddEmployeeModalAccessLevel from "./access_level";
import AddEmployeeModalInformation from "./employee_information";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function AddEmployeeModal(props) {
    const [page, setPage] = useState(1)
    const [employee, setEmployee] = useState({})
    const classes = useStyles()
    const t = props.t 

    return (
        <Box className={classes.root}>
            <ModalHeader title={t('employee.modal.header')} grey setOpen={props.setOpen} />

            <Box paddingLeft="50px" marginBottom="10px" marginTop="16px">
                <Box >
                    <Typography variant="subtitle1" className={classes.title}>
                        {page == 1 ? t('employee.modal.informationTitle') :
                            page == 2 ? t('employee.modal.levelTitle') :
                                null}
                    </Typography>
                </Box>

                <Box marginTop="10px" >
                    <Typography variant="h6" style={{ color: '#778191' }}>
                        {page == 1 ?
                            t('employee.modal.informationExplanation') :
                            page == 2 ?
                                t('employee.modal.levelExplanation') : null}
                    </Typography>
                </Box>
            </Box>
            <Box padding={`0px 32px 0px 50px`}>
                <Divider />
            </Box>
            <Box>
                {
                    page == 1 ? <AddEmployeeModalInformation setPage={setPage} page={page} setOpen={props.setOpen} {...props.t} /> :
                        page == 2 ? <AddEmployeeModalAccessLevel setPage={setPage} page={page} setOpen={props.setOpen} {...props.t} getEmployees={props.getEmployees}/> :
                            null}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '1056px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        paddingBottom: '24px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    },
    title: {
        fontWeight: 700,
    },
    checkIcon: {
        marginRight: '12px',
        color: '#27AE60',
        fontSize: '32px'
    }
}))

export default withTranslation()(AddEmployeeModal)