import { Box, Button, IconButton, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import ModalHeader from "../../../components/madal/modal_header"
import { Context } from "../../../core/context"
import dataProvider from "../../../core/dataProvider"
import removeIcon from "../../../images/remove-orange.png"
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles'

function RemoveCustomerModal(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const history = useHistory()
    const t = props.t
    const handleClose = () => {
        props.setOpen(false);
    };

    async function deleteCustomer() {
        let data = await dataProvider.delete(context, "customer/one/" + props.id)
        handleClose()
        history.goBack()
    }

    return (
        <Box className={classes.root}>
            <ModalHeader title={t('customer.removeModal.removingTheCustomer')} setOpen={props.setOpen} />
            <Box padding="0px 37px 24px 50px">
                <Box marginTop="20px" marginBottom="40px">
                    <Typography gutterBottom variant="h6">{t('customer.removeModal.removeCarDatabase')}</Typography>
                    <Typography variant="h6">{t('cars.show.removeCarModal.areYouSure')}</Typography>
                </Box>
                <Typography variant="h6">
                    <span className={classes.removeTitle}>{t('cars.show.removeCar.alert')} ! &nbsp;</span>
                    <strong>{t('cars.show.removeCar.notReversible')}</strong> {" "}
                    {t('customer.removeModal.willRemoved')}
                    {t('customer.removeModal.removePermenently')}
                </Typography>
                <Box display='flex' flexDirection='row-reverse' marginTop="68px">
                    <Button
                        onClick={deleteCustomer}
                        className={classes.removeButton}
                        startIcon={<img src={removeIcon} width="16px" height="21px" style={{ marginLeft: "8px" }} />}>
                        {t('customer.removeModal.removeThisCustomer')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}>
                        {t('button.cancel')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '760px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        boxSizing: 'border-box'
    },
    bold: {
        fontWeight: 700,
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
    },
    removeButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        marginLeft: '16px'
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
}))

export default withTranslation()(RemoveCustomerModal)