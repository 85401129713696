import { Avatar, Box, Button, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useRef, useState } from "react"
import CustomTextField from "../../components/textfield/custom_textfield";
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import CustomerShowItem from "../../components/customer_show_item/customer_show_item";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function Contact(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t

    async function sendMessage() {
        context.setLoading(true)
        let param = {
            title: document.getElementById("contactTitle").value,
            email: document.getElementById("contactEmail").value,
            description: document.getElementById("contactMessage").value,
        }
        let data = await dataProvider.create(context, "form/one", {
            data: { ...param }
        })
        context.setLoading(false)
        if (data.status == 200)
            context.setError({ open: true, message: data.message, state: 'success' })
        else
            context.setError({ open: true, message: data.message, state: 'error' })
    }
    return (
        <CustomerShowItem title={t('contact.card.title')} contact>
            <Typography className={classes.columnTitle} noWrap
                style={{ marginLeft: "24px", marginBottom: '32px' }}>{t('contact.description')}</Typography>
            <Box padding='0px 37px 24px 50px'>
                <Box marginBottom="24px">
                    <Typography className={classes.columnTitle}>{t("contact.email")}</Typography>
                    <CustomTextField placeholder={t('employee.modal.emailplh')}
                        id="contactEmail" />
                </Box>
                <Box marginBottom="24px">
                    <Typography className={classes.columnTitle}>{t('contact.title')}</Typography>
                    <CustomTextField placeholder={t('contact.titleplh')} id="contactTitle" />
                </Box>
                <Box marginBottom="16px">
                    <Typography className={classes.columnTitle}>{t('contact.message')}</Typography>
                    <MyTextField
                        variant="outlined"
                        multiline
                        rows={4}
                        placeholder={t('contact.messageplh')}
                        id="contactMessage" />
                </Box>
                <Button color="primary" className={classes.sellButton} onClick={sendMessage} >
                    {t('contact.sendMessage')}
                </Button>
            </Box>
        </CustomerShowItem >
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    sellButton: {
        border: "1px solid #2F80ED",
        height: '48px',
        padding: '14px 20px',
        borderRadius: '0px',
        fontSize: '16px',
        fontWeight: 700
    }
}))

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                border: '1px solid #2A303B'
            },
            "& .Mui-error": {
                border: "1px solid #EB5757"
            },
            fontWeight: '500',
            // height: '142px',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)


export default withTranslation()(Contact)