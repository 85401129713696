import { Autocomplete, Box, Button, Collapse, Divider, IconButton, InputBase, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useRef, useState } from "react"
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import FinancialTable from "./table";
import { withTranslation } from 'react-i18next';
import ProgressBar from "../../components/backdrop/progress"
import { makeStyles, withStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { drawerWidth } from "../../components/drawer/drawer";
import AddButton from "../../components/buttons/add";
import DatePicker from "@material-ui/lab/DatePicker";
import moment from "moment";
import { getMonths } from "../../core/date";
import { useMemo } from "react";
import { useReactToPrint } from 'react-to-print';
import FinancePrintContent from "./print_content";

function Financial(props) {
    const printRef = useRef()
    const t = props.t
    const classes = useStyles();
    const context = useContext(Context)
    const months = useMemo(() => getMonths(), [])
    const trimonths = useMemo(() => [
        { name: months[0] + ' - ' + months[2] },
        { name: months[3] + ' - ' + months[5] },
        { name: months[6] + ' - ' + months[8] },
        { name: months[9] + ' - ' + months[11] },
    ], [])
    const [timeFrame, setTimeFrame] = useState({ name: t('financial.timeFrame.monthly'), value: 'monthly' });
    const [cars, setCars] = useState([])
    const [prices, setPrices] = useState({ total_buy_price: 0, total_sell_price: 0, total: 0 })
    const [sort, setSort] = useState({ name: "", order: -1 })
    const [loading, setLoading] = useState(true)
    const [year, setYear] = useState(moment());
    const [month, setMonth] = useState({ name: months[0] });
    const [trimonth, setTrimonth] = useState(trimonths[0]);

    const optionList = [
        { name: t('financial.timeFrame.monthly'), value: 'monthly' },
        { name: t('financial.timeFrame.trimonthly'), value: 'trimonthly' },
        { name: t('financial.timeFrame.yearly'), value: 'yearly' },
        { name: t('financial.timeFrame.overall'), value: 'overall' },
    ]

    useEffect(() => {
        if (Object.keys(sort).includes("title"))
            getcars()
    }, [sort])

    useEffect(() => {
        getcars()
    }, [])

    async function getcars() {
        setLoading(true)
        var date = calcDate()
        let data = await dataProvider.getList(context, "car/finance", { sort, date })
        if (data && data.data && data.data.car_list) {
            setCars(data.data.car_list)
            var sell = data.data.total_sell_price
            var buy = data.data.total_buy_price
            setPrices({ total_sell_price: sell, total_buy_price: buy, total: sell - buy })
        }
        else setCars([])
        setLoading(false)
    }

    function calcDate() {
        var date = { "car_list.sell.sold_on": { from: "", to: "" } }
        var from, to
        switch (timeFrame.value) {
            case "monthly":
                from = moment(year.year() + " " + month.name, "YYYY MMM")
                to = moment(year.year() + " " + month.name, "YYYY MMM").add(1, "month")
                date["car_list.sell.sold_on"].from = from
                date["car_list.sell.sold_on"].to = to
                break
            case "trimonthly":
                var index = trimonths.indexOf(trimonth)
                from = moment(year.year() + " " + (index*3+1), "YYYY MM")
                to = moment(year.year() + " " + (index*3+3), "YYYY MM")
                date["car_list.sell.sold_on"].from = from
                date["car_list.sell.sold_on"].to = to
                break
            case "yearly":
                from = moment(year.year(),"YYYY")
                to = moment(year.year(),"YYYY").add(1, "year")
                date["car_list.sell.sold_on"].from = from
                date["car_list.sell.sold_on"].to = to
                break
            case "overall":
                date = {}
                break
            default:
                break
        }
        return date
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Box flexGrow={1}>
            <FinancePrintContent cars={cars} prices={prices} ref={printRef} t={props.t} 
            // pageStyle={pageStyle}
             />
            <Box padding="30px 40px 20px 40px"
                boxShadow="0px 4px 20px rgba(182, 191, 207, 0.4)">
                <Box marginBottom="22px" display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.title}>{t('financial')}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Field
                        value={timeFrame}
                        label={t('financial.timeFrame')}
                        options={optionList}
                        style={{ width: '260px' }}
                        onChange={(e, v) => {
                            setTimeFrame(v)
                        }}
                    />
                    <Box display={timeFrame.value === "overall" ? "none" : "flex"} alignItems="center" flexGrow="1">
                        <Box width="128px" marginRight={'16px'}>
                            <Typography className={classes.columnTitle}>{t('cars.list.year')}</Typography>
                            <DatePicker
                                views={['year']}
                                value={year}
                                onChange={(newValue) => {
                                    setYear(newValue);
                                }}
                                renderInput={(params) => <MyTextField {...params} helperText={null} />}
                            />
                        </Box>
                        {timeFrame.value === "monthly" ?
                            <Field
                                label={t('financial.month')}
                                value={month}
                                options={months.map(item => { return { name: item } })}
                                style={{ flexGrow: 1, marginRight: '40px' }}
                                onChange={(e, v) => setMonth(v)}
                            />
                            : timeFrame.value === "trimonthly" ?
                                <Field
                                    label={t('financial.month\'s')}
                                    options={trimonths}
                                    value={trimonth}
                                    style={{ flexGrow: 1, marginRight: '40px' }}
                                    onChange={(e, v) => setTrimonth(v)}
                                /> : null}

                    </Box>
                    <Box marginLeft="auto" paddingTop="26px">
                        <Button color="primary" onClick={getcars} className={classes.sellButton}>{t('financial.getReport')}</Button>
                    </Box>

                </Box>
            </Box>



            <Box
                padding={`16px 24px ${loading ? '0px' : '260px'} 40px`}
                position="relative"
                minHeight="60vh"
            >
                {loading ? <ProgressBar /> :
                    <FinancialTable cars={cars} sort={sort} setSort={setSort} {...props.t} />
                }
            </Box>

            <Box boxShadow="0px -4px 20px rgba(182, 191, 207, 0.4)" alignItems="center"
                position="fixed" bottom={0} right={0} left={0} overflow="hidden" bgcolor="#F5F7FA" padding={`32px 24px 32px ${drawerWidth + 40}px`}
                display="flex">
                <Box border="1px solid #E4E7ED" bgcolor="#F1F3F6" padding="16px 32px 16px 20px" marginRight="12px" flexGrow="1">
                    <Typography className={classes.reportTitle}>{t('financial.purchased')}</Typography>
                    <Typography className={classes.reportContent} noWrap>{prices.total_buy_price} <Typography style={{ fontWeight: '5000', fontSize: '20px', marginLeft: '10px' }}>CHF</Typography></Typography>
                </Box>
                <Box border="1px solid #E4E7ED" bgcolor="#F1F3F6" padding="16px 32px 16px 20px" marginRight="12px" flexGrow="1">
                    <Typography className={classes.reportTitle}>{t('financial.sold')}</Typography>
                    <Typography className={classes.reportContent} noWrap>{prices.total_sell_price} <Typography style={{ fontWeight: '5000', fontSize: '20px', marginLeft: '10px' }}>CHF</Typography></Typography>
                </Box>
                <Box border="1px solid #E4E7ED" bgcolor="#F1F3F6" padding="16px 32px 16px 20px" marginRight="40px" flexGrow="1">
                    <Typography className={classes.reportTitle}>{t('financial.total')}</Typography>
                    <Typography className={classes.reportContent} noWrap>{prices.total} <Typography style={{ fontWeight: '5000', fontSize: '20px', marginLeft: '10px' }}>CHF</Typography></Typography>
                </Box>
                <Box marginLeft="auto">
                    <AddButton onClick={handlePrint} noIcon text={t("financial.printReport")} style={{ fontSize: '20px' }} />
                </Box>
            </Box>

        </Box>
    )
}

const pageStyle = `
  @page {
    size: 80mm 50mm;
    margin: 5mm 5mm 5mm 5mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }`

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 700
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    sellButton: {
        border: "1px solid #2F80ED",
        height: '56px',
        padding: '14px 20px',
        borderRadius: '0px',
        fontSize: '20px',
        fontWeight: 700,
        marginLeft: 'auto'
    },
    reportTitle: {
        color: '#778191',
        marginBottom: '10px',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 700
    },
    reportContent: {
        color: '#2A303B',
        marginBottom: '10px',
        fontSize: '24px',
        lineHeight: '29px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
    },

}));

function Field(props) {
    const classes = useStyles()
    return (
        <Box style={props.style} marginRight={'16px'}>
            <Typography className={classes.columnTitle}>{props.label}</Typography>
            <Autocomplete
                disableClearable
                value={props.value ? props.value : null}
                options={props.options}
                popupIcon={<ExpandMoreIcon color="disabled" />}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <MyTextField {...params} placeholder={props.label} variant="outlined" />}
                onChange={props.onChange}
            />
        </Box>
    )
}

const MyTextField = withStyles({
    root: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            fontWeight: '500',
            backgroundColor: 'white',
            borderRadius: '0px',
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            border: '1px solid #E4E7ED',
        },
    },

})(TextField)





export default withTranslation()(Financial)