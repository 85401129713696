import { Box, Collapse, TextField, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CarInformationItem from "../../../../components/car_list_card/car_information_item"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomTextField from "../../../../components/textfield/custom_textfield";
import Recyclerbin from "../../../../components/buttons/recyclrebin";
import AddButton from "../../../../components/buttons/add";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import { PrintContext } from "../print/print_context";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import {makeStyles} from '@material-ui/styles'

function ExtraWithCar(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [extras, setExtras] = useState([])
    const [print, setPrint] = useState(false)
    const printContext = useContext(PrintContext)
    const context = useContext(Context)
    const t = props.t
    const [query, setQuery] = useState({ event: "", field: "", id: '' })

    function changeField(event, field, id) {
        setQuery({ event: event, field: field, id: id })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.id, token), INPUT_TIMEOUT);
        return () =>  clearTimeout(timeOutId)
    }, [query])

    function changePrint() {
        if (printContext) {
            if (print) printContext.setContent({ ...printContext.content, extra_with_cars: "" })
            else printContext.setContent({ ...printContext.content, extra_with_cars: extras })
            setPrint(!print)
        }
    }

    async function getExtras() {
        let data = await dataProvider.getList(context, "car/extra/list/" + props.id)
        if (data && data.data && data.data.extra_list)
            setExtras(data.data.extra_list)
        else setExtras([])
    }

    async function deleteExtra(id) {
        let data = await dataProvider.delete(context, "car/extra/one/" + props.id + "/" + id)
        if (!data.data || !data.data.extra_list)
            setOpen(false)
        getExtras()
    }
    async function addNewExtra() {
        let params = {
            title: '',
        }
        let data = await dataProvider.create(context, "car/extra/one/" + props.id, { data: params })
        getExtras()
        setOpen(true)
    }
    async function req(event, id) {
        if (event.target) {
            let params = {
                title: event.target.value,
            }
            let data = await dataProvider.update(context, "car/extra/one/" + props.id + "/" + id, { data: params })
            getExtras()
        }
    }
    useEffect(() => {
        getExtras()
    }, [])
    return (
        <CarInformationItem title={t('cars.show.extraWithCar')} open={open} setOpen={setOpen} footer={extras.length == 0} print={print} changePrint={changePrint}>
            <Collapse in={extras.length == 0}>
                <Box>
                    <Typography gutterBottom paragraph className={classes.columnData}>{t('cars.show.extraWithCar.emptyExplanation')}</Typography>
                    <AddButton text={t('cars.show.extraWithCar.addNewExtra')} onClick={addNewExtra} />
                </Box>
            </Collapse>
            <Collapse in={!open && extras.length != 0}>
                <Box display="flex">
                    {extras.map((item, index) =>
                        index < 4 &&
                        <Box flexGrow={1}>
                            <Typography className={classes.columnTitle}># {index + 1}</Typography>
                            <Typography className={classes.columnData} >{item.title}</Typography>
                        </Box>
                    )}
                </Box>
            </Collapse>

            <Collapse in={open && extras.length != 0} style={{ width: '100%' }}>
                <TableContainer style={{ width: '60%' }}>
                    <Table style={{ borderCollapse: 'separate' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headCell}></TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.extraWithCar.title')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {extras.map((item, index) => (
                                <TableRow className={classes.row}>
                                    <TableCell width="52px" className={classes.bodyCell}>
                                        <Typography className={classes.columnTitle}>#{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        <CustomTextField key={item._id} defaultValue={item.title} placeholder={t('cars.show.extraWithCar.titleplh')}
                                            onChange={event => changeField(event, "title", item._id)} />
                                    </TableCell>
                                    <TableCell width="48px" className={classes.bodyCell} align="left">
                                        <Recyclerbin onClick={event => deleteExtra(item._id)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className={classes.bodyCell}></TableCell>
                                <TableCell style={{ padding: "0px", borderBottom: 'none' }}>
                                    <AddButton text={t('cars.show.extraWithCar.addNewExtra')} onClick={addNewExtra} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>

        </CarInformationItem>
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    row: {
        backgroundColor: 'none',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '0px',
    },
}))


export default withTranslation()(ExtraWithCar)