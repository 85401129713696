import React from "react"
import { Button } from "@material-ui/core";
import addIcon from "../../images/add.png"
import {makeStyles} from '@material-ui/styles'

function AddButton(props) {
    const classes = useStyles()
    return (
        <Button
            startIcon={!props.noIcon && <img src={addIcon} width="16px" height="16px" />}
            className={classes.addButton}
            {...props}>{props.text}</Button>
    )
}

const useStyles = makeStyles(theme => ({
    addButton: {
        border: "1px solid #2A303B",
        height: '48px',
        padding: '14px 20px',
        borderRadius: '0px',
        fontSize:'16px',
        fontWeight: 700,
        color:'#2A303B'
    },
}))

export default AddButton