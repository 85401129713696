import dataProvider from "../../../../../core/dataProvider"
import i18n from "i18next";

export async function getTodos(context, setTodos, carId) {
    let data = await dataProvider.getList(context, "car/todo/list/" + carId)
    if (data && data.data && data.data.todo_list) {
        var promise = new Promise((resolve, reject) => {
            data.data.todo_list.forEach((item, index, array) => {
                switch (item.category) {
                    case "General":
                        item.category = i18n.t('cars.show.fees.general')
                        break;
                    case "Appearence":
                        item.category = i18n.t('cars.show.fees.appearence')
                        break;
                    case "Mechanical":
                        item.category = i18n.t('cars.show.fees.mechanical')
                        break;
                    default:
                        break;
                }
                switch (item.priority) {
                    case "Important":
                        item.priority = i18n.t('cars.show.todo.Important')
                        break;
                    case "Normal":
                        item.priority = i18n.t('cars.show.todo.Normal')
                        break;
                    default:
                        break;
                }
                if (index === array.length - 1) resolve();
            })
        })
        promise.then(() => {
            setTodos(data.data.todo_list)
        })
    }
    else setTodos([])
}
export async function deleteTodo(context, carId, id, setTodos, setOpen) {
    let data = await dataProvider.delete(context, "car/todo/one/" + carId + "/" + id)
    if (!data.data || !data.data.todo_list)
        setOpen(false)
    await getTodos(context, setTodos, carId)
}
export async function addTodo(context, carId, setTodos, setOpen) {
    let data = await dataProvider.create(context, "car/todo/one/" + carId, {
        data: {
            state: 'doing',
            title: '',
            category: 'General',
            priority: 'Normal'
        }
    })
    await getTodos(context, setTodos, carId)
    setOpen(true)
}
export async function updateTodoState(context, carId, id, setTodos) {
    let data = await dataProvider.update(context, "car/todo/one/" + carId + "/" + id, {
        data: {
            state: 'done',
        }
    })
    getTodos(context, setTodos, carId)
}
export async function changeFieldTodo(context, event, field, carId, id, setTodos, cancelToken) {
    if (field) {
        let data = await dataProvider.update(context, "car/todo/one/" + carId + "/" + id, {
            data: {
                [field]: event.target.value,
            }, cancelToken
        })
        await getTodos(context, setTodos, carId)
    }
}

export async function changeCategory(context, todos, setTodos, newValue, id, carId, field) {
    let todoList = JSON.parse(JSON.stringify(todos))
    todoList.filter(item => item._id == id)[0][field] = newValue.name
    setTodos(todoList)
    let value
    if (field === "category")
        switch (newValue.name) {
            case i18n.t('cars.show.fees.general'):
                value = "General"
                break;
            case i18n.t('cars.show.fees.appearence'):
                value = "Appearence"
                break;
            case i18n.t('cars.show.fees.mechanical'):
                value = "Mechanical"
                break;
            default:
                break;
        }
    else
        switch (newValue.name) {
            case i18n.t('cars.show.todo.Important'):
                value = "Important"
                break;
            case i18n.t('cars.show.todo.Normal'):
                value = "Normal"
                break;
            default:
                break;
        }
    let data = await dataProvider.update(context, "car/todo/one/" + carId + "/" + id, {
        data: {
            [field]: value
        }
    })
    getTodos(context, setTodos, carId)
}
