import { Box, Divider, Typography } from '@material-ui/core'
import React, { forwardRef, useContext } from 'react'
import { getDate } from '../../../../core/date'
import { PrintContext } from './print_context'

const PrintContent = forwardRef((props, ref) => {
    const { content } = useContext(PrintContext)
    const t = props.t
    return (

        <div ref={ref} style={{ padding: '10px' }}>
            <Box display="none" displayPrint="block" padding="20px">
                {content.summary &&
                    <Box>
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.summary')}</Typography>
                        <RowData title={t('cars.show.summary.purchasedPrice')} data={content.summary.purchased + " CHF"} />
                        {(content.summary.fees && !content.summary.ignore.ignore_fee) ?
                            <>
                                <RowData title={t('cars.show.summary.totalFees')} data={content.summary.fees + " CHF"} />
                                <RowData title={t('cars.show.summary.totalCost')} data={content.summary.cost + " CHF"} />
                            </>
                            : null
                        }
                        <RowData title={t('cars.show.summary.soldPrice')} data={content.summary.sold ? content.summary.sold + " CHF" : `(${t('cars.show.summary.notSold')})`} />
                        <RowData title={t('cars.show.summary.margin')} data={content.summary.margin ? content.summary.margin + " CHF" : "-"} />
                        {!content.summary.ignore.ignore_vta &&
                            <RowData title={t('cars.show.summary.tva')} data={content.summary.tva ? content.summary.tva + " CHF" : "-"} />
                        }
                    </Box>
                }
                {content.car_information &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.carInformation')}</Typography>
                        <RowData title={t('cars.show.carInformation.brand&model')} data={content.car_information["brand_type"]} />
                        <RowData title={t('cars.show.carInformation.regNumber')} data={content.car_information["matricule"]} />
                        <RowData title={t('cars.show.carInformation.regDate')} data={getDate(content.car_information["reg_date"]).monthNamed} />
                        <RowData title={t('cars.show.carInformation.kilomtrage')} data={content.car_information["kilomtrage"]} />
                        <RowData title={t('cars.show.carInformation.approval')} data={content.car_information["approval"]} />
                        <RowData title={t('cars.show.carInformation.body')} data={content.car_information["body"]} />
                        <RowData title={t('cars.list.color')} data={content.car_information["color"]} />
                        <RowData title={t('cars.show.carInformation.cylinders')} data={content.car_information["cylinder"]} />
                        <RowData title={t('cars.show.carInformation.cylindreeCm3')} data={content.car_information["cylindree_cm3"]} />
                        <RowData title={t('cars.show.carInformation.power')} data={content.car_information["power"]} />
                        <RowData title={t('cars.show.carInformation.emmissionCode')} data={content.car_information["emission_code"]} />
                        <RowData title={t('cars.show.carInformation.expertiseDate')} data={getDate(content.car_information["expertise_date"]).monthNamed} />
                        <RowData title={t('cars.show.carInformation.expertiseCanton')} data={content.car_information["expertise_canton"]} />
                        <RowData title={t('cars.show.carInformation.status')} data={content.car_information["status"]} />
                    </Box>
                }
                {(content.purchase_detail && content.purchase_detail.customer) &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.purchaseDetail')}</Typography>
                        <RowData title={t('cars.show.purchaseDetail.provider')} data={content.purchase_detail.customer["name"]} />
                        <RowData title={t('cars.show.purchaseDetail.phone')} data={content.purchase_detail.customer["phone_number"]} />
                        <RowData title={t('cars.show.purchaseDetail.purchasedOn')} data={getDate(content.purchase_detail["purchased_on"]).monthNamed} />
                        <RowData title={t('cars.show.purchaseDetail.price')} data={content.purchase_detail["price"]} />
                    </Box>
                }
                {(content.fees &&
                    (content.fees.General ||
                        content.fees.Appearence ||
                        content.fees.Mechanical)) &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.fees')}</Typography>
                        <RowData title={t('cars.show.fees.generalFees')} data={content.fees.prices["sum_general"]} />
                        <RowData title={t('cars.show.fees.appearenceFees')} data={content.fees.prices["sum_appearence"]} />
                        <RowData title={t('cars.show.fees.mechanicalFees')} data={content.fees.prices["sum_mechanical"]} />
                        <RowData title={t('cars.show.fees.totalFees')} data={content.fees.prices["sum_all"]} />
                        {(content.fees && content.fees.General) &&
                            <Box marginTop="10px" paddingLeft="10px">
                                <Typography variant="subtitle2" style={{ fontWeight: 500 }} gutterBottom>{t('cars.show.fees.general')}</Typography>
                                {content.fees.General.map(item =>
                                    <Box marginBottom="10px" display="flex">
                                        <RowData title={t('cars.show.fees.feeTitle')} data={item["title"]} />
                                        <RowData title={t('cars.show.fees.type')} data={item["category"]} />
                                        <RowData title={t('cars.show.fees.cost')} data={item["price"]} />
                                    </Box>
                                )}
                            </Box>
                        }
                        {(content.fees && content.fees.Appearence) &&
                            <Box marginTop="10px" paddingLeft="10px">
                                <Typography variant="subtitle2" style={{ fontWeight: 500 }} gutterBottom>{t('cars.show.fees.appearence')}</Typography>
                                {content.fees.Appearence.map(item =>
                                    <Box marginBottom="10px" display="flex">
                                        <RowData title={t('cars.show.fees.feeTitle')} data={item["title"]} />
                                        <RowData title={t('cars.show.fees.type')} data={item["category"]} />
                                        <RowData title={t('cars.show.fees.cost')} data={item["price"]} />
                                    </Box>
                                )}
                            </Box>
                        }
                        {(content.fees && content.fees.Mechanical) &&
                            <Box marginTop="10px" paddingLeft="10px">
                                <Typography variant="subtitle2" style={{ fontWeight: 500 }} gutterBottom>{t('cars.show.fees.mechanical')}</Typography>
                                {content.fees.Mechanical.map(item =>
                                    <Box marginBottom="10px" display="flex">
                                        <RowData title={t('cars.show.fees.feeTitle')} data={item["title"]} />
                                        <RowData title={t('cars.show.fees.type')} data={item["category"]} />
                                        <RowData title={t('cars.show.fees.cost')} data={item["price"]} />
                                    </Box>
                                )}
                            </Box>
                        }
                    </Box>
                }
                {content.to_do &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.toDoList')}</Typography>
                        {content.to_do.map(item =>
                            <Box display="flex">
                                <RowData title={t('cars.show.toDoList.taskTitle')} data={item["title"]} />
                                <RowData title={t('cars.show.toDoList.estimatedCost')} data={item["price"]} />
                            </Box>
                        )}
                    </Box>
                }
                {(content.sell_detail && content.sell_detail.customer && content.sell_detail.sell) &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.sellDetail')}</Typography>
                        <RowData title={t('cars.show.sellDetail.customer')} data={content.sell_detail.customer["name"]} />
                        <RowData title={t('cars.show.purchaseDetail.phone')} data={content.sell_detail.customer["phone_number"]} />
                        <RowData title={t('cars.show.purchaseDetail.date')} data={getDate(content.sell_detail["sold_on"]).monthNamed} />
                        <RowData title={t('cars.show.sellDetail.soldPrice')} data={content.sell_detail["price"]} />
                        <RowData title={t('cars.show.sellDetail.exported')}
                            data={content.sell_detail.sell["exported"] ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')} />
                        <RowData title={t('cars.show.sellDetail.guarantee')}
                            data={content.sell_detail.sell["gurantee"] ? t('cars.show.sellDetail.yes') : t('cars.show.sellDetail.no')} />
                    </Box>
                }
                {content.extra_with_cars &&
                    <Box>
                        <Divider style={{ margin: "10px 0px" }} />
                        <Typography variant="subtitle1" style={{ fontWeight: 700 }} paragraph>{t('cars.show.extraWithCar')}</Typography>
                        {content.extra_with_cars.map(item =>
                            <Box display="flex">
                                <RowData title={t('cars.show.extraWithCar.title')} data={item["title"]} />
                            </Box>
                        )}
                    </Box>
                }
            </Box>

        </div>
    )
})

function RowData(props) {
    return (
        <Box display="flex" alignItems="center" paddingRight="10px">
            <Typography varaint="subtitle2" style={{ fontWeight: 700, marginRight: '20px', pageBreakInside: 'avoid' }}>{props.title}</Typography>
            <Typography style={{ pageBreakInside: 'avoid' }}>{props.data}</Typography>
        </Box>
    )
}

export default PrintContent