import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, Button, Collapse, IconButton, TextField, Typography } from "@material-ui/core"
import CarInformationItem from "../../../../../components/car_list_card/car_information_item"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomTextField from "../../../../../components/textfield/custom_textfield";
import Recyclerbin from "../../../../../components/buttons/recyclrebin";
import AddButton from "../../../../../components/buttons/add";
import { deleteFile, getFiles, uploadNewFile } from "./files_api";
import { Context } from "../../../../../core/context";
import LinearProgress from '@material-ui/core/LinearProgress';
import ClearIcon from '@material-ui/icons/Clear';
import axios from "axios";
import dataProvider from "../../../../../core/dataProvider";
import { withTranslation } from 'react-i18next';
import { INPUT_TIMEOUT } from "../../../../../core/APP_URL";
import {makeStyles} from '@material-ui/styles'
import { withStyles } from "@material-ui/styles";

function Files(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([])
    const [selectedFile, setSelectedFile] = useState({})
    const [progress, setProgress] = useState(0)
    const [query, setQuery] = useState({ event: "", field: "", id: '' })
    const { cancel, token } = axios.CancelToken.source();
    const t = props.t
    const context = useContext(Context)

    function changeField(event, field, id) {
        setQuery({ event: event, field: field, id: id })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.id, token), INPUT_TIMEOUT);
        return () =>  clearTimeout(timeOutId)
    }, [query])
    useEffect(() => {
        getFiles(context, props.id, setFiles)
    }, [])

    useEffect(() => {
        if (selectedFile && selectedFile.name)
            setFiles([...files, { title: selectedFile.name, uploading: true }])
    }, [selectedFile])

    async function req(event, id, cancelToken) {
        if (event.target) {
            let params = {
                "description": event.target.value
            }
            let data = await dataProvider.update(context, "car/file/one/" + props.id + "/" + id, {
                data: params,
                cancelToken
            })
            getFiles(context, props.id, setFiles)
        }
    }

    return (
        <CarInformationItem title={t('cars.show.files')} open={open} setOpen={setOpen} footer={files.length == 0} checkbox>
            <Collapse in={files.length == 0}>
                <Box>
                    <Typography gutterBottom paragraph className={classes.columnData}>{t('cars.show.files.emptyExplanation')}</Typography>
                    <UploadButton setProgress={setProgress} token={token}
                        file={selectedFile} setFiles={setFiles}
                        setFile={setSelectedFile} id={props.id} setOpen={setOpen} {...props} />
                </Box>
            </Collapse>
            <Collapse in={!open && files.length != 0}>
                <Box display="flex">
                    {files.map((item, index) =>
                        index < 2 &&
                        <Box flexGrow={1}>
                            <Typography className={classes.columnTitle}>#{index + 1}</Typography>
                            <Typography className={classes.columnData}>{item.title}</Typography>
                            <Typography className={classes.columnTitle} style={{ fontWeight: '500' }}>{item.description}</Typography>
                        </Box>
                    )}
                </Box>
            </Collapse>
            <Collapse in={open && files.length != 0} style={{ width: '100%' }}>

                <TableContainer>
                    <Table style={{ borderCollapse: 'separate' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.headCell}></TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.files.fileName')}</Typography>
                                </TableCell>
                                <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                    <Typography className={classes.columnTitle}>{t('cars.show.files.description')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((item, index) => (
                                <TableRow className={classes.row}>
                                    <TableCell width="52px" className={classes.bodyCell}>
                                        <Typography className={classes.columnTitle}>#{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell style={{ borderRight: '20px solid #F5F7FA' }} className={classes.bodyCell} width="40%">
                                        <Box border="1px solid #E4E7ED" padding="14px 27px 14px 16px">
                                            <Typography className={classes.columnData} noWrap>{item.title}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        {item.uploading ?
                                            <CustomProgress value={progress} cancel={cancel} {...props} />
                                            :
                                            <CustomTextField key={item._id} defaultValue={item.description} placeholder={t('cars.show.files.descriptionplh')} onChange={e => changeField(e, "description", item._id)} />
                                        }
                                    </TableCell>
                                    <TableCell width="86px" className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        {!item.uploading &&
                                            <AddButton text={t('cars.show.files.open')} noIcon onClick={e => window.open(`${"http://apigarage.crossproduct.ch" + item.link}`, "_blank")} />
                                        }
                                    </TableCell>
                                    <TableCell width="48px" className={classes.bodyCell} align="left" style={{ borderRight: '20px solid #F5F7FA' }}>
                                        {!item.uploading &&
                                            <Recyclerbin onClick={event => deleteFile(context, props.id, item._id, setFiles, setOpen)} />
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className={classes.bodyCell}></TableCell>
                                <TableCell style={{ padding: "0px", borderBottom: 'none' }}>
                                    <UploadButton setProgress={setProgress} token={token}
                                        file={selectedFile} setFiles={setFiles}
                                        setFile={setSelectedFile} id={props.id} setOpen={setOpen} {...props} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </CarInformationItem>
    )
}

function UploadButton(props) {
    const hiddenFileInput = useRef(null);
    const context = useContext(Context)
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const t = props.t

    async function changeFile(event) {
        props.setFile(event.target.files[0])
        await uploadNewFile(context, props.id, event.target.files[0],
            props.setFiles, props.setFile, props.setOpen, props.setProgress, props.token)
        hiddenFileInput.current.value = ""
    }
    return (
        <Box>
            <input accept="image/jpeg,image/png,application/pdf" type="file" ref={hiddenFileInput}
                onChange={changeFile}
                style={{ display: 'none' }} />
            <AddButton text={t('cars.show.files.uploadNewFile')} onClick={handleClick} />
        </Box>
    )
}
function CustomProgress(props) {
    const classes = useStyles()
    const t = props.t
    function cancelReq() {
        props.cancel("No longer latest req")
    }
    return (
        <Box height="48px" position="relative">
            <CustomLinearProgressBar variant="determinate" value={props.value} />
            <Box position="absolute" display="flex" alignItems="center" top="0px" left="0px" width="100%" height="100%" padding="14px 16px 14px 16px" boxSizing="border-box">
                <Typography className={classes.bold}>{parseInt(props.value)}% &nbsp;</Typography>
                <Typography className={classes.medium}>{t('cars.show.files.uploaded')}</Typography>
                <IconButton style={{ marginLeft: 'auto', padding: '0px' }} onClick={cancelReq}>
                    <ClearIcon style={{ color: "#B6BFCF" }}  />
                </IconButton>
            </Box>
        </Box>
    )
}



const CustomLinearProgressBar = withStyles((theme) => ({
    root: {
        height: '48px',
        borderRadius: 0,
        border: '1px solid #E4E7ED'
    },
    colorPrimary: {
        background: 'none'
    },
    bar: {
        borderRadius: 0,
        background: '#6FCF97',
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    bold: {
        fontWeight: 700,
    },
    medium: {
        fontWeight: 500,
    },
    row: {
        backgroundColor: 'none',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '0px',
    },
}))

export default withTranslation()(Files)