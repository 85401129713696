import { Box, Button, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { Context } from "../../core/context"
import dataProvider from "../../core/dataProvider"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ModalHeader from "../../components/madal/modal_header"
import { makeStyles } from '@material-ui/styles'

function LogoutModal(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const history = useHistory()
    const handleClose = () => {
        props.setOpen(false);
    };
    const t = props.t

    async function logout() {
        context.setLoading(true)
        let data = await dataProvider.delete(context, "auth/logout")
        if (data && (data.status === 200 || data.status === 401)) {
            let lang = localStorage.getItem("lang")
            sessionStorage.clear()
            localStorage.clear()
            localStorage.setItem("lang", lang)
            handleClose()
            history.push("/login")
        }
        context.setLoading(false)
    }
    return (
        <Box className={classes.root}>
            <ModalHeader title={t('profile.logout')} setOpen={props.setOpen} />
            <Box padding="0px 37px 24px 50px">
                <Box marginTop="20px" marginBottom="40px">
                    <Typography gutterBottom variant="h6">{t('profile.logout.explanation')}</Typography>
                </Box>
                <Box display='flex' flexDirection='row-reverse'>
                    <Button
                        onClick={logout}
                        className={classes.logoutButton}
                        startIcon={<ExitToAppIcon style={{ marginLeft: "8px", fontSize: '30px' }} />}>
                        {t('profile.logout')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}>
                        {t('button.cancel')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '760px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        boxSizing: 'border-box'
    },
    bold: {
        fontWeight: 700,
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
    },
    logoutButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        marginLeft: '16px'
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
}))

export default LogoutModal