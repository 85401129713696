import { Box, Button, Typography } from "@material-ui/core"
import React, { useCallback, useContext, useEffect, useState } from "react"
import arrowIcon from "../../../images/arrow-right.png"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import TableSort from "../../../components/buttons/tab_sort";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function CustomerListTable(props) {
    const classes = useStyles()
    const history = useHistory()
    const t = props.t
    function handleOnClick(event, id) {
        history.push('/customers/' + id)
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>
                                <TableSort name="customer_list.name" title={t('customer.name&location')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="customer_list.phone_number" title={t('customer.phoneNumber&email')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                            <TableCell className={classes.headCell} style={{ paddingLeft: "0px" }} align="left">
                                <TableSort name="customer_list.last_car" title={t('cars.show.carInformation.brand&model')} sort={props.sort} setSort={props.setSort} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.customers.map((item, index) => (
                            <TableRow className={classes.row} >
                                <TableCell className={classes.bodyCell}>
                                    <Box>
                                        <Typography className={classes.title} gutterBottom>{item.name}</Typography>
                                        <Typography style={{ color: "#B6BFCF" }} className={classes.medium}>{item.location}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.bodyCell}>
                                    <Box>
                                        <Typography className={classes.title} gutterBottom>{item.phone_number}</Typography>
                                        <Typography style={{ color: "#434B59", fontWeight: '500' }}  >{item.email}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.bodyCell}>
                                    {(item.last_car) &&
                                        <Box>
                                            <Typography className={classes.medium} gutterBottom>{item.last_car}</Typography>
                                            <Typography className={classes.medium}>{ }</Typography>
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell className={classes.bodyCell} align="left" width="32px">
                                    <img src={arrowIcon} width="16px" height="16px" style={{ backgroundColor: 'black', padding: '8px', cursor: "pointer" }}
                                        onClick={event => handleOnClick(event, item._id)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 700
    },
    medium: {
        fontWeight: 500,
        color: '#B6BFCF'
    },
    text: {
        color: "#434B59"
    },
    arrowIcon: {
        color: 'white',
        width: '24px',
        height: '24px',
    },
    row: {
        backgroundColor: 'white',
    },
    headCell: {
        border: 'none',
        paddingBottom: '8px',
    },
    bodyCell: {
        borderBottom: '1rem solid #F5F7FA',
        padding: '16px 20px'
    }
}))

export default withTranslation()(CustomerListTable)