import { styled } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles'
import React from "react"
import Switch from '@material-ui/core/Switch'

function AntSwitch(props) {
    return (
        <CustomSwitch color="primary" {...props} />
    )
}
const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(36);
const height = pxToRem(20);
const size = pxToRem(10);
const gap = (20 - 10) / 2;
const CustomSwitch = withStyles((theme) => ({
    root: {
        width,
        height,
        padding: 0,
        // margin: theme.spacing(1),
        margin: "16px",
        overflow: 'unset',
    },
    switchBase: {
        padding: pxToRem(gap),
        '&$checked': {
            color: '#fff',
            transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
            '& + $track': {
                // backgroundColor: theme.palette.primary.main,
                backgroundColor: "#2F80ED",
                opacity: 1,
                border: 'none',
            },
            '& $thumb': {
                backgroundColor: '#fff',
            },
        },
    },
    track: {
        borderRadius: 40,
        border: `2px solid #778191`,
        borderWidth,
        background: 'none',
        opacity: 1,
        // transition: theme.transitions.create(['background-color', 'border']),
        boxSizing: 'border-box',
    },
    thumb: {
        boxShadow: 'none',
        backgroundColor: '#778191',
        width: size,
        height: size,
    },
    checked: {},
}))(Switch);

export default AntSwitch