import { Box, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import CalendarTextField from "../../../../components/textfield/calender_picker";
import { getDate } from "../../../../core/date";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import CustomerShowItem from "../../../../components/customer_show_item/customer_show_item";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import {makeStyles} from '@material-ui/styles'

function CustomerInformation(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const [kind, setKind] = useState(props.customer.type)
    const [query, setQuery] = useState({ event: "", field: "" })
    const t = props.t
    const changeKind = (event) => {
        setKind(event.target.value);
        let data = dataProvider.update(context, "customer/one/" + props.customer._id, {
            data: {
                'type': event.target.value
            }
        })
    };
    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () =>  clearTimeout(timeOutId)
    }, [query])

    async function req(event, field, cancelToken) {
        if (field) {
            let data = dataProvider.update(context, "customer/one/" + props.customer._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
        }
    }
    return (
        <CustomerShowItem title={t('customer.customerInformation')}>
            <Box padding='0px 37px 24px 50px'>
                <Box display='flex' marginBottom="24px">
                    <Box width="33.33%" marginRight="40px">
                        <Typography className={classes.columnTitle}>{t('customerModal.name')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "name")} defaultValue={props.customer.name} placeholder="Your Name..." />
                    </Box>

                    <Box width="66.66%" display="flex" flexDirection="column" justifyContent="space-between" marginRight="40px">
                        <Typography className={classes.columnTitle}>{t('customerModal.kind')}</Typography>
                        <Box display='flex' flexDirection="column" justifyContent="space-between" marginRight="68px">
                            <RadioGroup value={kind} onChange={changeKind} className={classes.radioGroup}>
                                <FormControlLabel value="client" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: kind == "client" ? "700" : "500" }}>{t('customerModal.customer')}</Typography>} />
                                <FormControlLabel value="provider" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: kind == "provider" ? "700" : "500" }}>{t('customerModal.provider')}</Typography>} />
                            </RadioGroup>
                        </Box>

                    </Box>

                </Box>

                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.streetNo')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "street_no")} defaultValue={props.customer.street_no} placeholder="Your Street No. ..." />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.postcode')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "postcode")} defaultValue={props.customer.postcode} placeholder="Your Postcode..." />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.town')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "town")} defaultValue={props.customer.town} placeholder="Your Town..." />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.phoneNumber')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "phone_number")} defaultValue={props.customer.phone_number} placeholder="Your Phone Number..." />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.email')}</Typography>
                        <CustomTextField onChange={event => changeField(event, "email")} defaultValue={props.customer.email} placeholder="Your Email..." />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.dateOfBirth')}</Typography>
                        <CalendarTextField date={props.customer.birthday} 
                        placeholder={t('cars.show.purchaseDetail.dateOfBirth')}
                        onDateChange={event => changeField(event, "birthday")} />
                    </Box>
                </Box>
            </Box>
        </CustomerShowItem >
    )
}

const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    columnData: {
        color: '#2A303B',
        fontWeight: 500
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
}))


export default withTranslation()(CustomerInformation)