import { Box, Button, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckIcon from '@material-ui/icons/Check';
import ModalHeader from "../madal/modal_header";
import CustomTextField from "../textfield/custom_textfield";
import CalendarTextField from "../textfield/calender_picker";
import { getDate } from "../../core/date";
import dataProvider from "../../core/dataProvider";
import { Context } from "../../core/context";
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function CustomerModal(props) {
    const [kind, setKind] = useState(props.customer ? props.customer.type : "client")
    const [birthDay, setBirthday] = useState("")
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    const roles = JSON.parse(sessionStorage.getItem("roles"))
    const handleClose = () => {
        props.setOpen(false);
    };
    const changeKind = (event) => {
        setKind(event.target.value);
    }

    async function finishAddEdit() {
        let data
        let params = {
            name: document.getElementById("customerModalName").value,
            street_no: document.getElementById("customerModalStreetNo").value,
            postcode: document.getElementById("customerModalPostcode").value,
            town: document.getElementById("customerModalTown").value,
            email: document.getElementById("customerModalEmail").value,
            phone_number: document.getElementById("customerModalPhoneNumber").value,
            birthday: birthDay,
            type: kind
        }
        if (props.edit)
            params._id = props.customer._id
        if (props.edit && props.setCustomer && roles && roles.includes(1)) {
            data = await dataProvider.update(context, "customer/one/" + props.customer._id, { data: params })
            if (data && data.data)
                props.setCustomer(data.data)
            else props.setCustomer(params)
        }
        else {
            data = await dataProvider.create(context, "customer/one", { data: params })
            if (data && data.data) {
                await props.getCustomers()
                if (props.setCustomer && roles && roles.includes(2))
                    props.setCustomer(data.data)
            }
        }
        props.setOpen(false)
    }

    return (
        <Box className={classes.root}>
            <ModalHeader title={props.edit ? t('customerModal.editCustomer') : t('customerModal.addCustomer')} setOpen={props.setOpen} />
            <Box padding='0px 37px 24px 50px'>
                <Box marginTop="8px" marginBottom="32px">
                    <Typography variant="h6" style={{ color: '#778191' }}>
                        {props.edit ?
                            t('customerModal.editExplanation')
                            :
                            t('customerModal.addExplanation')}
                    </Typography>
                </Box>

                <Box display='flex' marginBottom="24px">
                    <Box width="33.33%" marginRight="40px">
                        <Typography className={classes.columnTitle}>{t('customerModal.name')}</Typography>
                        <CustomTextField id="customerModalName" defaultValue={props.customer ? props.customer.name : ''} placeholder={t('employee.modal.nameplh')} />
                    </Box>

                    <Box width="66.66%" display="flex" flexDirection="column" justifyContent="space-between" marginRight="40px">
                        <Typography className={classes.columnTitle}>{t('customerModal.kind')}</Typography>
                        <Box display='flex' flexDirection="column" justifyContent="space-between" marginRight="68px">
                            <RadioGroup value={kind} onChange={changeKind} className={classes.radioGroup}>
                                <FormControlLabel value="client" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: kind == "client" ? "700" : "500" }}>{t('customerModal.customer')}</Typography>} />
                                <FormControlLabel value="provider" control={<Radio color="primary" />}
                                    label={<Typography style={{ fontWeight: kind == "provider" ? "700" : "500" }}>{t('customerModal.provider')}</Typography>} />
                            </RadioGroup>
                        </Box>

                    </Box>

                </Box>

                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.streetNo')}</Typography>
                        <CustomTextField id="customerModalStreetNo" defaultValue={props.customer ? props.customer.street_no : null} placeholder={t('customerModal.addressplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.postcode')}</Typography>
                        <CustomTextField id="customerModalPostcode" defaultValue={props.customer ? props.customer.postcode : null} placeholder={t('customerModal.postcodeplh')} />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.town')}</Typography>
                        <CustomTextField id="customerModalTown" defaultValue={props.customer ? props.customer.town : null} placeholder={t('customerModal.townplh')}/>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" marginBottom="60px">
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.phoneNumber')}</Typography>
                        <CustomTextField id="customerModalPhoneNumber" defaultValue={props.customer ? props.customer.phone_number : null} placeholder={t('employee.modal.phoneplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.email')}</Typography>
                        <CustomTextField id="customerModalEmail" defaultValue={props.customer ? props.customer.email : null} placeholder={t('employee.modal.emailplh')} />
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.purchaseDetail.dateOfBirth')}</Typography>
                        <CalendarTextField placeholder={t('cars.show.purchaseDetail.dateOfBirthplh')}
                            onDateChange={e => setBirthday(e.target.value)} date={props.customer ? props.customer.birthday : null} />
                    </Box>
                </Box>

                <Box display='flex' flexDirection='row-reverse'>
                    <Button
                        onClick={finishAddEdit}
                        className={classes.addCustomerButton}
                        startIcon={<CheckIcon style={{ marginLeft: "8px", fontSize: "26px" }} />}>
                        {
                            props.edit ?
                                t('customerModal.applyEdits')
                                :
                                t('customerModal.addToCustomers')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}>
                        {t('button.cancel')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '1046px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        boxSizing: 'border-box'
    },
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    bold: {
        fontWeight: 700,
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    addCustomerButton: {
        background: '#2A303B',
        borderRadius: '0px',
        color: 'white',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        marginLeft: '16px',
        '&:hover': {
            background: "#2A303B"
        }
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
}))

export default withTranslation()(CustomerModal)