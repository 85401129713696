import { Box, Button, Typography } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import CustomTextField from "../../../../components/textfield/custom_textfield";
import AddCarModalFooter from "./footer";
import CalendarTextField from "../../../../components/textfield/calender_picker";
import { withTranslation } from 'react-i18next';
import { getDate } from "../../../../core/date";
import dataProvider from "../../../../core/dataProvider";
import { Context } from "../../../../core/context";
import axios from "axios";
import { INPUT_TIMEOUT } from "../../../../core/APP_URL";
import ErrorPopup from "../../../../components/error_popup/error_popup";
import {makeStyles} from '@material-ui/styles'

function AddCarModalFirstPage(props) {
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    const [query, setQuery] = useState({ event: "", field: "" })
    const [errorTip, setErrorTip] = useState(false);
    function changeField(event, field) {
        setQuery({ event: event, field: field })
    }
    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => req(query.event, query.field, token), INPUT_TIMEOUT);
        return () => clearTimeout(timeOutId)
    }, [query])
    function req(event, field, cancelToken) {
        let data
        if (event && event.target) {
            data = dataProvider.update(context, "car/one/" + props.car._id, {
                data: {
                    [field]: event.target.value
                },
                cancelToken
            })
            props.setCar({ ...props.car, [field]: event.target.value })
            if (field === "matricule" && data && data.status) {
                setErrorTip(data.status !== 200)
            }
        }
    }

    async function updateCar() {
        let data = await dataProvider.update(context, "car/one/" + props.car._id, { data: { ...props.car } })
        if (data?.status != 200) {
            props.setPage(1)
        } else props.setPage(2)
    }


    return (
        <Box padding="28px 32px 0px 50px">
            <Box marginBottom="24px">
                <Typography className={classes.columnTitle}>{t('cars.show.carInformation.brand&model')}</Typography>
                <CustomTextField placeholder={t('cars.show.carInformation.brandNameplh')}
                    defaultValue={props.car.brand_type} onChange={event => changeField(event, "brand_type")} />
            </Box>

            <Box display="flex" flexDirection="column">

                <RowData>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.carInformation.chassis')}</Typography>
                        <CustomTextField defaultValue={props.car.chassis}
                            onChange={event => changeField(event, "chassis")}
                            placeholder={t('cars.show.carInformation.chassisplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t("cars.show.carInformation.regNumber")}</Typography>
                        <ErrorPopup title={t('cars.show.carInformation.matriculeError')} open={errorTip}>
                            <CustomTextField defaultValue={props.car.matricule} onChange={event => changeField(event, "matricule")}
                                placeholder={t("cars.show.carInformation.regNumberplh")} />
                        </ErrorPopup>
                    </Box>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.carInformation.regDate')}</Typography>
                        <CalendarTextField date={props.car.reg_date} onDateChange={event => changeField(event, "reg_date")}
                            placeholder={t('cars.show.carInformation.regDateplh')} />
                    </Box>
                </RowData>
                <RowData>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.carInformation.kilomtrage')}</Typography>
                        <CustomTextField defaultValue={props.car.kilomtrage} onChange={event => changeField(event, "kilomtrage")}
                            placeholder={t('cars.show.carInformation.kilomtrageplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.show.carInformation.approval')}</Typography>
                        <CustomTextField defaultValue={props.car.approval} onChange={event => changeField(event, "approval")}
                            placeholder={t('cars.show.carInformation.approvalplh')} />
                    </Box>
                    <Box width="33.33%"></Box>
                </RowData>
                <RowData>
                    <Box width="33.33%">
                        <Typography className={classes.columnTitle}>{t('cars.show.carInformation.body')}</Typography>
                        <CustomTextField defaultValue={props.car.body} onChange={event => changeField(event, "body")}
                            placeholder={t('cars.show.carInformation.bodyplh')} />
                    </Box>
                    <Box width="33.33%" margin="0px 40px">
                        <Typography className={classes.columnTitle}>{t('cars.list.color')}</Typography>
                        <CustomTextField defaultValue={props.car.color} onChange={event => changeField(event, "color")}
                            placeholder={t('cars.show.carInformation.colorplh')} />
                    </Box>
                    <Box width="33.33%"></Box>
                </RowData>
            </Box>

            <AddCarModalFooter page={props.page} disableDefault nextOnClick={updateCar} setPage={props.setPage} {...props} />

        </Box>
    )
}


function RowData(props) {
    return (
        <Box display="flex" alignItems="center" marginBottom="24px">
            {props.children}
        </Box>
    )
}
const useStyles = makeStyles(theme => ({
    columnTitle: {
        color: '#778191',
        fontWeight: 700,
        marginBottom: '5px'
    },
    bold: {
        fontWeight: 700,
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },


}))

export default withTranslation()(AddCarModalFirstPage)
