import { Box, Radio, Typography } from "@material-ui/core"
import React, { useContext, useState } from "react"
import AddCarModalFooter from "./footer"
import scanImage from "../../../../images/big_scanner.png"
import typingImage from "../../../../images/typing.png"
import dataProvider from "../../../../core/dataProvider"
import { Context } from "../../../../core/context"
import { withTranslation } from 'react-i18next';
import {makeStyles} from '@material-ui/styles'

function ChooseEnterInformationType(props) {
    const feature_list = JSON.parse(sessionStorage.getItem("feature_list"))
    const [type, setType] = useState(feature_list && feature_list.includes("OCR") ? "scan" : "typing")
    const classes = useStyles()
    const context = useContext(Context)
    const t = props.t
    function changeType(type) {
        setType(type)
    }

    async function createCar() {
        let carItem = {}
        car_fields.map(item => {
            if (item == "type")
                carItem[item] = "stock"
            else if (item == "buy_price" || item == "sell_price")
                carItem[item] = 0
            else
                carItem[item] = ""
        })
        let data = await dataProvider.create(context, "car/one", {
            data: carItem
        })
        if (data && data.data) {
            props.setCar(data.data)
            if (type == "typing")
                props.setPage(1)
            else props.setPage(0)
        }
    }
    return (
        <Box padding="28px 32px 0px 50px">
            <Box display="flex" justifyContent="space-between">
                {(feature_list && feature_list.includes("OCR")) &&
                    <Box width="50%" marginRight="20px" className={classes.card} onClick={event => changeType("scan")}
                        style={{
                            border: type == "scan" ? "2px solid #2F80ED" : "1px solid #CAD0DB",
                            boxShadow: type == "scan" ? "0px 10px 20px -5px rgba(182, 191, 207, 0.6)" : "0px 10px 20px -5px rgba(182, 191, 207, 0.3)"
                        }} >
                        <Box display="flex" marginBottom="8px">
                            <Radio
                                checked={type == "scan"}
                                color="primary" className={classes.radio} />
                            <Typography variant="subtitle2" className={classes.bold}>{t('carModal.readFromGraycard')}</Typography>
                        </Box>
                        <Box display="flex" paddingLeft="46px" alignItems="center">
                            <Typography className={classes.secondaryText} variant="body2">
                                {t('carModal.readFromGraycardExplanation')}
                            </Typography>
                            <Box marginLeft="28px" marginLeft="auto">
                                <img src={scanImage} width="53px" height="62px" />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box width="50%" marginRight="20px" className={classes.card} onClick={event => changeType("typing")}
                    style={{
                        border: type == "typing" ? "2px solid #2F80ED" : "1px solid #CAD0DB",
                        boxShadow: type == "typing" ? "0px 10px 20px -5px rgba(182, 191, 207, 0.6)" : "0px 10px 20px -5px rgba(182, 191, 207, 0.3)"
                    }}>
                    <Box display="flex" marginBottom="8px">
                        <Radio
                            checked={type == "typing"}
                            color="primary" className={classes.radio} />
                        <Typography variant="subtitle2" className={classes.bold}>{t('carModal.enterManully')}</Typography>
                    </Box>
                    <Box display="flex" paddingLeft="46px">
                        <Typography className={classes.secondaryText} variant="body2">{t('carModal.enterManullyExplanation')}</Typography>
                        <Box marginLeft="28px" marginLeft="auto">
                            <img src={typingImage} width="80px" height="80px" />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <AddCarModalFooter page={props.page} setPage={props.setPage} setOpen={props.setOpen} nextOnClick={createCar} {...props} />
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700
    },
    secondaryText: {
        fontWeight: 500,
        color: '#778191',
        lineHeight: '26px'
    },
    card: {
        backgroundColor: 'white',
        border: "1px solid #CAD0DB",
        boxShadow: '0px 10px 20px -5px rgba(182, 191, 207, 0.3)',
        cursor: 'pointer',
        padding: '20px 30px 20px 20px',
        transitionDuration: '500ms',
        boxSizing: 'border-box'
    },
    radio: {
        "& svg": {
            width: "30px",
            height: "30px"
        },
        padding: '0px',
        marginRight: '16px'
    }
}))


const car_fields = [
    "brand_type",
    "chassis",
    "matricule",
    "reg_date",
    "kilomtrage",
    "approval",
    "body",
    "color",
    "cylinder",
    "cylindree_cm3",
    "power",
    "emission_code",
    "expertise_date",
    "expertise_canton",
    "status",
    "type",
]

export default withTranslation()(ChooseEnterInformationType)