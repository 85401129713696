import { Box, Button, IconButton, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { makeStyles } from '@material-ui/styles'
import ModalHeader from "../../../../components/madal/modal_header"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

function WarningModal(props) {
    const classes = useStyles()
    const handleClose = () => {
        props.setOpen(false);
    };
    const t = props.t

    return (
        <Box className={classes.root}>
            <ModalHeader title={t('cars.show.removeCar.alert')} setOpen={props.setOpen} />
            <Box padding="0px 37px 24px 50px">
                <Box marginTop="20px" marginBottom="40px">
                    <Typography gutterBottom variant="h6">{t('carWarning')}</Typography>
                </Box>
                <Box display='flex' flexDirection='row-reverse'>
                    <Button
                        onClick={props.deleteCar}
                        className={classes.logoutButton}
                        startIcon={<DeleteOutlineIcon style={{ marginLeft: "8px", fontSize: '30px' }} />}>
                        {t('cars.show.removeCar.deleteThisCar')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}>
                        {t('button.cancel')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '760px',
        background: '#F5F7FA',
        boxShadow: '0px 10px 20px -5px rgba(42, 48, 59, 0.2)',
        position: "absolute",
        top: "50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        boxSizing: 'border-box'
    },
    bold: {
        fontWeight: 700,
    },
    removeTitle: {
        fontWeight: 700,
        color: '#EB5757',
    },
    logoutButton: {
        border: "1px solid #EB5757",
        borderRadius: '0px',
        color: '#EB5757',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700,
        marginLeft: '16px'
    },
    cancelButton: {
        border: "1px solid #B6BFCF",
        borderRadius: '0px',
        color: '#B6BFCF',
        fontSize: '20px',
        padding: '12px 20px',
        fontWeight: 700
    },
}))

export default WarningModal