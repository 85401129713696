import { Box } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import AppDrawer from "../../components/drawer/drawer.jsx"
import { Context } from "../../core/context.js"
import dataProvider from "../../core/dataProvider.js"
import CarsList from "../cars/cars_list/cars_list.jsx"
import CarShow from "../cars/cars_show/car_show.jsx"
import CustomersList from "../customers/customer_list/customer_list.jsx"
import CustomerShow from "../customers/customer_show/customer_show.jsx"
import EmployeesList from "../employees/employees_list/employees_list.jsx"
import EmployeeShow from "../employees/employees_show/employee_show.jsx"
import financial from "../financial/financial.jsx"
import Profile from "../profile/profile.jsx"
import PrivateRoute from "./private_root.jsx"

const Home = props => {
    async function verify() {
        let data = await dataProvider.getOne(context, "auth/verify")
        if (!data || data.status != 200) {
            sessionStorage.removeItem("token")
            localStorage.removeItem("token")
            props.history.push("/login")
        }
        else {
            if (data.data.roles)
                sessionStorage.setItem("roles", JSON.stringify(data.data.roles.map(item => item.code)))
            if (data.data.feature_list)
                sessionStorage.setItem("feature_list", JSON.stringify(data.data.feature_list.map(item => item.title)))
            if (props.history.location.pathname == "/")
                props.history.push("/cars")
        }
    }
    const context = useContext(Context)
    const roles = JSON.parse(sessionStorage.getItem("roles"))

    useEffect(() => {
        if (!localStorage.getItem("token") && !sessionStorage.getItem("token")) {
            props.history.push("/login")
        } else verify()
    }, [])
    return (
        <Box display="flex" height="100%">
            <AppDrawer />
            <Switch>
                <Route path="/cars" exact component={CarsList} />
                <Route path="/cars/:id" exact component={CarShow} />
                <PrivateRoute path="/customers" exact component={CustomersList} condition={roles && roles.includes(2)} />
                <PrivateRoute path="/customers/:id" exact component={CustomerShow} condition={roles && roles.includes(2)} />
                <PrivateRoute path="/financial" exact component={financial} condition={roles && roles.includes(3)} />
                <PrivateRoute path="/employees" exact component={EmployeesList} condition={roles && (roles.includes(4) || roles.includes(5))} />
                <PrivateRoute path="/employees/:id" exact component={EmployeeShow} condition={roles && (roles.includes(4) || roles.includes(5))} />
                <Route path="/profile" exact component={Profile} />
            </Switch>
        </Box>
    )
}

export default Home