import React from "react"
import { Box, Typography } from "@material-ui/core";
import {makeStyles} from '@material-ui/styles'

function CustomerShowItem(props) {
    const classes = useStyles()

    return (
        <Box padding="16px 0px" border="1px solid #CAD0DB"
         boxShadow="0px 10px 20px -5px rgba(182, 191, 207, 0.3)" bgcolor="#F5F7FA"
         marginBottom="24px">
            <Box paddingLeft="24px" marginBottom={props.contact ? "16px" : "34px"}>
                <Typography className={classes.cardTitle} variant="subtitle2">{props.title}</Typography>
            </Box>
            <Box>
                {props.children}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    cardTitle: {
        color: "#2A303B",
        fontWeight: 700
    },
}))

export default CustomerShowItem