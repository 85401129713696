import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorSnackbar from "./components/backdrop/snackbar.jsx";
import { ContextProvider } from "./core/context.js";
import Home from "./pages/home/Home.jsx";
import Login from "./pages/login/login.jsx";
import NotFoundPage from "./pages/not_found_page/NotFoundPage.jsx"
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import LoadingBackdrop from "./components/backdrop/backdrop.jsx"
import "moment/locale/fr";
import "moment/locale/de";
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  function messageHandler(event) {
    let domains = ["http://www.ogarage.crossproduct.ch"]
    const { action, key, value } = event.data
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }
  window.addEventListener("message", messageHandler);

console.log(i18n.language)
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={localeMap[i18n.language]}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ContextProvider>
            <ErrorSnackbar />
            <LoadingBackdrop />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={Home} />
              <Route component={NotFoundPage} />
            </Switch>
          </ContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LocalizationProvider >
  );
}

const localeMap = {
  en: "en",
  fr: "fr",
  de: "de",
};

const theme = createTheme({
  typography: {

    fontFamily: 'Montserrat',
    button: {
      textTransform: 'none'
    },
    subtitle1: {
      fontSize: '24px',
      lineHeight: '29px',
    },
    subtitle2: {
      fontSize: '20px',
      lineHeight: '24px',
    },
    h6: {
      fontSize: '18px',
      lineHeight: '26px'
    },
    body1: {
      lineHeight: '20px',
      fontSize: '16px'
    },
    body2: {
      lineHeight: '17px',
      fontSize: '14px'
    },
    caption: {
      lineHeight: '15px',
      fontSize: '12px'
    }
  },
  palette: {
    primary: {
      main: '#2F80ED'
    },
    text: {
      primary: "#2A303B"
    },
  }

})

export default App;
